<template>
  <!-- 時刻表詳細カード -->
  <!-- 大枠 -->
  <div id="TimeTableDetailCard" class="flex mx-6 p-4 bg-white rounded-[4px]">
    <!-- 時間表示 -->
    <div class="w-[63px] text-left text-W6 text-[18px] leading-[18px] my-auto">
      {{ getFormatTimeToHHmm(operation.time) }}
    </div>
    <!-- 各種表示 -->
    <div class="flex-1 self-center my-auto">
      <!-- 1行目 -->
      <div class="flex text-left leading-3">
        <!-- 出発ホーム表示※電車の場合のみ表示 -->
        <span v-if="isShowPlatform" class="flex">
          <span class="block leading-3 text-W5">
            {{ operation.platform.name }}
          </span>
          <span class="block leading-3 text-W4 text-gray500">発</span>
          <span class="block leading-3 px-[2.5px] text-W4 text-gray300">
            ｜
          </span>
        </span>
        <!-- 種別 -->
        <span
          v-if="isShowLinkType"
          :style="{color: getOperationColor}"
          class="block leading-3 text-W6"
        >
          {{ operation.type }}
        </span>
        <!-- 路線名※飛行機の場合のみ表示 -->
        <span v-if="isShowAirplaneLineName" class="flex">
          <span class="block leading-3 px-[2.5px] text-W4 text-gray300">
            ｜
          </span>
          <span class="block leading-3 text-W4">
            {{ operation.name }}
          </span>
        </span>
      </div>
      <!-- 2行目 -->
      <div v-if="isShowBusFerryLineName || isShowDestination" class="mt-2">
        <!-- 路線名※バスorフェリーの場合のみ表示 -->
        <div
          v-if="isShowBusFerryLineName"
          class="text-left leading-3 text-W2 mb-1"
        >
          {{ operation.name }}
        </div>
        <!-- 3行目 -->
        <div v-if="isShowDestination" class="text-left leading-[13px] text-W2">
          {{ operation.destination.name }}行
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 時刻表詳細カードコンポーネント
 */
import Util from '@/mixins/util'
const TimeTableDetailCard = {
  name: 'TimeTableDetailCard',
  props: {
    operation: {
      type: Object,
      require: true,
    },
    stationType: {
      type: String,
      require: true,
    },
  },
  data() {
    return {}
  },
  emits: ['click-direction'],
  mixins: [Util],
  computed: {
    /**
     * プラットフォーム表示判定
     */
    isShowPlatform() {
      return this.checkStationType('station', false) && this.operation.platform
    },
    /**
     * 路線種別表示判定
     */
    isShowLinkType() {
      return this.operation.type
    },
    /**
     * 飛行機の路線名表示判定
     */
    isShowAirplaneLineName() {
      return this.checkStationType('airport', false)
    },
    /**
     * バスの路線名表示判定
     */
    isShowBusFerryLineName() {
      return (
        this.checkStationType('busstop', true) ||
        this.checkStationType('port', false)
      )
    },
    /**
     * 行き先表示判定
     */
    isShowDestination() {
      return this.operation.destination
    },
    /**
     * 色の取得
     */
    getOperationColor() {
      return this.operation.color
    },
  },
  methods: {
    /**
     * 駅種別のチェック
     * @param {String} type チェックする種別の文字列
     */
    checkStationType(type, isEndCheck) {
      return isEndCheck
        ? this.stationType.endsWith(type)
        : this.stationType == type
    },
  },
}
export default TimeTableDetailCard
</script>

<style></style>
