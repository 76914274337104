<template>
  <!-- 正式なレイアウトが出てきていないので、決めうち実装 -->
  <div
    class="w-fit flex items-center h-[12px]"
    @click="$emit('click-weather-info')"
  >
    <div class="text-W5 text-[10px] leading-[10px] text-success300 mr-2">
      現在の天気
    </div>
    <!-- 天気 -->
    <img class="h-[12px] w-[12px] mr-1" :src="weatherIcon()" />
    <!-- 気温 -->
    <div class="text-W4 text-[11px] leading-[11px] text-gray500 mr-2">
      {{ `${temperature}℃` }}
    </div>
    <!-- 降水確率 -->
    <img class="h-2.5 w-1.5 mr-1" src="@/assets/weather/rainyPercent.svg" />
    <div class="text-W4 text-[11px] leading-[11px] text-gray500 mr-2">
      {{ `${rainyPercent}%` }}
    </div>
    <!-- <img class="h-[10px] w-[10px]" src="@/assets/Icon_Right_gray.svg" /> -->
  </div>
</template>
<script>
import * as WeatherUtil from '@/utils/weatherUtil.js'
const WeatherInfo = {
  name: 'WeatherInfo',
  props: {
    // 天気コード
    weatherCode: {
      type: String,
      required: true,
    },
    // 気温
    temperature: {
      type: String,
      required: true,
    },
    // 降水確率
    rainyPercent: {
      type: String,
      required: true,
    },
  },
  emits: ['click-weather-info'],
  methods: {
    /**
     * 天気アイコン取得
     */
    weatherIcon() {
      return WeatherUtil.getWeatherIcon(this.weatherCode)
    },
  },
}
export default WeatherInfo
</script>
<style scooped></style>
