<template>
  <div class="fixed inset-0 h-screen">
    <div class="h-full w-full relative">
      <!-- 乗車地ピンアイコン -->
      <div
        v-if="isPickUpSettingMode"
        class="absolute positionPin translate-y-[-125%] z-10 pointer-events-none"
      >
        <div class="relative w-fit">
          <!-- ピンアイコン -->
          <img
            src="@/assets/arrangementIcon/taxi/IconPickUpPin.svg"
            class="w-11 h-11 translate-x-[-50%]"
          />
          <!-- 乗車時間吹き出し(タクシー到着時間取得中、または取得成功時のみ表示) -->
          <div
            v-if="isShowSpeechBubble"
            class="taxiArrivalSpeechBubblePosition h-9 z-10 pointer-events-none"
          >
            <div
              class="h-9 py-2 bg-white shadow-normal rounded-[10px] min-w-[122px] w-fit flex items-center justify-center"
            >
              <!-- タクシー到着時間取得中の場合はローディングアイコン -->
              <BaseLoading v-if="isShowRequestingSnackBar" width="16" />
              <!-- タクシー到着時間取得済みの場合は到着時間表示 -->
              <div v-else class="text-W4 text-[12px] text-gray400">
                <span>約</span>
                <span class="text-[15px] font-bold text-gray600">
                  {{ taxiArrivalText }}
                </span>
                <span>分で乗車</span>
              </div>
            </div>
            <!-- 吹き出しの尖り部分 -->
            <div
              class="taxiArrivalSpeechBubble taxiArrivalSpeechBubbleBottom w-fit"
            />
          </div>
        </div>
      </div>
      <!-- 目的地ピンアイコン -->
      <img
        v-else-if="isDropOffSettingMode"
        src="@/assets/Icon_Map_Place.svg"
        class="absolute positionPin translate-x-[-50%] translate-y-[-115%] w-11 h-11 z-10 pointer-events-none"
      />
      <!-- タクシー到着予想時間表示用吹き出し-->
      <div class="circle-back-button z-10 mt-safe-area" @click="goToBackPage()">
        <img class="h-4 w-4 mt-2.5 mx-auto" src="@/assets/Icon_Left_gray.svg" />
      </div>
    </div>
    <div class="absolute inset-0">
      <TaxiReservationMap
        ref="taxiReservationMap"
        @stop-map="getPickUpInfoOfMapCenter()"
        @moving-map="changeMapConditionAndInitStore()"
      />
    </div>
    <div class="absolute top-0 w-full pt-safe-area">
      <div
        v-show="isShowRequestingSnackBar"
        class="snackBar translate-x-[-50%] text-W4 mt-safe-area"
      >
        読み込み中...
      </div>
      <div
        v-show="showDisableDispatchSpotText"
        class="snackBar translate-x-[-50%] leading-[19.5px] text-W4 w-[239px] mt-safe-area"
      >
        ピンが置かれた乗車地は
        <br />
        指定できません。
      </div>
    </div>
    <div class="absolute bottom-0 w-full pb-safe-area">
      <!-- 地点設定パネル -->
      <BaseCard ref="settingPanel" class="mb-[25px]">
        <div class="relative">
          <!-- 現在地に戻るボタン -->
          <div
            class="absolute circle-back-current-position-button top-[-34px] translate-y-[-100%] right-0"
            @click="goBackCurrentPosition()"
          >
            <img class="h-4 w-4 mx-auto" src="@/assets/current_location.svg" />
          </div>
        </div>
        <!-- カード -->
        <keep-alive :include="includedComponents">
          <TaxiReservationWhereToGo
            v-if="isPickUpSettingMode"
            :routeInfo="routeInfo"
            :isShowPickupAddressNameText="!isShowRequestingSnackBar"
            :isButtonDisabled="isPickUpDisabled"
            @pick-up-config-start="spotSearchComponentVisibilityFlg = true"
            @drop-off-config-start="dropOffPopupWindowFlg = true"
            @click-next="onClickNext()"
          />
          <TaxiReservationDestinationConfirm
            v-else-if="isDropOffSettingMode"
            :dropOff="dropOffData"
            :isShowAddressNameText="!isShowRequestingSnackBar"
            :isButtonDisabled="isDropOffDisabled"
            @click-go-there="onClickGoThere()"
          />
        </keep-alive>
      </BaseCard>
    </div>
    <!-- 目的地設定 -->
    <SelectBox
      v-if="this.dropOffPopupWindowFlg"
      headerTitle="目的地の設定"
      :selectItems="destinationSettingMethods"
      @close-select-box="dropOffPopupWindowFlg = false"
      @click-item="onClickDestinationSetting($event.id)"
    />
    <Suggest
      :isShowSuggest="spotSearchComponentVisibilityFlg"
      :favoriteSectionDisplayFlg="false"
      @click-back-button="onClickBackInSuggest()"
      :panelSpaceHeight="$store.state.topSafeAreaHeight"
      :isRoundedTop="false"
      @route-search-select-spot="setPickUpOrDropOffSpot($event)"
    ></Suggest>
  </div>
</template>
<script>
import BaseBox from '@/components/atoms/BaseBox.vue'
import BaseCard from '@/components/atoms/BaseCard.vue'
import BaseLoading from '@/components/atoms/BaseLoading.vue'
import Overlay from '@/components/Overlay.vue'
import TaxiReservationDestinationConfirm from '@/components/molecules/arrangement/TaxiReservationDestinationConfirm'
import TaxiReservationMap from '@/components/molecules/arrangement/TaxiReservationMap.vue'
import TaxiReservationWhereToGo from '@/components/molecules/arrangement/TaxiReservationWhereToGo.vue'
import SelectBox from '@/components/molecules/SelectBox.vue'
import ArrangementUtil from '@/mixins/arrangementUtil.js'
import Suggest from '@/components/Suggest.vue'
import Util from '@/mixins/util.js'
import deepcopy from 'deepcopy'

// 目的地設定方法
const DESTINATION_SETTING_METHODS = {
  BY_ON_MAP: {
    id: 1,
    label: '地図上で指定する',
  },
  BY_ADDRESS: {
    id: 2,
    label: '住所を検索する',
  },
  NOT_SPECIFIED: {
    id: 3,
    label: '目的地を指定しない',
  },
}

// ルート情報初期値
const INITIAL_ROUTE_INFO = {
  lat: '',
  lon: '',
  address: '',
}

// 乗車地設定カード名
const PICK_UP_SETTING_CARD_NAME = 'TaxiReservationWhereToGo'
// 目的地設定カード名
const DROP_OFF_SETTING_CARD_NAME = 'TaxiReservationDestinationConfirm'
// 乗車地キー
const DATA_KEY_PICK_UP = 'pickUp'
// 目的地キー
const DATA_KEY_DROP_OFF = 'dropOff'

const TaxiReservationTop = {
  name: 'TaxiReservationTop',
  mixins: [ArrangementUtil, Util],
  components: {
    BaseBox,
    BaseCard,
    BaseLoading,
    Overlay,
    TaxiReservationDestinationConfirm,
    TaxiReservationMap,
    TaxiReservationWhereToGo,
    SelectBox,
    Suggest,
  },
  data() {
    return {
      spotSearchComponentVisibilityFlg: false, // 乗車地用スポット検索画面展開フラグ
      dropOffPopupWindowFlg: false, // 目的地設定用ポップアップ表示フラグ
      isMovingMap: false, // マップ操作中フラグ
      isShowRequestingSnackBar: false, // 読み込み中スナックバー
      isDispatchArea: false, // 配車可能判定
      dropOffData: deepcopy(INITIAL_ROUTE_INFO), // 目的地(ここへ行く押下時にStoreにコミットさせるためメモリで作成)
      current: PICK_UP_SETTING_CARD_NAME, // 現在の表示カード
      estimatedArrivalTime:
        this.$store.state.MobilityReservationStore.taxi.estimatedArrival, // 到着予想時間
    }
  },
  computed: {
    /**
     * ルート情報を返す
     */
    routeInfo() {
      return this.$store.state.MobilityReservationStore.taxi.routeInfo
    },
    /**
     * 乗車地の住所
     */
    pickUp() {
      return this.routeInfo.pickUp
    },
    /**
     * 目的地の住所
     */
    dropOff() {
      return this.routeInfo.dropOff
    },
    /**
     * 目的地設定方法
     */
    destinationSettingMethods() {
      return DESTINATION_SETTING_METHODS
    },
    /**
     * 乗車地の設定が完了しているかの判定
     */
    isCompletedPickUpSetting() {
      return (
        !this.isNull(this.pickUp.address) &&
        !this.isNull(this.pickUp.lat) &&
        !this.isNull(this.pickUp.lon)
      )
    },
    /**
     * 目的地の設定が完了しているかの判定
     */
    isCompletedDropOffSetting() {
      return (
        !this.isNull(this.dropOff.address) &&
        !this.isNull(this.dropOff.lat) &&
        !this.isNull(this.dropOff.lon)
      )
    },
    /**
     * 目的地dataの設定が完了しているかの判定
     */
    isCompletedDropOffDataSetting() {
      return (
        !this.isNull(this.dropOffData.address) &&
        !this.isNull(this.dropOffData.lat) &&
        !this.isNull(this.dropOffData.lon)
      )
    },
    /**
     * 配車不可能時のメッセージ表示フラグ
     */
    showDisableDispatchSpotText() {
      return (
        this.isPickUpSettingMode &&
        !this.isShowRequestingSnackBar &&
        !this.isDispatchArea
      )
    },
    /**
     * 乗車地非活性判定処理
     */
    isPickUpDisabled() {
      return (
        this.isMovingMap ||
        !this.isDispatchArea ||
        !this.isCompletedPickUpSetting
      )
    },
    /**
     * 目的地非活性判定処理
     */
    isDropOffDisabled() {
      return this.isMovingMap || !this.isCompletedDropOffDataSetting
    },
    /**
     * 現在地座標
     */
    currentPosition() {
      return this.$store.state.currentPosition
    },
    /**
     * 乗車地表示用に地図の中点を取得
     */
    appropriatePickUpPosition() {
      // 東京駅座標を初期設定
      let coord = {
        lat: this.$config.DEFAULT_CURRENT_POSITION.LAT,
        lon: this.$config.DEFAULT_CURRENT_POSITION.LON,
      }

      // 乗車地設定がある場合は乗車地の座標を取得
      if (this.isCompletedPickUpSetting) {
        coord.lat = this.pickUp.lat
        coord.lon = this.pickUp.lon
      } else if (this.currentPosition.lat && this.currentPosition.lon) {
        // 設定がない場合は現在地座標
        coord.lat = this.currentPosition.lat
        coord.lon = this.currentPosition.lon
      }
      return coord
    },
    /**
     * 目的地表示用に地図の中点を取得
     */
    appropriateDropOffPosition() {
      const coord = deepcopy(this.appropriatePickUpPosition)
      // 目的地設定がある場合は目的地の座標を取得
      if (this.isCompletedDropOffSetting) {
        coord.lat = this.dropOff.lat
        coord.lon = this.dropOff.lon
      }
      return coord
    },
    /**
     * 乗車地設定モード判定処理
     */
    isPickUpSettingMode() {
      return this.current === PICK_UP_SETTING_CARD_NAME
    },
    /**
     * 目的地設定モード判定処理
     */
    isDropOffSettingMode() {
      return this.current === DROP_OFF_SETTING_CARD_NAME
    },
    /**
     * keep-aliveのキャッシュ対象コンポーネントを返す
     */
    includedComponents() {
      return PICK_UP_SETTING_CARD_NAME
    },
    /**
     * 到着予想時間用メッセージ
     */
    taxiArrivalText() {
      const {minWaitTime, maxWaitTime} = this.estimatedArrivalTime
      if (minWaitTime === maxWaitTime) {
        return `${minWaitTime}`
      }
      return `${minWaitTime}~${maxWaitTime}`
    },
    /**
     * 迎車見込み時間が存在するかどうか
     */
    existArrivalTime() {
      return (
        this.estimatedArrivalTime.maxWaitTime !== null &&
        this.estimatedArrivalTime.minWaitTime !== null
      )
    },
    /**
     * 迎車見込み時間の吹き出しを表示するかどうか
     *
     * 下記条件を満たす場合に表示する
     * ・迎車地点設定時
     * ・読み込み中もしくは迎車時間取得成功時
     */
    isShowSpeechBubble() {
      return (
        this.isPickUpSettingMode &&
        (this.isShowRequestingSnackBar || this.existArrivalTime)
      )
    },
  },

  mounted() {
    this.initPickUpInfo()
    this.initDropOffInfo()
  },
  methods: {
    /**
     * 乗車地情報の初期化を行う
     */
    initPickUpInfo() {
      // 読み込み中を表示
      this.isShowRequestingSnackBar = true

      const dataHandler = (routeInfo, supported, estimatedTimes) => {
        this.updateRouteInfo(routeInfo, DATA_KEY_PICK_UP)
        // 乗車可能か判定
        this.isDispatchArea = supported
        // 到着予想時間を更新
        this.$store.commit(
          'MobilityReservationStore/updateEstimatedArrivalTime',
          estimatedTimes
        )
        this.estimatedArrivalTime = estimatedTimes
      }

      // 乗車地の設定がある場合はその緯度・経度を使用
      this.checkDispatchArea(
        this.appropriatePickUpPosition.lat,
        this.appropriatePickUpPosition.lon,
        DATA_KEY_PICK_UP,
        dataHandler
      )
    },
    /**
     * 目的地情報の初期化を行う
     */
    initDropOffInfo() {
      if (!this.isCompletedDropOffSetting) {
        return
      }
      // 読み込み中を表示
      this.isShowRequestingSnackBar = true
      this.dropOffData = deepcopy(this.dropOff)

      const dataHandler = (routeInfo, _) => {
        this.updateRouteInfo(routeInfo, DATA_KEY_DROP_OFF)
      }

      // 目的地の設定がある場合はその緯度・経度を使用
      this.checkDispatchArea(
        this.appropriateDropOffPosition.lat,
        this.appropriateDropOffPosition.lon,
        DATA_KEY_DROP_OFF,
        dataHandler
      )
    },
    /**
     * マップの中心地で逆ジオコーディング処理を実行する
     */
    getPickUpInfoOfMapCenter() {
      if (!this.$refs.taxiReservationMap) {
        return
      }
      // 現在の選択モードを保持
      const currentSettingMode = this.isPickUpSettingMode
        ? DATA_KEY_PICK_UP
        : DATA_KEY_DROP_OFF
      // マップの中心座標取得
      const currentLatLon = this.$refs.taxiReservationMap.getCenter()
      // 読み込み中を表示
      this.isShowRequestingSnackBar = true
      // 住所を更新して配車可能判定実施&保持
      const dataHandler = (routeInfo, supported, estimatedTimes) => {
        if (currentSettingMode == DATA_KEY_PICK_UP) {
          this.updateRouteInfo(routeInfo, DATA_KEY_PICK_UP)
          this.isDispatchArea = supported
        } else if (currentSettingMode == DATA_KEY_DROP_OFF) {
          this.dropOffData = routeInfo
        }
        // 到着予想時間を更新
        this.$store.commit(
          'MobilityReservationStore/updateEstimatedArrivalTime',
          estimatedTimes
        )
        this.estimatedArrivalTime = estimatedTimes
      }
      this.checkDispatchArea(
        currentLatLon.lat,
        currentLatLon.lng,
        currentSettingMode,
        dataHandler
      )

      // フラグ更新
      this.isMovingMap = false
    },
    /**
     * 渡された緯度・経度の住所、配車可否、タクシーの到着予想時間を取得
     * @param {String} lat 緯度
     * @param {String} lon 経度
     * @param {String} key canselSource保持用キー(乗車地と目的地でそれぞれ保持する)
     * @param {function} dataHandler 取得データ制御用関数
     */
    checkDispatchArea(lat, lon, key, dataHandler) {
      const vm = this
      // 成功時コールバック
      const success = (result) => {
        // マップが移動中の場合は最新の住所を取りに行くため処理中断
        if (this.isMovingMap) {
          return
        }

        // 取得データを元に呼び元で処理実行
        if (dataHandler) {
          const routeInfo = {
            address: result.addressName,
            lat: lat,
            lon: lon,
          }
          const estimatedArrivalTimeFromApi = {
            maxWaitTime: result.maxWaitTime,
            minWaitTime: result.minWaitTime,
          }
          dataHandler(routeInfo, result.supported, estimatedArrivalTimeFromApi)
        }
        vm.isShowRequestingSnackBar = false
      }

      // 失敗時コールバック
      const failed = () => {
        // 初期値に戻す
        vm.isDispatchArea = false
        vm.isShowRequestingSnackBar = false
      }

      // エラー時コールバック
      const error = (e) => {
        vm.isDispatchArea = false
        vm.isShowRequestingSnackBar = false
        throw e
      }

      // 逆ジオコーディング実行
      vm.$store.dispatch('MobilityReservationStore/checkTaxiDispatchArea', {
        success: success,
        failed: failed,
        error: error,
        lat: lat,
        lon: lon,
        key: key,
      })
    },
    /**
     * ルート情報をもとに、住所を更新する
     * @param {*} routeInfo ルート情報
     * @param {String} key 乗車地('pickUp')or目的地('dropOff') デフォルト値は乗車地
     */
    updateRouteInfo(routeInfo, key = DATA_KEY_PICK_UP) {
      // Storeを更新
      this.$store.commit('MobilityReservationStore/updateRouteInfoInTaxi', {
        obj: routeInfo,
        key: key,
      })
    },
    /**
     * マップ操作中フラグをtrueにし、乗車地の初期化を行う
     */
    changeMapConditionAndInitStore() {
      // マップ操作中、読み込み中フラグをtrueに更新
      this.spotSearchComponentVisibilityFlg = false
      this.isMovingMap = true
      this.isShowRequestingSnackBar = true

      if (this.isPickUpSettingMode) {
        // Storeの乗車地を初期化
        this.$store.commit(
          'MobilityReservationStore/initRouteInfoInTaxi',
          DATA_KEY_PICK_UP
        )
      } else if (this.isDropOffSettingMode) {
        this.dropOffData = deepcopy(INITIAL_ROUTE_INFO)
      }
    },
    /**
     * 前の画面に戻る
     */
    goToBackPage() {
      if (this.isPickUpSettingMode) {
        // 予約情報を初期化して前の画面に戻る
        this.clickClose(true)
      } else if (this.isDropOffSettingMode) {
        // 乗車地指定モードへ戻る
        this.returnToPickUpSettingMode()
      }
    },
    /**
     * 現在地に戻る
     */
    goBackCurrentPosition() {
      if (
        !this.currentPosition ||
        !this.currentPosition.lat ||
        !this.currentPosition.lon
      ) {
        // TODO: エラーハンドリング見直時、位置情報エラーとして取り扱う
        return
      }
      // 現在地に地図の中心を合わせる
      this.$refs.taxiReservationMap.currentSpotFit()
    },
    /**
     * 次へすすむボタン押下時の処理
     */
    onClickNext() {
      this.$router.push({name: this.$config.DISPLAY_TAXI_RESERVATION_CONFIRM})
    },
    /**
     * ここへ行く押下時の処理
     */
    onClickGoThere() {
      // Storeを更新
      this.$store.commit('MobilityReservationStore/updateRouteInfoInTaxi', {
        obj: this.dropOffData,
        key: DATA_KEY_DROP_OFF,
      })

      // 乗車地指定モードへ戻る
      this.returnToPickUpSettingMode()
    },
    /**
     * 目的地設定のボタン押下処理
     */
    onClickDestinationSetting(id) {
      switch (id) {
        case DESTINATION_SETTING_METHODS.BY_ON_MAP.id:
          // カードを切り替え、目的地選択モードに変更
          this.onClickByOnMap()
          break
        case DESTINATION_SETTING_METHODS.BY_ADDRESS.id:
          // 目的地選択ダイアログを閉じる
          this.onClickByAddress()
          break
        case DESTINATION_SETTING_METHODS.NOT_SPECIFIED.id:
          // Storeの目的地を初期化
          this.onClickNotSpecified()
          break
        default:
          break
      }
    },
    /**
     * 地図上で指定選択時の処理
     */
    onClickByOnMap() {
      // カードを切り替え、目的地設定ダイアログを閉じる
      this.current = DROP_OFF_SETTING_CARD_NAME
      this.dropOffPopupWindowFlg = false

      // 地図の中点を変更
      if (this.$refs.taxiReservationMap) {
        this.$refs.taxiReservationMap.callFitWithLatLon(
          this.appropriateDropOffPosition.lat,
          this.appropriateDropOffPosition.lon
        )
      }

      // dataに目的地をコピー
      this.dropOffData = deepcopy(this.dropOff)

      // 配車可能か判定
      this.isShowRequestingSnackBar = true
      const dataHandler = (routeInfo, _) => {
        this.dropOffData = routeInfo
      }
      this.checkDispatchArea(
        this.appropriateDropOffPosition.lat,
        this.appropriateDropOffPosition.lon,
        DATA_KEY_DROP_OFF,
        dataHandler
      )
    },
    /**
     * 住所を検索する選択時の処理
     */
    onClickByAddress() {
      // オーバーレイを非表示にしてスポット検索画面を表示する
      this.dropOffPopupWindowFlg = false
      this.spotSearchComponentVisibilityFlg = true

      // 表示カードを目的地設定用カードに切り替え
      this.current = DROP_OFF_SETTING_CARD_NAME
    },
    /**
     * 指定しない選択時の処理
     */
    onClickNotSpecified() {
      this.dropOffPopupWindowFlg = false
      // Storeの目的地を初期化
      this.$store.commit(
        'MobilityReservationStore/initRouteInfoInTaxi',
        DATA_KEY_DROP_OFF
      )
    },
    /**
     * 目的地指定から乗車地指定モードへ戻る時の共通処理
     */
    returnToPickUpSettingMode() {
      // 乗車位置指定モードへ切り替え
      this.current = PICK_UP_SETTING_CARD_NAME
      // 目的地保持用データ初期化
      this.dropOffData = deepcopy(INITIAL_ROUTE_INFO)
      // マップの中点を乗車地へ
      this.$refs.taxiReservationMap.callFitWithLatLon(
        this.appropriatePickUpPosition.lat,
        this.appropriatePickUpPosition.lon
      )
    },
    /**
     * スポット検索で乗車地/目的地を設定
     */
    setPickUpOrDropOffSpot($event) {
      // 現在の選択モードを保持
      const currentSettingMode = this.isPickUpSettingMode
        ? DATA_KEY_PICK_UP
        : DATA_KEY_DROP_OFF
      // スポットデータの抽出
      const setData = this.getSpotCurrentOrOtherwise($event)
      // 読み込み中を表示
      this.isShowRequestingSnackBar = true
      const dataHandler = (routeInfo, supported, estimatedTimes) => {
        if (currentSettingMode == DATA_KEY_PICK_UP) {
          this.updateRouteInfo(routeInfo, DATA_KEY_PICK_UP)
          this.isDispatchArea = supported
          // 到着予想時間を更新
          this.$store.commit(
            'MobilityReservationStore/updateEstimatedArrivalTime',
            estimatedTimes
          )
          this.estimatedArrivalTime = estimatedTimes
        } else if (currentSettingMode == DATA_KEY_DROP_OFF) {
          this.dropOffData = routeInfo
        }
      }
      // 抽出した座標情報で、逆ジオコーディングを実施
      this.checkDispatchArea(
        setData.lat,
        setData.lon,
        currentSettingMode,
        dataHandler
      )
      // マップの中心点を乗車地/目的地に設定
      this.$refs.taxiReservationMap.callFitWithLatLon(
        Number(setData.lat),
        Number(setData.lon)
      )
      // スポット検索画面を閉じる
      this.spotSearchComponentVisibilityFlg = false
    },
    /**
     * スポットデータの抽出
     * スポット検索にて、取得結果で返すパラメータを切り替える
     * 現在地の場合：Storeにあるパラメータを使用
     * 現在地以外の場合：検索結果から座標を数値情報に変換して返却
     * @param {spot} スポット検索結果データ
     * @returns 成形したオブジェクトデータ
     */
    getSpotCurrentOrOtherwise(spot) {
      if (spot.name == this.$config.CURRENT_NAME) {
        return {
          lat: this.currentPosition.lat,
          lon: this.currentPosition.lon,
        }
      } else {
        const result = this.disassembleLatAndLon(spot.coord)
        return {
          lat: result.lat,
          lon: result.lon,
        }
      }
    },
    /**
     * スポット検索コンポーネントの戻るボタン押下処理
     */
    onClickBackInSuggest() {
      // 目的地設定モードの場合は乗車地設定モードに戻す
      if (this.isDropOffSettingMode) {
        this.current = PICK_UP_SETTING_CARD_NAME
      }

      // スポット検索コンポーネントを非表示にする
      this.spotSearchComponentVisibilityFlg = false
    },
  },
}
export default TaxiReservationTop
</script>
<style scoped>
.positionPin {
  top: calc(50% - 102px);
  left: calc(50%);
}
.snackBar {
  position: absolute;
  top: 16px;
  left: 50%;
  padding: 12px;
  font-size: 13px;
  line-height: 19.5px;
  color: white;
  background-color: #1a1c21b3;
  opacity: 0.9;
  border-radius: 4px;
}
.taxiArrivalSpeechBubblePosition {
  position: absolute;
  top: -7px; /* 吹き出し下の棘のサイズ/2 */
  transform: translateX(-50%) translateY(-100%);
}
.taxiArrivalSpeechBubbleBottom {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  left: 50%;
}
.taxiArrivalSpeechBubble {
  height: 10px;
  width: 10px;
  position: absolute;
  background: #fff;
}
</style>
