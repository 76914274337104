<template>
  <div class="fixed-app">
    <Header
      ref="header"
      :isShowPrevious="false"
      headerTitle="お客様のタクシー"
      :isShowClose="true"
      @clickClose="clickCloseButton()"
    />
    <!-- ボディ -->
    <div class="overflow-y-auto scroll-bar-none">
      <!-- 上部セクション -->
      <div class="my-5 mx-6">
        <!-- ネットワークエラー表示 -->
        <div v-if="isNetworkErrorOccurred" class="flex w-full mb-5">
          <div
            class="flex flex-wrap w-full bg-danger100 p-4 border-[1px] border-solid border-danger300 rounded-[8px]"
          >
            <div class="text-W7 text-[13px] leading-[13px] text-danger300">
              通信エラー
            </div>
            <div class="text-W4 text-[13px] text-left leading-[19.5px] pt-2">
              タクシーの最新情報を取得するには、通信環境をご確認ください。
            </div>
          </div>
        </div>
        <!-- タクシー派遣中 -->
        <div v-if="isDispatching">
          <!-- 配車状況表示タイトル -->
          <div class="text-W7 text-[14px] leading-[21px] text-blue mx-auto">
            {{ vehicleConditionTitle }}
          </div>
          <!-- 配車補足説明 -->
          <div
            class="mt-1 text-W7 text-[18px] leading-[27px] text-gray600 mx-auto"
          >
            {{ vehicleConditionDescription }}
          </div>
        </div>

        <!-- タクシー乗車地到着 -->
        <div v-else-if="isPickUpArrived">
          <!-- 配車状況表示タイトル -->
          <div class="text-W7 text-[14px] leading-[21px] text-blue mx-auto">
            {{ vehicleConditionTitle }}
          </div>
          <!-- 配車補足説明 -->
          <div
            class="mt-1 text-W7 text-[18px] leading-[27px] text-gray600 mx-auto"
          >
            タクシーをお探しの上、
            <br />
            ご乗車ください。
          </div>
        </div>

        <!-- タクシー乗車中 -->
        <div v-else-if="isOnDeliver">
          <!-- 配車状況表示タイトル -->
          <div class="text-W7 text-[14px] leading-[21px] text-blue mx-auto">
            {{ vehicleConditionTitle }}
          </div>
          <!-- 配車補足説明(報告アクション) -->
          <div class="flex justify-center mt-1 mx-auto">
            <div class="text-W4 text-[12px] leading-[18px] text-gray500">
              お乗りでない場合はこちら
            </div>
            <div
              class="flex items-center ml-1"
              @click="isOpenReportCancelPopup = true"
            >
              <div class="text-W4 text-[12px] leading-[18px] text-blue">
                報告する
              </div>
              <img src="@/assets/Icon_Right_blue.svg" class="w-3 h-3" />
            </div>
          </div>
        </div>

        <!-- タクシー降車 -->
        <div v-else-if="isTaxiCompleted">
          <!-- 配車補足説明 -->
          <div
            class="mt-1 text-W7 text-[18px] leading-[27px] text-gray600 mx-auto"
          >
            ご利用ありがとうございました
          </div>
          <div class="flex justify-center mt-1 mx-auto">
            <div class="text-W4 text-[12px] leading-[18px] text-gray500">
              タクシー会社へ電話
            </div>
            <div class="flex items-center ml-1">
              <div class="text-W4 text-[12px] leading-[18px] text-gray500">
                {{ companyPhoneNumber }}
              </div>
            </div>
          </div>
        </div>
        <!-- 待ち合わせ番号表示 -->
        <div class="mt-4 mb-5 bg-blue200 rounded-[4px] mx-auto">
          <div class="p-3 flex flex-row items-center justify-between">
            <div
              class="text-W4 text-[12px] leading-[18px] text-gray500 text-left"
            >
              待ち合わせ番号
              <br />
              （乗務員にお知らせください）
            </div>
            <div class="text-W6 text-[24px] leading-[23px] text-gray600">
              {{ taxiVehicleInfo.waitingNumber }}
            </div>
          </div>
        </div>
        <div
          v-if="unreadFlag"
          class="mb-3 text-W7 text-[12px] leading-[18px] text-[#F39800] mx-auto"
        >
          乗務員からメッセージがあります
        </div>
        <!-- ボタン -->
        <TheButton
          text="乗務員とメッセージ"
          :class="{'!bg-orange': unreadFlag}"
          class="text-W6"
          :isDisabled="isTaxiCompleted"
          @click-button="goToScreenToChatWithDriver()"
        />
        <TheButton
          text="配車を取り消す"
          class="mt-3 the-button-secondary"
          :isDisabled="isAfterOnDeliver"
          @click-button="isOpenCancelPopup = true"
        />
      </div>
      <!-- 車両情報セクション -->
      <div class="bg-blue200 pt-5 pb-2 px-6">
        <!-- 車両情報 -->
        <div class="bg-white rounded-[4px] pt-3 px-3 pb-2 flex flex-col">
          <div class="flex flex-row items-center mx-auto">
            <div class="pl-2 text-W4 text-[12px] leading-[18px] text-gray500">
              {{ taxiVehicleInfo.company?.name }}
            </div>
          </div>
          <div class="mt-[5px] flex flex-row items-center mx-auto">
            <div class="text-W4 text-[12px] leading-[18px] text-gray500">
              ナンバープレート
            </div>
            <div class="pl-2 text-W6 text-[12px] leading-[19.5px] text-gray600">
              {{ taxiVehicleInfo.vehicle?.numberPlate }}
            </div>
          </div>
        </div>
        <div
          v-if="!isTaxiCompleted"
          class="mt-5 flex flex-row flex flex-row items-center"
        >
          <img
            class="w-[18px] h-[18px]"
            src="@/assets/arrangementIcon/taxi/IconArrangingTaxiPosition.svg"
          />
          <div class="pl-2 text-W7 text-[13px] leading-[18px] text-gray600">
            お客様のタクシー
          </div>
        </div>
      </div>
      <!-- マップ -->
      <div class="relative" v-if="isShowMap">
        <BaseMap
          ref="BaseMap"
          mapElementId="taxiReservationCompletedMap"
          :defaultCenter="defaultCenter"
          :mapSize="mapSize"
          @finish-generate-map="finishGenerateMap()"
        />
        <!-- 乗車地に戻るボタン -->
        <div
          class="absolute circle-back-current-position-button bottom-[35px] right-[16px]"
          @click="moveCenterOfMap()"
        >
          <img class="h-4 w-4 mx-auto" src="@/assets/current_location.svg" />
        </div>
      </div>
      <!-- 下部セクション -->
      <div class="mt-4 mx-6 mb-10">
        <!-- 乗車地住所 -->
        <BaseBox>
          <template v-slot:explanation>
            <div class="bg-gray200 rounded-[4px] py-3 px-3">
              <div class="flex flex-1 flex-col grid">
                <div class="flex flex-row items-center">
                  <img
                    src="@/assets/arrangementIcon/taxi/IconPickUpPin.svg"
                    class="h-[13px] w-2.5"
                  />
                  <div
                    class="ml-1.5 text-W4 text-xs leading-[18px] text-gray500"
                  >
                    乗車地
                  </div>
                </div>
                <div
                  class="mt-[2px] text-W6 text-[12px] leading-[18px] text-gray600"
                >
                  {{ pickUp.address }}
                </div>
              </div>
            </div>
          </template>
        </BaseBox>
        <!-- 住所情報区切りアイコン -->
        <img
          src="@/assets/Icon_down_gray.svg"
          class="h-2.5 w-2.5 m-auto my-1"
        />
        <!-- 目的地住所 -->
        <BaseBox>
          <template v-slot:explanation>
            <div class="bg-blue200 rounded-[4px] py-3 px-3">
              <div class="flex flex-1 flex-col">
                <div class="flex flex-row items-center">
                  <img
                    src="@/assets/arrangementIcon/taxi/IconDropOffPin.svg"
                    class="h-3 w-3"
                  />
                  <div
                    class="ml-1.5 text-W4 text-xs leading-[18px] text-gray500"
                  >
                    目的地
                  </div>
                </div>
                <div
                  class="mt-[2px] text-W6 text-[12px] leading-[18px] text-gray600"
                >
                  {{ dropOffAddress }}
                </div>
              </div>
            </div>
          </template>
        </BaseBox>
        <!-- 決済情報セクション -->
        <div class="mt-[12.75px] text-left text-gray600">
          <div v-if="isTaxiCompleted" class="mb-[5.25px]">
            <div class="text-W7 text-[13px] leading-[19.5px]">決済金額</div>
            <div class="mt-[0.25px] text-W4 text-[14px] leading-[21px]">
              {{ displayAmount }}
            </div>
          </div>
          <div>
            <div class="text-W7 text-[13px] leading-[19.5px]">決済番号</div>
            <div class="mt-[0.25px] text-W4 text-[14px] leading-[21px]">
              {{ orderId }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- GO都合のキャンセル用モーダル -->
    <Modal
      v-if="isOpenPassiveCancelPopup"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 py-6 text-center">
        <div class="text-W5 text-[17px]">
          大変申し訳ございません。
          <br />
          配車がキャンセルされました。
        </div>
        <div class="mt-4 text-W3 text-[15px]">
          お手数おかけしますが、再度タクシーをお呼びください。
        </div>
        <div class="rounded-[4px] mt-6 p-5 bg-gray200 text-left">
          <div class="text-W5 text-[15px]">考えられるキャンセル理由</div>
          <ul class="mt-3 text-W3 text-[13px] text-gray500">
            <li>・道路状況や車両故障で迎車が困難</li>
            <li>・乗務員の体調不良 など</li>
          </ul>
        </div>
        <div class="flex justify-center mt-6 items-center">
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="stopArrangement()"
          >
            手配をやめる
          </div>
          <TheButton
            class="w-1/2"
            text="再手配"
            @click-button="reArrangement()"
          />
        </div>
      </div>
    </Modal>
    <!-- 手配取り消し(報告)ポップアップ -->
    <Modal
      v-if="isOpenReportCancelPopup"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 py-6">
        <div class="mt-3 text-W5 text-[17px] leading-[25.5px]">
          ご迷惑をおかけしており大変申し訳ございません。現在の配車を取り消します。よろしいですか？
        </div>
        <div class="flex justify-center mt-6 items-center">
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="isOpenReportCancelPopup = false"
          >
            もどる
          </div>
          <TheButton
            class="w-1/2"
            text="配車をやめる"
            @click-button="onClickCancelConfirm()"
          />
        </div>
      </div>
    </Modal>
    <!-- 手配取り消しポップアップ -->
    <Modal
      v-if="isOpenCancelPopup"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 py-6">
        <div class="mt-3 text-W5 text-[17px] leading-[25.5px]">
          現在の配車を取り消します。よろしいですか？
        </div>
        <div class="mt-6 text-W3 text-[12px] leading-[18px] text-gray500">
          配車のキャンセルを繰り返すと、規約に基づきご利用を制限する場合がございます。
        </div>
        <div class="flex justify-center mt-6 items-center">
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="isOpenCancelPopup = false"
          >
            もどる
          </div>
          <TheButton
            class="w-1/2"
            text="配車をやめる"
            @click-button="onClickCancelConfirm()"
          />
        </div>
      </div>
    </Modal>
    <!-- キャンセル通信中ポップアップ -->
    <Modal
      v-if="isRequestingCancelFlg"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 py-6">
        <img src="@/assets/Wait_Indicator.svg" class="w-16 h-16 m-auto" />
        <div class="text-W5 text-[17px] leading-[25.5px] mt-4">
          しばらくお待ちください...
        </div>
      </div>
    </Modal>
    <!-- タクシー配車取消完了ポップアップ -->
    <Modal
      v-if="isCanceledFlg"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-6 pb-6">
        <div class="font-bold mt-4">配車を取り消しました。</div>
        <TheButton text="OK" class="mt-4" @click-button="clickCloseButton()" />
      </div>
    </Modal>
    <!-- タクシー配車取消失敗ポップアップ -->
    <Modal
      v-if="isCancelFailedFlg"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-6 pb-6">
        <div class="font-bold mt-4">配車の取り消しができませんでした。</div>
        <TheButton
          text="OK"
          class="mt-4"
          @click-button="isCancelFailedFlg = false"
        />
      </div>
    </Modal>
    <!-- タクシーを探し中ポップアップ -->
    <Modal
      v-if="isFindingInitTaxiStatus"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 py-6">
        <img src="@/assets/Wait_Indicator.svg" class="w-16 h-16 m-auto" />
        <div class="text-W5 text-[17px] leading-[25.5px] mt-4">
          しばらくお待ちください...
        </div>
      </div>
    </Modal>
    <!-- タクシー手配失敗ポップアップ -->
    <Modal
      v-if="isShowArrangementFailedPopup"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="py-6 px-5">
        <div class="font-bold mt-3">タクシーの手配ができませんでした。</div>
        <TheButton text="OK" class="mt-4" @click-button="clickCloseButton()" />
      </div>
    </Modal>
  </div>
</template>

<script>
import ArrangementUtil from '@/mixins/arrangementUtil'
import BaseBox from '@/components/atoms/BaseBox.vue'
import BaseMap from '@/components/atoms/BaseMap.vue'
import Header from '@/components/organisms/Header.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import Modal from '@/components/Modal.vue'
import Util from '@/mixins/util.js'
import log from 'loglevel'

// マップ上に表示する乗車地アイコン
const PICKUP_ICON = {
  name: 'arrangementIcon/taxi/IconPickUpPin.svg',
  width: 36,
  height: 46,
}
// マップ上に表示する目的アイコン
const DORP_OFF_ICON = {
  name: 'Icon_Map_Place.svg',
  width: 44,
  height: 44,
}
// タクシー車両アイコン
const VEHICLE_ICON = {
  name: 'arrangementIcon/taxi/IconArrangingTaxiPositionOnMap.svg',
  width: 56,
  height: 56,
}

/// タクシー配車キャンセル理由（ユーザーキャンセル）
const CANCEL_REASON_USER = 'user_cancel'

const TaxiReservationCompleted = {
  name: 'TaxiReservationCompleted',
  components: {
    Header,
    BaseBox,
    BaseMap,
    TheButton,
    Modal,
  },
  mixins: [ArrangementUtil, Util],
  data() {
    return {
      // VueのReactivityによる値変更検知をさせないため、オブジェクトを凍結
      frozen: Object.freeze({
        mapRelated: {
          vehicleGlMarker: null, // 配車タクシーアイコンのマーカーオブジェクト
          pickUpGlMarker: null, //乗車地用のマーカーオブジェクト
        },
      }),
      mapSize: {
        height: 400,
        width: document.documentElement.clientWidth,
      }, // マップの表示サイズ
      isOpenReportCancelPopup: false, //キャンセルモーダル(報告)表示フラグ
      isOpenCancelPopup: false, //キャンセルモーダル表示フラグ
      isRequestingCancelFlg: false, //キャンセル通信中フラグ
      isCanceledFlg: false, //キャンセル完了フラグ
      isCancelFailedFlg: false, // キャンセル失敗フラグ
      unreadFlag: false, // メッセージ未読フラグ
      isFindingInitTaxiStatus: false, // タクシーを探している最中か
      isDispatchFailedTaxiStatus: false, // タクシーが見つからなかったフラグ
      isTaxiOrderFailed: false, // 手配失敗フラグ
      isIconInitialized: false, // 初期化済フラグ
      isFinishGenerateMap: false, // マップ初期化済フラグ
    }
  },
  created() {
    this.init()
  },
  unmounted() {
    // ローカルストレージ内のタクシー予約情報を初期化する
    if (this.isTaxiCompleted) {
      this.resetLocalStorageOfTaxiReservation()
    } else if (this.isTaxiCanceled) {
      // ローカルストレージ内のタクシー予約情報を初期化する
      this.resetLocalStorageOfTaxiReservation()
      // 受動的キャンセルが行われた場合、画面遷移前にタクシー情報をリセットする
      this.$store.commit('MobilityReservationStore/resetTaxiInfo')
    }
  },
  computed: {
    /**
     * ネットワークエラー発生フラグ
     */
    isNetworkErrorOccurred() {
      return this.$store.state.MobilityReservationStore.taxi
        .isNetworkErrorOccurred
    },
    /**
     * メッセージ履歴を取得して表示用配列を返す
     */
    messages() {
      return this.$store.state.MobilityReservationStore.taxi.messageList
    },
    /**
     * ルート情報を返す
     */
    routeInfo() {
      return this.$store.state.MobilityReservationStore.taxi.routeInfo
    },
    /**
     * タクシー配車情報
     */
    taxiVehicleInfo() {
      return this.$store.state.MobilityReservationStore.taxi.vehicleInfo
    },
    /**
     * 決済番号を返す
     */
    orderId() {
      return this.taxiVehicleInfo.orderId
    },
    /**
     * 請求金額を返す
     */
    amount() {
      return this.taxiVehicleInfo.onlinePayment?.amount
    },
    /**
     * 表示用金額
     */
    displayAmount() {
      return this.amount == null
        ? '登録メールアドレス宛にご案内します。'
        : this.addCommaForInteger(this.amount) + '円'
    },
    /**
     * 乗車地の地点情報
     */
    pickUp() {
      return this.routeInfo.pickUp
    },
    /**
     * 目的地の地点情報
     */
    dropOff() {
      return this.routeInfo.dropOff
    },
    /**
     * 目的地が設定済であること
     */
    isDropOffPosition() {
      return !this.isNull(this.dropOff.lat) && !this.isNull(this.dropOff.lon)
    },
    /**
     * マップ表示時の初期中心地
     */
    defaultCenter() {
      return {
        lat: this.pickUp.lat,
        lon: this.pickUp.lon,
      }
    },
    /**
     * 目的地の住所表示
     */
    dropOffAddress() {
      return this.isNull(this.dropOff.address)
        ? '乗務員に直接指定'
        : this.dropOff.address
    },
    /**
     * ユーザーの現在地
     */
    currentPosition() {
      return this.$store.state.currentPosition
    },
    /**
     * ユーザーの現在地が取得できていること
     */
    isCurrentPosition() {
      return (
        !this.isNull(this.currentPosition.lat) &&
        !this.isNull(this.currentPosition.lon)
      )
    },
    /**
     * タクシー配車ステータスを取得
     */
    taxiStatus() {
      return this.$store.state.MobilityReservationStore.taxi.reservationStatus
    },
    /**
     * 初期ステータス
     */
    isInitialStatus() {
      return this.taxiStatus === ''
    },
    /**
     * マップ表示を行うか
     */
    isShowMap() {
      return !this.isTaxiCompleted && !this.isInitialStatus
    },
    /**
     * 配車中か判定
     */
    isDispatching() {
      return (
        this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.DISPATCHING
      )
    },
    /**
     * 乗車地に到着したか判定
     */
    isPickUpArrived() {
      return (
        this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.PICK_UP_ARRIVED
      )
    },
    /**
     * 乗車中か判定
     */
    isOnDeliver() {
      return this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.ON_DELIVER
    },
    /**
     * 乗車中以降か判定
     */
    isAfterOnDeliver() {
      return (
        this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.ON_DELIVER ||
        this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.SUCCEED ||
        this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.DROP_OFF ||
        this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.CANCELED
      )
    },
    /**
     * タクシー手配が終了したか判定
     */
    isTaxiCompleted() {
      return (
        this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.SUCCEED ||
        this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.DROP_OFF
      )
    },
    /**
     * タクシー手配がキャンセルされたか判定
     */
    isTaxiCanceled() {
      return this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.CANCELED
    },
    /**
     * タクシーが見つからなかったか判定
     */
    isDispatchFailed() {
      return (
        this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.DISPATCH_FAILED
      )
    },
    /**
     * 配車状況表示タイトル
     */
    vehicleConditionTitle() {
      switch (this.taxiStatus) {
        case this.$config.TAXI_RESERVATION_STATUS.DISPATCHING:
          return 'タクシーの手配ができました'
        case this.$config.TAXI_RESERVATION_STATUS.PICK_UP_ARRIVED:
          return '到着しました'
        case this.$config.TAXI_RESERVATION_STATUS.ON_DELIVER:
          return '乗車中です'
        default:
          return ''
      }
    },
    /**
     * 配車状況補足説明
     */
    vehicleConditionDescription() {
      if (this.taxiVehicleInfo?.pickUpArrivalMinutes) {
        return `到着予定時間：約${this.taxiVehicleInfo.pickUpArrivalMinutes}分後`
      }
      return 'まもなく到着します'
    },
    /**
     * タクシーに乗車済かつ現在地取得可能状態であること
     */
    isTakenTaxiAndGettingCurrentLocation() {
      return this.isOnDeliver && this.isCurrentPosition
    },
    /**
     * タクシー会社の電話番号
     */
    companyPhoneNumber() {
      return this.taxiVehicleInfo.company?.phoneNumber
    },
    /**
     * 受動的キャンセルモーダル表示フラグ
     */
    isOpenPassiveCancelPopup() {
      const isUnreadTaxiPassiveCancelModal = !this.getLocalStorage(
        this.$config.IS_READ_TAXI_PASSIVE_CANCEL_MODAL
      )
      return (
        // 以下の条件を全て満たす場合、trueを返却
        // * 配車ステータス: キャンセル
        // * キャンセル理由: ユーザー起因以外の理由
        // * 受動的キャンセルモーダル: 未読
        this.taxiStatus === this.$config.TAXI_RESERVATION_STATUS.CANCELED &&
        this.taxiVehicleInfo.cancelReason !== CANCEL_REASON_USER &&
        isUnreadTaxiPassiveCancelModal
      )
    },
    /**
     * 手配失敗モーダル表示フラグ
     */
    isShowArrangementFailedPopup() {
      return this.isTaxiOrderFailed || this.isDispatchFailedTaxiStatus
    },
  },
  watch: {
    messages: {
      deep: true,
      handler(newMessageList) {
        this.unreadFlag = this.judgeUnreadTaxiMessages(newMessageList)
      },
    },
    taxiStatus: {
      handler(newStatus, oldStatus) {
        if (
          oldStatus === this.$config.TAXI_RESERVATION_STATUS.RESERVING &&
          this.isNoArrangementStatus(newStatus)
        ) {
          // reservingから未手配ステータスに遷移するケースは手配ロールバックの場合なので、
          // キャンセルポップアップを表示した上処理中断
          this.isFindingInitTaxiStatus = false
          this.isTaxiOrderFailed = true
          return
        }

        // 乗車中状態にて必要なアイコンに切り替える
        this.changeDisplayIconModeOnDeliver()

        // この画面を開いた際のステータスがタクシーを探している最中だった場合のポップアップ表示／非表示判定
        this.switchFindingModalDisplay()

        // ユーザー起因キャンセルが完了した場合、配車取り消しポップアップを表示する
        this.switchUserCanceledModalDisplay()

        // 受動的キャンセルモーダルが表示されている場合、しばらくお待ちくださいポップアップを非表示にする
        this.switchDriverCanceledModalDisplay()
      },
    },
    taxiVehicleInfo: {
      deep: true,
      handler() {
        if (this.isIconInitialized) {
          this.moveVehicleIcon()
        } else {
          this.initializeMapIcon()
        }
      },
    },
    currentPosition: {
      deep: true,
      handler() {
        this.moveVehicleIcon()
      },
    },
  },
  methods: {
    /**
     * 画面表示準備処理
     */
    init() {
      // ローカルストレージの受動的キャンセルモーダル既読フラグをリセット（削除）
      localStorage.removeItem(this.$config.IS_READ_TAXI_PASSIVE_CANCEL_MODAL)
      // この画面を開いた時点でステータスがFindingであるか
      this.isFindingInitTaxiStatus =
        this.$store.getters[
          'MobilityReservationStore/isReservingOrFindingTaxiStatus'
        ]
      // 乗務員とのテンプレートメッセージ取得
      this.$store.commit('startLoading')
      this.fetchTemplateMessage()
      this.restartPolling()
    },
    /**
     * 乗務員とのテンプレートメッセージ取得処理
     */
    fetchTemplateMessage() {
      const templateMessageList =
        this.$store.state.MobilityReservationStore.taxi.templateMessageList
      // テンプレートメッセージ取得済みの場合は何も行わない
      // eslint-disable-next-line no-magic-numbers
      if (templateMessageList.length !== 0) {
        return
      }

      // テンプレートメッセージ未取得の場合は、取得処理を行う
      const success = () => {}
      const failed = () => {
        log.debug('定型文取得失敗')
      }
      const error = (e) => {
        log.debug('定型文取得エラー', e)
      }
      this.$store.dispatch(
        'MobilityReservationStore/getTaxiTemplateMessageList',
        {
          success: success,
          failed: failed,
          error: error,
        }
      )
    },
    /**
     * 閉じるボタン押下時の処理
     */
    clickCloseButton() {
      this.$store.dispatch('MobilityReservationStore/stopTaxiPolling')

      // タクシー到着予想時間の初期化
      this.$store.commit('MobilityReservationStore/initEstimatedArrivalTime')

      // タクシー降車した場合かキャンセルされた場合はStoreを初期化してページを閉じる
      const shouldBeReset =
        this.isTaxiCompleted || this.isTaxiCanceled || this.isInitialStatus
      this.clickClose(shouldBeReset)
    },
    /**
     * マップ生成後の処理
     */
    finishGenerateMap() {
      this.isFinishGenerateMap = true
      this.initializeMapIcon()
    },
    /**
     * アイコン等配置処理
     */
    initializeMapIcon() {
      // マップの生成が終わっていなければ処理を行わない
      if (!this.isFinishGenerateMap) {
        return
      }
      // 車両情報の取得が終わっていなければ処理を行わない
      if (!this.orderId) {
        return
      }

      // 手配状態によって適したピンを配置
      this.setPinIconByReservationStatus()
      // タクシーアイコン配置
      this.setVehicleIcon()
      // マップ中心地設定
      this.moveCenterOfMap()

      // 初期化完了フラグを立てる
      this.isIconInitialized = true
    },
    /**
     * 配車ステータスに適したピンアイコンを配置
     */
    setPinIconByReservationStatus() {
      // 乗車中は目的地ピンを配置
      if (this.isOnDeliver) {
        if (this.isDropOffPosition) {
          this.setDropOffIcon()
        }
      } else {
        // それ以外は乗車地ピンを配置
        this.setPickUpIcon()
      }
    },
    /**
     * 配車タクシーアイコンの設置
     */
    setVehicleIcon() {
      // タクシーの位置情報取得
      const {lat, lon} = this.getVehiclePositionByStatus()

      const iconSize = {
        height: VEHICLE_ICON.height,
        width: VEHICLE_ICON.width,
      }
      // アイコンをプロット
      this.frozen.mapRelated.vehicleGlMarker = this.$refs.BaseMap.putPin(
        'vehicleIcon',
        lat,
        lon,
        iconSize,
        VEHICLE_ICON.name
      )
    },
    /**
     * 配車タクシーアイコンの移動
     */
    moveVehicleIcon() {
      if (this.isNull(this.frozen.mapRelated.vehicleGlMarker)) {
        return
      }

      // タクシー降車時、キャンセル時、ステータスが空白時は処理中断
      if (this.isTaxiCompleted || this.isInitialStatus || this.isTaxiCanceled) {
        return
      }

      // タクシーの位置情報取得
      const {lat, lon} = this.getVehiclePositionByStatus()

      const easingSeconds = 1
      /* eslint-disable no-undef */
      this.frozen.mapRelated.vehicleGlMarker.setPosition(
        new mapscript.value.LatLng(lat, lon),
        new mapscript.value.animation.AnimationOption(easingSeconds, {
          calculateAnimationProgress: (number) => {
            return number
          },
        })
      )
      /* eslint-enable no-undef */
    },
    /**
     * 配車ステータスに適したタクシーの位置情報を取得する
     */
    getVehiclePositionByStatus() {
      let lat
      let lon
      // 乗車中の場合は現在地を使用
      if (this.isTakenTaxiAndGettingCurrentLocation) {
        lat = this.currentPosition.lat
        lon = this.currentPosition.lon
      } else {
        // 乗車中でない場合はタクシー位置を使用
        lat = this.taxiVehicleInfo.vehicle?.latitude
        lon = this.taxiVehicleInfo.vehicle?.longitude
      }

      return {
        lat,
        lon,
      }
    },
    /**
     * 乗車地のアイコンをマップに配置する
     */
    setPickUpIcon() {
      const lat = this.pickUp.lat
      const lon = this.pickUp.lon
      const iconName = PICKUP_ICON.name
      const iconSize = {
        height: PICKUP_ICON.height,
        width: PICKUP_ICON.width,
      }
      // アイコンをプロット
      this.frozen.mapRelated.pickUpGlMarker = this.$refs.BaseMap.putPin(
        'pickUpIcon',
        lat,
        lon,
        iconSize,
        iconName
      )
    },
    /**
     * 乗車地アイコンを破棄する
     */
    deletePickUpIcon() {
      this.$refs.BaseMap.removeGLMarkers([
        this.frozen.mapRelated.pickUpGlMarker,
      ])
      this.frozen.mapRelated.pickUpGlMarker = null
    },
    /**
     * 目的地アイコンを表示する
     */
    setDropOffIcon() {
      const lat = this.dropOff.lat
      const lon = this.dropOff.lon
      const iconName = DORP_OFF_ICON.name
      const iconSize = {
        height: DORP_OFF_ICON.height,
        width: DORP_OFF_ICON.width,
      }
      // アイコンをプロット
      this.$refs.BaseMap.putPin('dropOffIcon', lat, lon, iconSize, iconName)
    },
    /**
     * マップの中心地移動アイコンの処理
     */
    moveCenterOfMap() {
      if (!this.$refs.BaseMap) {
        return
      }
      // fit処理の対象座標を切り替え
      if (this.isOnDeliver) {
        if (this.isCurrentPosition) {
          // ユーザーの現在位置座標に戻る
          this.goBackCurrentPosition()
        } else {
          // タクシーの現在位置座標に戻る
          this.goBackTaxiPosition()
        }
      } else {
        // 乗車位置座標に戻る
        this.goBackPickUpPosition()
      }
    },
    /**
     * 乗車地に戻る
     */
    goBackPickUpPosition() {
      // 地図の中点を乗車地に変更
      this.$refs.BaseMap.setCenter(
        Number(this.pickUp.lat),
        Number(this.pickUp.lon)
      )
    },
    /**
     * マップの中心を現在地にフィットさせる
     */
    goBackCurrentPosition() {
      this.$refs.BaseMap.setCenter(
        Number(this.currentPosition.lat),
        Number(this.currentPosition.lon)
      )
    },
    /**
     * タクシーの位置情報
     */
    goBackTaxiPosition() {
      this.$refs.BaseMap.setCenter(
        Number(this.taxiVehicleInfo.vehicle?.latitude),
        Number(this.taxiVehicleInfo.vehicle?.longitude)
      )
    },
    /**
     * チャット画面に遷移
     */
    goToScreenToChatWithDriver() {
      this.$router.push({name: this.$config.DISPLAY_TAXI_RESERVATION_CHAT})
    },
    /**
     * 乗車中状態にて必要なアイコンに切り替える
     */
    changeDisplayIconModeOnDeliver() {
      // 乗車中でない場合は処理中断
      if (!this.isOnDeliver) {
        return
      }

      // 乗車地アイコンの削除
      this.deletePickUpIcon()
      // 目的地情報があれば目的地アイコンの表示
      if (this.isDropOffPosition) {
        this.setDropOffIcon()
      }
    },
    /**
     * 再手配
     * 手配内容を保持したまま確認画面に戻る
     */
    reArrangement() {
      // 受動的キャンセルモーダル既読フラグをtrueに更新
      this.updateToReadTaxiPassiveCancelModal()
      // 確認画面に戻る
      this.$router.push(this.$config.DISPLAY_TAXI_RESERVATION_CONFIRM)
    },
    /**
     * 手配をやめる
     */
    stopArrangement() {
      // 受動的キャンセルモーダル既読フラグをtrueに更新
      this.updateToReadTaxiPassiveCancelModal()
      this.clickCloseButton()
    },
    /**
     * 再起動等でポーリングが行われていない場合はポーリングを再開
     */
    restartPolling() {
      if (
        this.$store.state.MobilityReservationStore.taxi.pollingTimerId === null
      ) {
        this.$store
          .dispatch('MobilityReservationStore/startTaxiPolling')
          .then(() => {
            this.unreadFlag = this.judgeUnreadTaxiMessages(this.messages)
          })
          .finally(() => {
            this.$store.commit('endLoading')
          })
      } else {
        this.$store.commit('endLoading')
      }
    },
    /**
     * ローカルストレージのタクシー予約関連情報を初期化する
     */
    resetLocalStorageOfTaxiReservation() {
      this.setLocalStorage(this.$config.MESSAGE_LOG_LENGTH, null)
    },
    /**
     * 配車取り消しを確定する
     */
    onClickCancelConfirm() {
      this.isOpenReportCancelPopup = false
      this.isOpenCancelPopup = false
      this.isRequestingCancelFlg = true

      const failed = () => {
        //しばらくお待ちくださいポップアップを非表示
        this.isRequestingCancelFlg = false
        // キャンセル失敗ポップアップを表示
        this.isCancelFailedFlg = true
      }
      const error = (e) => {
        //しばらくお待ちくださいポップアップを非表示
        this.isRequestingCancelFlg = false
        throw e
      }

      // success時の処理はwatchでステータスの変更を検知してから行うため不要
      this.$store.dispatch('MobilityReservationStore/cancelTaxiReservation', {
        failed: failed,
        error: error,
      })
    },
    /**
     * 受動的キャンセルモーダル既読フラグをtrueに更新する
     */
    updateToReadTaxiPassiveCancelModal() {
      this.setLocalStorage(this.$config.IS_READ_TAXI_PASSIVE_CANCEL_MODAL, true)
    },
    /**
     * この画面を開いた際のステータスがタクシーを探している最中だった場合のポップアップ表示／非表示判定
     * Findingの最中にアプリ再起動した場合に isFindingInitTaxiStatus が真になる
     */
    switchFindingModalDisplay() {
      if (this.isFindingInitTaxiStatus) {
        // タクシーが見つからなかった場合
        if (this.isDispatchFailed) {
          // タクシー手配失敗ポップアップ表示
          this.isDispatchFailedTaxiStatus = true
        }
        // タクシーを探し中ポップアップ非表示
        this.isFindingInitTaxiStatus = false
      }
    },
    /**
     * ユーザー起因キャンセルが完了した場合、配車取り消しポップアップを表示する
     */
    switchUserCanceledModalDisplay() {
      if (
        this.isTaxiCanceled &&
        this.taxiVehicleInfo.cancelReason === CANCEL_REASON_USER
      ) {
        // しばらくお待ちくださいポップアップを非表示
        this.isRequestingCancelFlg = false
        // 配車を取り消しましたポップアップを表示
        this.isCanceledFlg = true
      }
    },
    /**
     * 受動的キャンセルモーダルが表示されている場合、しばらくお待ちくださいポップアップを非表示にする
     */
    switchDriverCanceledModalDisplay() {
      if (this.isOpenPassiveCancelPopup) {
        // しばらくお待ちくださいポップアップを非表示
        this.isRequestingCancelFlg = false
      }
    },
    /**
     * 手配中判定のステータスであるかどうか
     */
    isNoArrangementStatus(status) {
      const targetArr = [
        this.$config.TAXI_RESERVATION_STATUS.DEFAULT,
        this.$config.TAXI_RESERVATION_STATUS.DISPATCH_FAILED,
        this.$config.TAXI_RESERVATION_STATUS.CANCELED,
        this.$config.TAXI_RESERVATION_STATUS.DROP_OFF,
        this.$config.TAXI_RESERVATION_STATUS.SUCCEED,
      ]
      return targetArr.includes(status)
    },
  },
}
export default TaxiReservationCompleted
</script>
