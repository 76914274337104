<template>
  <!-- メニュー画面 -->
  <div class="fixed inset-0 bg-gray200 pt-safe-area pb-safe-area">
    <div id="menu-page" class="w-full flex flex-col z-[10] py-6">
      <div
        class="h-[112px] w-[112px] relative mx-auto rounded-[55px] z-[12] border-2 border-primary"
        @click="onClickIconEdit()"
      >
        <div class="h-[98px] w-[98px] absolute left-[5px] top-[5px]">
          <img :src="userIcon" width="98" height="98" />
        </div>
        <img
          src="@/assets/menu/user_edit.png"
          class="z-[13] absolute right-0 bottom-0"
          width="24"
          height="24"
        />
      </div>
      <div class="w-full mt-4 bottom-0 z-[11] flex justify-center">
        <div class="gestText leading-5 text-W7 text-[20px] truncate">
          {{ user.name }}
        </div>
        <div class="leading-[15px] h-[15px] text-W3 text-[13px] mt-auto">
          さん
        </div>
        <IconEdit
          class="text-primary h-4 w-4 my-auto ml-2"
          @click="openEditUserName()"
        />
      </div>
    </div>
    <div class="overflow-y-auto mt-1 pb-16 bg-gray200">
      <div v-if="!isLogin" class="w-full mb-6">
        <TheButton
          text="新規登録・ログイン"
          class="m-auto px-0 w-[182px]"
          @click-button="goToSignupForMobilityPage()"
        />
      </div>
      <div class="mx-5 px-5 py-2 bg-white rounded-[16px]">
        <!-- お知らせ -->
        <MenuCard
          :icon="notificationIcon()"
          name="お知らせ"
          @click-menu="gotoNotificationListPage()"
        />
        <!-- Suica -->
        <MenuCard
          v-if="isAndroidWebView()"
          icon="menu/suica.svg"
          name="モバイルSuica"
          @click-menu="onClickSuicaCard()"
        />
        <template v-for="menu in MenuList" :key="menu.id">
          <MenuCard
            :icon="menu.icon"
            :name="menu.name"
            :id="menu.id"
            @click-menu="onClickMenuCard(menu.id)"
          />
        </template>
        <MenuCard
          v-if="isLogin"
          icon="menu/logout.svg"
          name="ログアウト"
          @click-menu="logout()"
        />
      </div>
      <div class="mx-5">
        <img
          class="w-full my-6 aspect-[335/100]"
          src="@/assets/insuranceService/banner.png"
          @click="gotoInsuranceService()"
        />

        <div>
          <div
            v-for="linkToExternal in LinkToExternalList"
            :key="linkToExternal.name"
            class="flex items-center mb-4"
          >
            <div
              class="text-gray text-W5 text-[13px] leading-[13px]"
              @click="gotoExternalSite(linkToExternal.url)"
            >
              {{ linkToExternal.name }}
            </div>
            <IconLink
              class="w-3 h-3 ml-1 text-gray"
              @click="gotoExternalSite(linkToExternal.url)"
            />
          </div>
        </div>
        <div v-if="isLogin">
          <!-- セクション分けボーダー -->
          <div class="mt-5 w-40 border border-gray300"></div>
          <button
            class="mt-3 w-full text-W5 text-[13px] leading-[13px] text-left text-danger300 disabled:opacity-40"
            @click="isMembershipCancelModal = true"
            :disabled="isNotMembershipCancellable"
          >
            退会
          </button>
        </div>
        <div
          class="text-gray text-W5 text-[13px] leading-[13px] mt-10 flex items-center"
          @click="onClickLicenseInfo()"
        >
          ライセンス情報
          <IconRight class="ml-1 w-2 h-2" />
        </div>
        <div
          class="text-gray text-W5 text-[13px] leading-[13px] mt-4 mb-10 flex items-center"
          @click="gotoTimetableLicenseInfo()"
        >
          時刻表について
          <IconRight class="ml-1 w-2 h-2" />
        </div>
        <div
          class="text-gray text-W5 text-[13px] leading-[13px] w-full text-right"
        >
          ver{{ version }}
        </div>
      </div>
    </div>
    <Teleport to="#app-page">
      <Modal
        v-if="isEditUserNamePopup"
        class="modal"
        :isShowCloseButton="false"
        :isModalCenter="true"
        modalPaddingX="20px"
      >
        <div class="px-5 pt-9 pb-6">
          <div class="text-W5 text-[17px] leading-[17px]">
            ニックネームの変更
          </div>
          <!-- ユーザー名入力枠 -->
          <TextForm
            class="searchText mt-4"
            :inputClass="setInputColor()"
            :isBackButton="false"
            :text="userNameWord"
            placeholder="ニックネームを入力"
            maxlength="10"
            @input-text="setUpdateUserName"
            ref="textForm"
          />
          <div
            v-if="isOverUserName"
            class="mt-2 text-W2 text-[13px] leading-[13px] text-danger300"
          >
            全角5文字/半角10文字以内で入力してください。
          </div>
          <!-- ボタン -->
          <div class="flex justify-center mt-4 items-center">
            <div
              class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
              @click="closeEditUserName()"
            >
              キャンセル
            </div>
            <div
              class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
              :class="disableButtonStyle"
              @click="updateUserName()"
            >
              登録
            </div>
          </div>
        </div>
      </Modal>
      <!-- 画像トリミングポップアップ -->
      <TrimmingIconModal
        v-if="isTrimmingIconModal"
        primaryButtonText="次へ"
        SecondaryButtonText="キャンセル"
        @click-primary-button="onClickIconSave"
        @click-secondary-button="onClickIconCancel"
        ref="trimmingModal"
      />
      <!-- アイコンサイズ設定モーダル -->
      <Modal
        v-if="isSettingIconSizeModal"
        class="modal"
        :isShowCloseButton="false"
        :isModalCenter="true"
        modalPaddingX="20px"
      >
        <div class="px-5 pt-9 pb-6">
          <!-- タイトル -->
          <div class="text-W5 text-[17px] leading-[17px]">サイズ選択</div>
          <!-- サイズ設定 -->
          <div class="px-8 pt-4 flex flex-col items-center">
            <!-- イメージ画像 -->
            <img :src="iconSizeImagePicture" width="227" height="227" />
            <!-- サイズ設定ラジオボタン -->
            <div class="w-full flex justify-between max-w-[227px]">
              <BaseRadioButtonV2
                v-for="userIcon in userIconSizeList"
                :key="userIcon.KEY"
                :itemKey="userIcon.KEY"
                :label="userIcon.LABEL"
                v-model="selectedUserIconSize"
              />
            </div>
          </div>
          <!-- ボタン -->
          <div class="flex justify-center mt-4 items-center">
            <div
              class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
              @click="onClickSettingIconSizeCancel()"
            >
              キャンセル
            </div>
            <div
              class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
              @click="onClickSettingIconSizeOk()"
            >
              OK
            </div>
          </div>
        </div>
      </Modal>
      <!-- 退会確認ポップアップ -->
      <Modal
        v-if="isMembershipCancelModal"
        class="modal"
        :isShowCloseButton="false"
        :isModalCenter="true"
        modalPaddingX="20px"
      >
        <div class="pt-9 pb-6 mx-5">
          <div class="text-W5 font-semibold text-[17px] leading-[25.5px] mb-4">
            NAMOを退会します。この操作は取り消せませんが、よろしいですか？
          </div>
          <div class="text-W3 text-[15px] leading-[23px]">
            退会するとNAMOアカウントに関連付けられているすべての機能がご利用いただけなくなります。
          </div>
          <div class="flex justify-center mt-6 items-center">
            <div
              class="w-1/2 py-4 the-button-danger text-W7 text-[15px] leading-[15px] mr-3"
              @click="onClickMembershipCancel()"
            >
              退会
            </div>
            <div class="w-1/2">
              <TheButton
                text="もどる"
                :isDisabled="false"
                @click-button="isMembershipCancelModal = false"
              />
            </div>
          </div>
        </div>
      </Modal>
      <!-- 退会完了ポップアップ -->
      <Modal
        v-if="isMembershipCancelSuccessModal"
        class="modal"
        :isShowCloseButton="false"
        :isModalCenter="true"
        modalPaddingX="20px"
      >
        <div class="pt-9 pb-6 mx-5">
          <div class="text-W5 font-semibold text-[17px] leading-[25.5px] mb-4">
            退会手続きが完了しました。ご利用ありがとうございました。
          </div>
          <TheButton
            text="OK"
            @click-button="onClickMembershipCancelSuccessOk"
          />
        </div>
      </Modal>
      <!-- 退会エラーポップアップ -->
      <Modal
        v-if="isMembershipCancelFailedModal"
        class="modal"
        :isShowCloseButton="false"
        :isModalCenter="true"
        modalPaddingX="20px"
      >
        <div class="pt-9 pb-6 mx-5">
          <div class="text-W5 font-semibold text-[17px] leading-[25.5px] mb-4">
            エラーが発生しました。
          </div>
          <div class="mb-4">
            通信環境をご確認いただくか、時間を置いて再度お試しください
          </div>
          <div class="max-w-[164px] m-auto">
            <TheButton
              text="もどる"
              @click-button="isMembershipCancelFailedModal = false"
            />
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>
<script>
/**
 * メニュー画面
 */
import MenuCard from '@/components/menu/MenuCard.vue'
import Modal from '@/components/Modal.vue'
import TextForm from '@/components/TextForm.vue'
import deepcopy from 'deepcopy'
import MenuUtil from '@/mixins/menuUtil'
import Util from '@/mixins/util'
import NativeUtil from '@/mixins/nativeUtil'
import TrimmingIconModal from '@/components/TrimmingIconModal.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import IconLink from '@/components/icons/common/IconLink.vue'
import IconEdit from '@/components/icons/common/IconEdit.vue'
import IconRight from '@/components/icons/common/IconRight.vue'
import {logout} from '@/auth/AuthorizationInteractor.js'
import suica from '@/utils/suicaNativeUtil'
import log from 'loglevel'
import BaseRadioButtonV2 from '@/components/atoms/BaseRadioButtonV2.vue'

// メニュー項目
const MENU_ITEM = {
  FAVORITE: {id: 1, icon: 'menu/favorite.svg', name: 'お気に入り地点'},
  USERINFO: {id: 2, icon: 'menu/userinfo.svg', name: 'ユーザー情報'},
  PAYMENT: {id: 3, icon: 'menu/payment.svg', name: 'お支払い方法'},
  INSURANCE: {id: 4, icon: 'menu/insurance.svg', name: '保険金請求'},
}

// メニュー表示リスト
const MENU_LIST = [
  MENU_ITEM.FAVORITE,
  MENU_ITEM.USERINFO,
  MENU_ITEM.PAYMENT,
  MENU_ITEM.INSURANCE,
]

const LINK_TO_EXTERNAL_LIST = [
  {name: 'NAMO公式サイト', url: process.env.VUE_APP_NAMO_HP_URL},
  {name: '使い方', url: process.env.VUE_APP_NAMO_HELP_URL},
  {name: 'よくある質問', url: process.env.VUE_APP_NAMO_FAQ_URL},
  {name: 'お問い合わせ', url: process.env.VUE_APP_NAMO_CUSTOMER_SERVICE_URL},
  {name: '利用規約', url: process.env.VUE_APP_NAMO_AGREEMENT_URL},
  {
    name: 'プライバシーポリシー',
    url: process.env.VUE_APP_NAMO_PRIVACY_POLICY_URL,
  },
  {
    name: '特定商取引法に基づく表記',
    url: process.env.VUE_APP_NAMO_ACT_ON_SPECIFIED_COMMERCIAL_TRANSACTIONS_URL,
  },
  {name: '運営会社', url: process.env.VUE_APP_TMNF_COMPANY_OUTLINE_URL},
]

const MenuPage = {
  name: 'MenuPage',
  components: {
    MenuCard,
    Modal,
    TextForm,
    TrimmingIconModal,
    TheButton,
    IconLink,
    IconEdit,
    IconRight,
    BaseRadioButtonV2,
  },
  mixins: [Util, MenuUtil, NativeUtil],
  data() {
    return {
      isEditUserNamePopup: false, // ユーザー名変更ポップアップ
      userNameWord: '', // ユーザー名編集文字
      isTrimmingIconModal: false, // 画像変更ポップアップ
      isSettingIconSizeModal: false, // 画像サイズ変更ポップアップ
      isMembershipCancelModal: false, // 退会ポップアップ
      isMembershipCancelSuccessModal: false, // 退会成功ポップアップ
      isMembershipCancelFailedModal: false, // 退会失敗ポップアップ
      version: process.env.VUE_APP_PROJECT_VERSION,
      userIconSizeList: Object.values(this.$config.USER_ICON_SIZE_LIST), // ユーザーアイコンサイズ一覧
      selectedUserIconData: '', // 一時保存用の変更後アイコン画像
      selectedUserIconSize: this.$store.state.user.appInfo.userIconSize, // 一時保存用の変更後アイコンサイズ
    }
  },
  created() {},
  computed: {
    /**
     * ログイン状態かどうかを返却
     */
    isLogin() {
      return this.$store.state.user.auth.isLogin
    },
    /**
     * ユーザー情報
     */
    user() {
      return this.$store.state.user.appInfo
    },
    /**
     * メニュー一覧情報取得処理
     */
    MenuList() {
      return MENU_LIST
    },
    /**
     * 外部サイトへのリンク一覧情報取得
     */
    LinkToExternalList() {
      return LINK_TO_EXTERNAL_LIST
    },
    /**
     * ユーザー名の入力バイト数超過チェック
     * @return {boolean} true:バイト数超過
     */
    isOverUserName() {
      return (
        this.$config.MAX_USER_NAME_LENGTH <
        this.calculationByteOfInputChar(this.userNameWord)
      )
    },
    /**
     * 初回起動時ユーザー名設定ポップアップの登録ボタンのスタイル
     */
    disableButtonStyle() {
      return this.userNameWord == '' || this.isOverUserName
        ? 'opacity-40 pointer-events-none'
        : ''
    },
    /**
     * ユーザーアイコン取得
     */
    userIcon() {
      return this.user.icon
        ? this.user.icon
        : require('@/assets/menu/icon_guest.svg')
    },
    /**
     * 退会可能か判定する
     * ログイン中であり、乗り物予約中または利用中の場合trueを返す
     */
    isNotMembershipCancellable() {
      const usingMobilityType =
        this.$store.getters[
          'MobilityReservationStore/reservedOrUsingOfMobilityType'
        ]
      return (
        !this.isLogin ||
        usingMobilityType !== this.$config.USING_MOBILITY_TYPE.NONE
      )
    },
    /**
     * アイコンサイズのイメージ画像
     * @return イメージ画像
     */
    iconSizeImagePicture() {
      let iconName = ''
      switch (this.selectedUserIconSize) {
        case this.$config.USER_ICON_SIZE_LIST.SMALL.KEY:
          iconName = 'Image_Login_Auto_small.png'
          break
        case this.$config.USER_ICON_SIZE_LIST.LARGE.KEY:
          iconName = 'Image_Login_Auto_large.png'
          break
        case this.$config.USER_ICON_SIZE_LIST.MIDDLE.KEY:
        default:
          iconName = 'Image_Login_Auto_middle.png'
          break
      }
      return require('@/assets/' + iconName)
    },
  },
  methods: {
    /**
     * お知らせカードのアイコン取得
     */
    notificationIcon() {
      return this.$store.getters['existUnDisplayMessage']
        ? 'menu/notification_bell_badge.svg' // 未表示あり時は、バッジ付きアイコン
        : 'menu/notification_bell.svg' // 未表示なし時は、バッジなしアイコン
    },
    /**
     * お知らせ一覧画面に遷移
     */
    gotoNotificationListPage() {
      this.$router.push({name: this.$config.DISPLAY_NOTIFICATION_LIST})
    },
    /**
     * ユーザー名変更ポップアップ表示
     */
    openEditUserName() {
      this.userNameWord = deepcopy(this.user.name)
      this.isEditUserNamePopup = true
      this.$nextTick(() => {
        // 表示完了後にユーザー名入力欄にフォーカスをあてる
        this.$refs.textForm.focusInputForm()
      })
    },
    /**
     * ユーザー名変更ポップアップ非表示
     */
    closeEditUserName() {
      this.isEditUserNamePopup = false
      this.userNameWord = ''
    },
    /**
     * ユーザー名変更
     */
    updateUserName() {
      if (this.isOverUserName) {
        // 入力制限に引っかかった場合は登録しない
        return
      }

      // ストレージとStoreを更新
      this.updateAppInfo(this, this.userNameWord, 'name')
        .then((updateAppInfo) => {
          this.$store.commit('updateUserAppInfo', updateAppInfo)
        })
        .catch((e) => {
          // エラー発生時
          log.debug('Error update user name.', e)
        })
        .finally(() => {
          // ユーザー名変更ポップアップを閉じる
          this.closeEditUserName()
        })
    },
    /**
     * ユーザー名の入力欄のクラス属性設定
     */
    setInputColor() {
      // 文字超過時、赤枠赤背景にする
      return this.isOverUserName ? '!border-danger300 bg-danger100' : ''
    },
    /**
     * ユーザー名設定ポップアップで入力された名前を設定
     * @param {String} value 入力された名前
     */
    setUpdateUserName(value) {
      this.userNameWord = value
    },
    /**
     * メニューカード押下処理
     */
    onClickMenuCard(value) {
      switch (value) {
        case MENU_ITEM.FAVORITE.id:
          // お気に入り一覧画面を表示
          this.$router.push({name: this.$config.DISPLAY_FAVORITE_SPOT_LIST})
          break
        case MENU_ITEM.USERINFO.id:
          // 生体認証を挟む
          this.judgeNeededBioAuth()
          break
        case MENU_ITEM.INSURANCE.id:
          // 請求ログイン画面へ遷移する
          this.$router.push({name: this.$config.DISPLAY_INSURANCE_CLAIMS_LOGIN})
          break
        case MENU_ITEM.PAYMENT.id:
          // お支払い方法管理画面へ遷移する
          this.$router.push({
            name: this.$config.DISPLAY_PAYMENT_METHOD_MANAGEMENT,
          })
          break
        default:
          break
      }
    },
    /**
     * Suicaカード押下時に、Suicaトップ画面またはSuica詳細画面に遷移する
     */
    async onClickSuicaCard() {
      // 遷移元ソース保存
      this.$store.commit('SuicaStore/updateScreenTransitionSource', {
        displayName: this.$config.DISPLAY_MENU_TOP,
        footerId: this.$store.state.selectedFooterTab,
      })
      if (await suica.isLinked()) {
        // Suica連携済の場合
        this.$router.push({name: this.$config.DISPLAY_SUICA_DETAIL})
      } else {
        this.$router.push({name: this.$config.DISPLAY_SUICA_TOP})
      }
    },
    /**
     * 画像編集ボタン押下処理
     */
    async onClickIconEdit() {
      const isGranted = await this.checkCameraPermission()
      if (isGranted) {
        this.isTrimmingIconModal = true
        // ファイル選択ダイアログを表示する
        this.$nextTick(() => {
          // 表示完了後にトリミング開始を呼び出し
          this.$refs.trimmingModal.startTrimming()
        })
      }
    },
    /**
     * 画像保存ボタン押下時処理
     */
    onClickIconSave(data) {
      // 選択した画像を保持
      this.selectedUserIconData = data
      // 画像トリミングモーダルを閉じ、アイコンサイズ設定モーダルを表示する
      this.isTrimmingIconModal = false
      this.isSettingIconSizeModal = true
    },
    /**
     * 画像トリミングポップアップのキャンセルボタン押下処理
     */
    onClickIconCancel() {
      // 画像トリミングポップアップを閉じる
      this.isTrimmingIconModal = false
    },
    /**
     * アイコンサイズ設定モーダルのキャンセルボタン押下処理
     */
    onClickSettingIconSizeCancel() {
      // 保持していた画像を破棄
      this.selectedUserIconData = ''
      // アイコンサイズのロールバック
      this.selectedUserIconSize = structuredClone(
        this.$store.state.user.appInfo.userIconSize
      )
      // モーダルを閉じる
      this.isSettingIconSizeModal = false
    },
    /**
     * アイコンサイズ設定モーダルのOKボタン押下処理
     */
    onClickSettingIconSizeOk() {
      // ユーザー情報を保持
      const appInfo = {
        name: this.user.name,
        icon: this.selectedUserIconData,
        size: this.selectedUserIconSize,
      }
      // ユーザー情報を更新
      this.updateAppInfo(this, appInfo)
        .then((updatedData) => {
          this.$store.commit('updateUserAppInfo', updatedData)
        })
        .catch((e) => {
          // エラー発生時
          log.debug('Error update icon.', e)
        })
        .finally(() => {
          this.selectedUserIconData = ''
          this.isSettingIconSizeModal = false
        })
    },
    /**
     * 乗り物ようこそ画面への遷移処理
     */
    goToSignupForMobilityPage() {
      this.$router.push({name: this.$config.DISPLAY_SIGNUP_FOR_MOBILITY})
    },
    /**
     * ログアウトする
     */
    async logout() {
      await logout()
    },
    /**
     * 退会する
     */
    async onClickMembershipCancel() {
      this.isMembershipCancelModal = false
      await this.doCancelMemberShip()
    },
    /**
     * 退会成功時処理
     */
    async onClickMembershipCancelSuccessOk() {
      this.isMembershipCancelSuccessModal = false

      // 強制ログアウト処理を行う
      this.$store.commit('startLoading')
      await logout({force: true})
    },
    /**
     * 退会処理実行
     */
    doCancelMemberShip() {
      this.$store.commit('startLoading')
      const vm = this

      // 成功時
      const success = async () => {
        // Native側の退会処理
        vm.linkNativeCallback('cancelMembership')

        // LocalStorage削除
        localStorage.clear()

        // indexedDB削除
        await vm.$store.dispatch('IndexedDbStore/initializeDatabase')

        // 退会処理後にステータス取得処理を実行させたくないため、
        // ログイン済みフラグをfalseに更新
        vm.$root.isAuthLogin = false

        vm.$store.commit('endLoading')
        vm.isMembershipCancelSuccessModal = true
      }

      // 失敗時
      const failed = () => {
        vm.$store.commit('endLoading')
        vm.isMembershipCancelFailedModal = true
      }

      vm.$store.dispatch('cancelMembership', {
        success: success,
        failed: failed,
      })
    },
    /**
     * ログイン状態によって直接遷移するか、生体認証を挟むかを判定する
     * 乗り物ようこそ画面表示前に生体認証が入ることがあるため
     */
    judgeNeededBioAuth() {
      if (this.isLogin) {
        this.doBioAuth()
      } else {
        this.goToUserInfoDetail()
      }
    },
    /**
     * 生体認証を行う
     */
    doBioAuth() {
      // 成功時はユーザー情報画面に遷移
      const success = () => {
        this.goToUserInfoDetail()
      }
      // 失敗時は何もしない
      const failed = () => {}

      // 生体認証実行
      this.defineGetBioAuthResultMethod(success, failed)
      this.linkNativeCallback('bioAuth')
    },
    /**
     * ユーザー情報参照画面に遷移する処理
     */
    goToUserInfoDetail() {
      this.$router.push({name: this.$config.DISPLAY_USER_INFO_DETAIL})
    },
    /**
     * 保険サービス(外部サイト)を開く
     */
    gotoInsuranceService() {
      this.gotoExternalSite(process.env.VUE_APP_NAMO_INSURANCE_SERVICE_URL)
    },
    /**
     * 外部サイトを開く
     * @param {string} url
     */
    gotoExternalSite(url) {
      window.open(url, '_blank', 'noreferrer')
    },
    /**
     * ライセンス情報表示
     */
    onClickLicenseInfo() {
      this.showLicensePage()
    },
    /**
     * 時刻表ライセンス情報画面に遷移する
     */
    gotoTimetableLicenseInfo() {
      // 時刻表ライセンス情報画面へ遷移
      this.$router.push({name: this.$config.DISPLAY_TIMETABLE_LICENSE_INFO})
    },
  },
}
export default MenuPage
</script>
<style scoped>
.gestText {
  max-width: calc(100vw - 100px);
}
</style>
