/**
 * Store
 */
import {createStore} from 'vuex'
import * as Axios from '@/service/AxiosService'
import Util from '@/mixins/util'
import NativeUtil from '@/mixins/nativeUtil.js'
import RouteStore from './RouteStore.js'
import TransferStore from './TransferStore.js'
import TimeTableStore from './TimeTableStore.js'
import MenuStore from './MenuStore.js'
import IndexedDbStore from './IndexedDbStore.js'
import MobilityReservationStore from './MobilityReservationStore.js'
import ClaimStore from './ClaimStore.js'
import SuicaStore from './SuicaStore.js'
import CreditCardsStore from './CreditCardsStore.js'
import config from '@/const/const.js'
import deepcopy from 'deepcopy'
import dayjs from 'dayjs'
const constant = config.constant
import {getCurrentPosition} from '@/utils/locationUtil'
import nativeUtil from '@/mixins/nativeUtil'
import {
  getSearchOptionsFromLocalStorage,
  setSearchOptionsToLocalStorage,
} from '@/utils/localStorageUtil'

// ルート描画データの保持領域の初期値
const DEFAULT_DRAW_ROUTE_DATA = {
  carRecommend: null,
  carFree: null,
  carToll: null,
  bicycle: null,
  walk: null,
  trainBus: [],
}
// 検索条件の初期値
const DEFAULT_SEARCH_CONDITIONS = {
  start: {name: '', address: '', coord: ''}, // 出発位置
  via: [], // 経由地
  goal: {name: '', address: '', coord: ''}, // 到着位置
  timeType: {id: 1, name: '出発'}, // 出発到着情報
  targetTime: Util.methods.getNow(),
  searchOptions: {},
}

// ユーザー情報の初期値
const DEFAULT_USER_INFO = {
  myoji: '', // 苗字
  na: '', // 名前
  gender: constant.GENDER.NO_SELECT.key, // 性別
  birthday: '', // 生年月日
  address: '', // 住所
  tel: '', // 電話番号
  email: '', // メールアドレス
}

// spot検索用のキャンセルソース
let getSpotCancelSource = null

export default createStore({
  state: {
    user: {
      // Auth0情報
      auth: {
        isLogin: false, // Authログイン状態
      },
      // アプリ内情報
      appInfo: {
        name: '',
        icon: '',
        userIconSize: constant.USER_ICON_SIZE_LIST.MIDDLE.KEY,
      },
      loginMethod: '',
      info: deepcopy(DEFAULT_USER_INFO), // ユーザー情報
    },
    // お知らせ情報
    notificationList: {
      popup: null, // ポップアップ通知
      notification: null, // お知らせ通知
    },
    selectedNotificationMessage: {}, // 選択中のお知らせ通知情報
    currentPosition: {lat: null, lon: null}, // 現在地情報
    searchSpotKey: {}, // 検索条件の設定先
    isShowSuggestFlg: false, // 地点検索画面表示フラグ
    isShowInsuranceServiceBanner: true, // 保険サービスバナー表示フラグ
    isShowKeyboard: false, // ソフトウェアキーボード表示フラグ
    searchConditions: structuredClone(DEFAULT_SEARCH_CONDITIONS),
    selectedFooterTab: 1, // 選択中のタブID
    // 入力中フラグ
    inputFlag: false,
    // フッター表示フラグ
    isShowFooter: false,
    // パネルのスワイプモード
    swipeMode: constant.PANEL_MODE.MIDDLE,
    drawRouteScript: null, // 描画対象のルート形状のGeoJson文字列（描画しない場合はnull）
    routeType: null, // 描画対象のルート形状のタイプ
    // ルート描画データの保持領域
    drawRouteData: deepcopy(DEFAULT_DRAW_ROUTE_DATA),
    // ルート形状表示時にローディング不要フラグ
    isNoLoadingWhenDrawRoute: false,
    // ローディング表示フラグ
    loadingFlag: false,
    // 画面ロック用フラグ
    screenLockFlag: false,
    // エラーハンドラでキャッチしたエラーの種類
    errorKey: '',
    // 閉局中エラー表示用フラグ
    isShowClosedErrorModal: false,
    // 閉局中リスト
    closedDataList: [],
    // 各タブの表示ページ復元用
    currentPage: constant.FOOTER_LIST.map((footer) => [footer.name, '']),
    // マップ情報
    map: {
      // 中心位置
      center: {
        lat: 0,
        lng: 0,
      },
      // ズームレベル
      zoom: 0,
      // ピンの位置
      pin: {
        lat: 0,
        lng: 0,
      },
    },
    // 検索条件の各地点アイコン情報
    searchSpotsGlMarkers: [],

    // 初回のルート検索実施フラグ
    isInitSearchRoute: false,

    // アプリ設定情報
    appSettings: {},

    // Nativeの情報
    nativeInfo: {
      version: null,
    },

    // キーボードの高さ
    keyboardHeight: 0,

    // 上部セーフエリアの高さ
    topSafeAreaHeight: 0,

    // 下部セーフエリアの高さ
    bottomSafeAreaHeight: 0,
  },
  getters: {
    /**
     * 閉局の最終制限時間を取得する
     * @param {*} state
     * @returns 閉局の最終制限時間
     */
    getFinalLimitDate(state) {
      const closedDataList = state.closedDataList
      let finalLimitDate = ''
      for (const closedData of closedDataList) {
        const limitDate = closedData.limitDate
        if (
          !finalLimitDate ||
          dayjs(finalLimitDate).isBefore(dayjs(limitDate))
        ) {
          finalLimitDate = limitDate
        }
      }
      return finalLimitDate
    },
    /**
     * 現在地点を地点構造に成形して返却
     * @param {*} state
     * @returns 現在地の地点構造{lat,lon,coord}
     */
    currentSpotObject(state) {
      // 位置情報が未取得の場合はnullを返却
      const currentPos = state.currentPosition
      if (currentPos.lat == null || currentPos.lon == null) {
        return null
      }

      // 現在地情報の地点構造に成形して返却
      return {
        name: '現在地',
        address: '現在地住所',
        coord: currentPos.lat + ',' + currentPos.lon,
      }
    },
    /**
     * お知らせ通知一覧で未表示のお知らせが存在するかを判定する
     */
    existUnDisplayMessage(state) {
      // 表示済みお知らせの通知ID一覧取得
      const key = constant.DISPLAY_NOTIFICATION_MESSAGES
      const displayMessages = Util.methods.getLocalStorage(key) || []

      // サーバから取得してきた、最新のお知らせの通知ID一覧を取得
      const latestMessages =
        state.notificationList?.notification?.map((item) => item.messageId) ||
        []

      // 最新のお知らせの中で、未表示(配列に含まれていない)のお知らせが存在するかを判定し返却
      return latestMessages.some((msgId) => !displayMessages.includes(msgId))
    },
  },
  mutations: {
    /**
     * ユーザーのAuth情報更新
     * @param {*} state
     * @param {Object} obj Auth情報
     * @param {Boolean} obj.isLogin ログイン状態
     */
    updateUserAuthInfo(state, obj) {
      state.user.auth.isLogin = obj.isLogin
    },
    /**
     * アプリ内情報更新
     * @param {*} state
     * @param {Object} obj アプリ内情報
     * @param {String} obj.name 名前
     * @param {String} obj.icon アイコン
     * @param {String} obj.size アイコンサイズ
     */
    updateUserAppInfo(state, obj) {
      state.user.appInfo.name = obj.name
      state.user.appInfo.icon = obj.icon
      state.user.appInfo.userIconSize = obj.size
    },
    /**
     * ログイン設定更新
     * @param {*} state
     * @param {String} obj ログイン設定
     */
    updateLoginMethod(state, obj) {
      state.user.loginMethod = obj
    },
    /**
     * 現在位置の更新
     */
    updateCurrentPosition(state, obj) {
      // 位置情報に変更があった場合のみstateを更新する（環境によって位置が変わらない場合でも呼ばれるため）
      const lat = obj?.lat || null
      const lon = obj?.lon || null
      if (
        state.currentPosition.lat !== lat ||
        state.currentPosition.lon !== lon
      ) {
        state.currentPosition = {lat, lon}
      }
    },
    /**
     * ローディング開始
     */
    startLoading(state) {
      state.loadingFlag = true
    },
    /**
     * ローディング停止
     * @param {*} state
     */
    endLoading(state) {
      state.loadingFlag = false
    },
    /**
     * 画面ロック開始
     */
    startScreenLock(state) {
      state.screenLockFlag = true
    },
    /**
     * 画面ロック終了
     */
    endScreenLock(state) {
      state.screenLockFlag = false
    },
    /**
     * エラーメッセージ表示
     * @param {*} state
     * @param {*} errorKey
     */
    errorModal(state, errorKey) {
      state.errorKey = errorKey
    },
    /**
     * 閉局中エラー表示用フラグを更新する
     * @param {*} state
     * @param {Boolean} isShowClosedErrorModal 閉局中エラー表示用フラグの更新内容
     */
    updateIsShowClosedErrorModal(state, isShowClosedErrorModal) {
      state.isShowClosedErrorModal = isShowClosedErrorModal
    },
    /**
     * メンテナンス終了時刻を更新する
     * @param {*} state
     * @param {String} maintenanceEndTime メンテナンス終了時刻の更新内容
     */
    updateClosedDataList(state, closedDataList) {
      state.closedDataList = closedDataList
    },
    /**
     * 検索情報を更新する
     * @param {*} state
     * @param {*} obj
     */
    updateSearchConditions(state, obj) {
      if (obj.key) {
        if (obj.key == 'searchConditions') {
          // 検索オブションまるごと更新の場合
          state.searchConditions = obj.value
        } else {
          state.searchConditions[obj.key] = obj.value
        }
      }
    },
    /**
     * ルート検索条件情報を初期化
     * @param {*} state
     */
    initializeSearchConditions(state) {
      state.searchConditions = structuredClone(DEFAULT_SEARCH_CONDITIONS)
      // ローカルストレージから検索オプションを取得、初期値含む
      state.searchConditions.searchOptions = getSearchOptionsFromLocalStorage()
    },
    /**
     * 出発地と到着地を交換する
     * @param {*} state
     */
    exchangeSpots(state) {
      let buf = state.searchConditions.start
      state.searchConditions.start = state.searchConditions.goal
      state.searchConditions.goal = buf
    },
    /**
     * フッター部で選択された項目に更新
     * @param {Object} value フッター部で選択された項目
     */
    updateFooterTab(state, value) {
      state.selectedFooterTab = value
    },
    /**
     * フッター部の表示・非表示の切替
     * @param {*} state
     * @param {*} value true:フッター表示、false:フッター非表示
     */
    updateShowFooter(state, value) {
      state.isShowFooter = value
      // セーフエリアの色を変更するためフッター表示状態をnativeにも渡す
      NativeUtil.methods.updateState({isShowFooter: value})
    },
    /**
     * スワイプモードの切替
     * @param {*} state
     * @param {*} value true:フッター表示、false:フッター非表示
     */
    updateSwipeMode(state, value) {
      state.swipeMode = value
    },
    /**
     * 経由地枠の追加
     * @param {Object} viaData 空の経由地情報
     */
    addVia(state, viaData) {
      state.searchConditions.via.push(viaData)
    },
    /**
     * 経由地枠の削除
     * @param {Object} target 削除対象の経由地情報のID
     */
    deleteVia(state, target) {
      const viaSetData = state.searchConditions.via
      const updateViaArr = viaSetData.filter((viaData) => {
        return viaData.id !== target
      })

      if (viaSetData.length == updateViaArr.length) {
        // 経由地情報に変更がない場合は、何も行わず終了
        return
      }
      state.searchConditions.via = updateViaArr
    },
    /**
     * 最適画面表示用の経由地追加処理
     */
    addShowVia(state) {
      const addSpot = {
        id: Date.now(),
        name: '',
        address: '',
        coord: '',
      }
      state.searchConditions.via.push(addSpot)
    },
    /**
     * 地点検索画面表示フラグ
     * @param {*} value 表示するかどうか
     */
    updateIsShowSuggestFlg(state, value) {
      state.isShowSuggestFlg = value
    },
    /**
     * 保険サービスバナー表示フラグ
     * @param {*} value 表示するかどうか
     */
    updateIsShowInsuranceServiceBanner(state, value) {
      state.isShowInsuranceServiceBanner = value
    },
    /**
     * ソフトウェアキーボード表示フラグ
     * @param {*} value 表示されているかどうか
     */
    updateIsShowKeyboard(state, value) {
      state.isShowKeyboard = value
    },
    /**
     * 入力中フラグの更新
     * @param {*} value 入力中:true 入力終了:false
     */
    updateInputFlag(state, value) {
      state.inputFlag = value
    },
    /**
     * 検索オプションを更新する
     * @param {*} state
     * @param {*} obj
     */
    updateSearchOptions(state, obj) {
      if (obj.key == 'searchOptions') {
        // 検索オブションまるごと更新の場合
        state.searchConditions.searchOptions = obj.value
      } else {
        // 個々に更新する場合
        state.searchConditions.searchOptions[obj.key] = obj.value
      }

      // ローカルストレージに値を保存
      setSearchOptionsToLocalStorage(state.searchConditions.searchOptions)
    },
    /**
     * 現在ページ情報の更新
     * @param {*} state
     * @param {*} obj
     */
    updateCurrentPage(state, obj) {
      state.currentPage[obj.key] = obj.value
    },
    /**
     * マップ中心位置の更新
     * @param {*} state
     * @param {*} value
     */
    updateMapCenter(state, value) {
      state.map.center.lat = value.lat
      state.map.center.lng = value.lng
    },
    /**
     * マップズームレベルの更新
     * @param {*} state
     * @param {*} value
     */
    updateMapZoom(state, value) {
      state.map.zoom = value
    },
    /**
     * マップのピン情報の更新
     * @param {*} state
     * @param {*} value
     */
    updateMapPin(state, value) {
      state.map.pin.lat = value.lat
      state.map.pin.lng = value.lng
    },
    /**
     * ルートスクリプトの設定
     */
    updateDrawRouteScript(state, value) {
      state.drawRouteScript = value
    },
    /**
     * ルート形状のモビリティタイプの更新
     */
    updateRouteType(state, value) {
      state.routeType = value
    },
    /**
     * ルートスクリプトの初期化
     */
    clearDrawRouteScript(state) {
      state.routeType = null
      state.drawRouteScript = null
    },
    /**
     * 検索条件の設定先更新処理
     */
    updateSearchSpotKey(state, value) {
      state.searchSpotKey = value
    },
    /**
     * 検索地点のアイコン情報
     */
    updateSearchSpotGlMarkers(state, value) {
      state.searchSpotsGlMarkers = value
    },
    /**
     * ルート描画データ保持領域の初期化
     */
    clearDrawRouteData(state, target) {
      // 向き先の指定がない場合は全てのルート描画データを初期化
      if (target == null) {
        state.drawRouteData = deepcopy(DEFAULT_DRAW_ROUTE_DATA)
        return
      }

      // 向き先の指定が正しく指定されていない場合は何もしない
      if (!Object.keys(DEFAULT_DRAW_ROUTE_DATA).includes(target)) return

      // 向き先の指定が正しく指定されている場合は、指定したルート描画データのみを初期化
      state.drawRouteData[target] = deepcopy(DEFAULT_DRAW_ROUTE_DATA)[target]
    },
    /**
     * 初回のルート検索実施フラグの切替
     * @param {*} state
     * @param {Boolean} value true:初回のルート検索実施終了、false:ルート検索実施前
     */
    updateSearchRoute(state, value) {
      state.isInitSearchRoute = value
    },
    /**
     * ルート形状表示時にローディング不要フラグの切替
     * @param {*} state
     * @param {Boolean} value true:ルート形状表示時にローディングを表示しない、false:ルート形状表示時にローディングを表示する
     */
    updateIsNoLoadingWhenDrawRoute(state, value) {
      state.isNoLoadingWhenDrawRoute = value
    },
    /**
     * ユーザー情報の更新
     * @param {*} state Storeオブジェクト
     * @param {String} value 更新後データ
     */
    updateUserInfo(state, value) {
      state.user.info = value
    },
    /**
     * ユーザー情報のメールアドレス更新
     * @param {*} state Storeオブジェクト
     * @param {String} value 更新後メールアドレス
     */
    updateUserEmailAddress(state, value) {
      state.user.info.email = value
    },
    /**
     * アプリ設定情報の更新
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新後設定情報
     */
    updateAppSettings(state, obj) {
      state.appSettings = obj
    },
    /**
     * Storeの復元
     * @param {*} state Storeオブジェクト
     * @param {*} value IndexedDBに格納されていたデータ
     */
    restoreStore(state, value) {
      // userはローカルストレージやAPIのレスポンスから取得できるため無視
      state.currentPosition = value.currentPosition
      state.searchSpotKey = value.searchSpotKey
      state.isShowSuggestFlg = value.isShowSuggestFlg
      state.searchConditions = value.searchConditions
      state.selectedFooterTab = value.selectedFooterTab
      state.inputFlag = value.inputFlag
      state.isShowFooter = value.isShowFooter
      state.swipeMode = value.swipeMode
      state.drawRouteScript = value.drawRouteScript
      state.routeType = value.routeType
      state.drawRouteData = value.drawRouteData
      state.isNoLoadingWhenDrawRoute = value.isNoLoadingWhenDrawRoute
      state.loadingFlag = value.loadingFlag
      state.screenLockFlag = value.screenLockFlag
      state.errorKey = value.errorKey
      state.currentPage = value.currentPage
      state.map = value.map
      state.isInitSearchRoute = value.isInitSearchRoute
    },
    /**
     * Nativeの情報を更新
     */
    updateNativeInfo(state, value) {
      if (value) {
        state.nativeInfo = Object.assign({}, state.nativeInfo, value)
      }
    },
    /**
     * キーボードの高さの更新
     * @param {*} state Storeオブジェクト
     * @param {*} value キーボードの高さ
     */
    updateKeyboardHeight(state, value) {
      if (value?.keyboardHeight != null) {
        state.keyboardHeight = value.keyboardHeight
      }
    },
    /**
     * セーフエリアサイズの更新
     * @param {*} state Storeオブジェクト
     * @param {*} value セーフエリアの情報
     */
    updateSafeAreaSize(state, value) {
      if (value?.topSafeAreaHeight != null) {
        state.topSafeAreaHeight = value.topSafeAreaHeight
      }
      if (value?.bottomSafeAreaHeight != null) {
        state.bottomSafeAreaHeight = value.bottomSafeAreaHeight
      }
    },
    /**
     * お知らせ情報更新処理
     * @param {*} state Storeオブジェクト
     * @param {Object} value お知らせ情報
     * @param {Array} value.popup ポップアップ通知
     * @param {Array} value.notification お知らせ通知
     */
    updateNotificationMessage(state, value) {
      // 各メッセージごとに保持を行う。※取得できない場合は空配列
      state.notificationList.popup = value.popup != null ? value.popup : []
      state.notificationList.notification =
        value.notification != null ? value.notification : []
    },
    /**
     * 選択中のお知らせ通知情報 更新処理
     * @param {*} state Storeオブジェクト
     * @param {Object} value お知らせ通知情報
     */
    updateSelectedNotificationMessage(state, value) {
      state.selectedNotificationMessage = value
    },
    /**
     * 選択中のお知らせ通知情報 初期化処理
     * @param {*} state Storeオブジェクト
     */
    clearSelectedNotificationMessage(state) {
      state.selectedNotificationMessage = {}
    },
  },

  actions: {
    /**
     * MapScript取得API
     * @param {*} state このstoreのstate
     * @param {Function} success 成功コールバック
     * @param {Function} failed 失敗コールバック
     * @param {Function} error エラーコールバック
     */
    /* eslint-disable-next-line no-unused-vars */
    async getMapScript({state, dispatch}, {success, failed, error}) {
      // キャッシュのキー名
      const cacheName = 'mapScript'
      // 有効期限（1440分=24時間）
      const minuteOfExpiry = 1440

      // mapScriptをキャッシュから取得するPromise
      const mapScriptFromCache = new Promise((resolve) => {
        dispatch('IndexedDbStore/getCache', {
          cacheName,
        }).then((result) => {
          if (result) {
            // 取得できた場合のみ成功とする
            resolve(result)
          }
        })
      })

      // mapScriptをサーバーから取得するPromise
      const mapScriptFromServer = new Promise((resolve, reject) => {
        // mapScript取得
        Axios.get('mapScript', {})
          .then((res) => {
            if (res.statusCode === constant.API_STATUS_CODE_OK) {
              if (success) {
                const data = res.body
                resolve(data)
                // サーバーから取得できたらキャッシュを更新する
                dispatch('IndexedDbStore/saveCache', {
                  cacheName,
                  data,
                  minuteOfExpiry,
                })
              }
            } else {
              throw res
            }
          })
          .catch((e) => reject(e))
      })

      // キャッシュからの取得とサーバーからの取得を同時に実行し、早く取得できた方を引数にsuccessを呼ぶ
      return Promise.race([mapScriptFromCache, mapScriptFromServer])
        .then((mapScript) => success(mapScript))
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * 地点検索API
     * @param {*} state このstoreのstate
     * @param {Function} success 成功コールバック
     * @param {Function} failed 失敗コールバック
     * @param {Function} error エラーコールバック
     * @param {Function} cancel キャンセル時コールバック関数
     * @param {String} word 検索ワード
     */
    /* eslint-disable-next-line no-unused-vars */
    async getSpot({state}, {success, failed, error, cancel, word}) {
      // 座標取得
      let currentPosition = {}
      try {
        // TODO:
        // 現状の構成上、navigator.geolocation.watchPositionが常に起動している形となっている。
        // 起動中にnavigator.geolocation.getCurrentPositionを実行するとタイムアウトエラーの挙動となり、スポット検索の検索が遅れる。
        // 今後位置情報取得処理の見直しは行うが、現時点では今の構成のまま進めたい。
        // (常にwatchPositionを起動するのではなく、必要な時のみ起動する。)
        //
        // 実機ではgeolocationではなく端末の位置情報を取得する為問題ないが、ブラウザ起動時ではgeolocationとなり、
        // 上記の問題が発生し開発に影響がある為、回避策としてキャッシュしている座標を用いるように対応。
        //
        currentPosition = nativeUtil.methods.isFlutterWebView()
          ? await getCurrentPosition()
          : deepcopy(state.currentPosition)
      } catch (e) {
        // エラーが発生した場合は更新しない
      }

      // 重複している処理をキャンセルし、新しいCancelSourceを発行
      getSpotCancelSource = Util.methods.resetCancelSource(getSpotCancelSource)
      const params = {
        word: word,
        lat: currentPosition.lat || undefined,
        lon: currentPosition.lon || undefined,
      }
      // 地点情報取得
      return Axios.get('spot', params, {source: getSpotCancelSource})
        .then((res) => {
          if (res.statusCode === constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (e.message === 'Cancel') {
              if (cancel) {
                cancel()
              }
              return
            }

            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * 住所検索API
     */
    /* eslint-disable-next-line no-unused-vars */
    getAddress({state}, {success, failed, error, word}) {
      // 地点情報取得
      return Axios.get('address', {word: word})
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * ルート形状取得API
     * @param {*} state このstoreのstate
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     * @param {Object} target 検索条件(ルート情報取得と同じリクエスト)
     */
    shapeRoute({state}, {success, failed, error, target}) {
      // 地点情報取得
      return Axios.get('shapeRoute', target)
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            // 結果をstateに保持
            state.drawRouteScript = res.body
            // 取得したルート描画データを保持
            if (target.routeType == constant.MOBILITY_CARD_TYPE_TRAIN_BUS) {
              // 電車バスの場合は、該当の要素に格納
              const routeObj = {
                id: target.no,
                data: res.body,
              }
              state.drawRouteData[target.routeType].push(routeObj)
            } else {
              // 電車バス以外の場合は、そのまま格納
              state.drawRouteData[target.routeType] = res.body
            }

            if (success) {
              success()
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * ユーザー情報登録API
     * @param {*} state このstoreのstate
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     * @param {Sting} deviceToken デバイストークン
     */
    /* eslint-disable-next-line no-unused-vars */
    createUser({state}, {success, failed, error, deviceToken}) {
      // ユーザID取得
      return Axios.post(
        'user',
        {deviceToken: deviceToken},
        {needsAccessToken: true}
      )
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            if (success) {
              success(data.body)
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * ユーザー情報取得
     * @param {*} commit Storeオブジェクト
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     * @param {String} deviceToken デバイストークン
     */
    getUser({commit}, {success, failed, error, deviceToken}) {
      // リクエスト用パラメータ生成
      let param = {
        deviceToken: deviceToken,
      }
      // ユーザ情報取得
      return Axios.get('user', param, {needsAccessToken: true})
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            // Storeに取得結果を反映
            const user = res.body.appInfo
            const creditCardsDetail = res.body.creditCards?.detail || []
            const creditCardsStatus = res.body.creditCards?.status ?? ''
            if (!Util.methods.isEmpty(user)) {
              // ユーザー情報が存在する場合はコミットする
              commit('updateUserInfo', user)
            }
            commit('CreditCardsStore/updateCardList', creditCardsDetail)
            commit('CreditCardsStore/updatePaymentStatus', creditCardsStatus)
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * ユーザー情報更新
     * @param {*} commit Storeオブジェクト
     * @param {Function} success 成功時コールバック
     * @param {Function} failed 失敗時コールバック
     * @param {Function} error エラー時コールバック
     * @param {String} userInfo ユーザー情報
     */
    updateUser({commit}, {success, failed, error, userInfo}) {
      // リクエスト生成
      const req = {
        myoji: userInfo.myoji,
        na: userInfo.na,
        gender: userInfo.gender,
        birthday: userInfo.birthday,
        address: userInfo.address,
        tel: userInfo.tel,
        email: userInfo.email,
      }
      return Axios.put('user', req, {needsAccessToken: true})
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            // storeのユーザー情報を更新
            commit('updateUserInfo', userInfo)
            if (success) {
              success()
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * 退会
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     */
    cancelMembership(_, {success, failed, error}) {
      return Axios.del('user', {}, {needsAccessToken: true})
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            if (success) {
              success()
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * アプリ設定情報取得
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error 失敗時コールバック関数
     */
    getAppSettings({commit}, {success, failed, error}) {
      return Axios.get('appSettings', {})
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            if (success) {
              // ストアのアプリ設定情報を更新
              commit('updateAppSettings', res.body)
              success()
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * アプリログインAPI
     * ※ログインAPIは一箇所からしか呼び出されない、かつエラー時は固有のモーダルを表示するため、
     * 他の関数と異なり「200OK」以外は例外をスローさせ、呼び出し側で例外処理を行う。
     */
    login(_) {
      return Axios.post('login', {}, {needsAccessToken: true})
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) return
          else throw new Error('StatusCodeError')
        })
        .catch((e) => {
          throw e
        })
    },
    /**
     * お知らせ情報取得API実行
     * ・未ログイン時：/messageを実行
     * ・ログイン時 ：/messageWithLoginを実行
     */
    getNotificationMessages({state, commit}, {success, failed, error}) {
      // ログイン有無により呼び出す先を切り替える
      const isLogin = state.user.auth.isLogin
      return Axios.get(
        isLogin ? 'notificationWithLogin' : 'notification',
        {},
        {needsAccessToken: isLogin}
      )
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            commit('updateNotificationMessage', data.body)
            if (success) {
              success()
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
  },
  modules: {
    RouteStore,
    TransferStore,
    TimeTableStore,
    MenuStore,
    IndexedDbStore,
    MobilityReservationStore,
    ClaimStore,
    SuicaStore,
    CreditCardsStore,
  },
})
