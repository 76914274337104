<template>
  <div>
    <div class="flex flex-col">
      <div
        class="history-col flex items-center py-3 active:bg-blue100"
        v-for="history in historyList"
        :key="history.key"
        @click="$emit('click-suggest', history)"
      >
        <div><IconHistory class="m-2 w-4 h-4 self-center" /></div>
        <div class="text-left ml-2 self-center">
          <div class="text-W4 history-name text-[16px] leading-[19.5px]">
            {{ history.name }}
          </div>
          <div
            v-if="isHistoryAddress(history.address)"
            class="text-W2 history-address text-[13px] text-gray500 leading-[16.9px] mt-1"
          >
            {{ showAddress(history) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconHistory from '@/components/icons/common/IconHistory.vue'

const HistoryList = {
  name: 'HistoryList',
  components: {IconHistory},
  props: {
    historyList: Array,
  },
  emits: ['click-suggest'],
  data() {
    return {}
  },
  methods: {
    /**
     * 履歴データに住所があるか判定する
     */
    isHistoryAddress(address) {
      return this.$config.ZERO < address.length
    },
    /**
     * 表示する住所名を作成
     */
    showAddress(spot) {
      return spot.buildingName != null && spot.buildingName != ''
        ? spot.address.concat(' ', spot.buildingName)
        : spot.address
    },
  },
}
export default HistoryList
</script>
<style scoped></style>
