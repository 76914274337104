/**
 * 分から時分に変換する
 * @param {Number} minute 分
 * @returns {Object} 時分
 */
export function getTimeFromMinute(minute) {
  let time = {
    minutes: 0,
    hours: 0,
  }
  const hour = 60
  if (minute < hour) {
    // 60分未満の場合、そのままminutesに代入
    time.minutes = minute
  } else {
    // 60分以上の場合、時間・分に切り分けて代入
    time.hours = Math.floor(minute / hour)
    time.minutes = minute % hour
  }

  return time
}

/**
 * 分から「○時間○分」の文字列に変換する
 * @param {Number} minute 分
 * @returns {String} 時分 + 単位
 */
export function transferMinuteToText(minutes) {
  const time = getTimeFromMinute(minutes)
  return time.hours === 0 // eslint-disable-line no-magic-numbers
    ? `${time.minutes}分`
    : `${time.hours}時間${time.minutes}分`
}
