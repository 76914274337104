<template>
  <div class="flex flex-col">
    <div
      class="flex items-center active:bg-blue100 py-3 suggest-col"
      v-for="spot in spots"
      :key="spot.key"
      @click="$emit('click-suggest', spot)"
    >
      <div><IconSpot class="m-2 w-4 h-4 self-center" /></div>
      <div class="text-left ml-2">
        <div class="text-W4 text-[16px] leading-[19.5px]">
          {{ spot.name }}
        </div>
        <div class="text-W2 text-[13px] text-gray500 leading-[16.9px] mt-1">
          {{ spot.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSpot from '@/components/icons/common/IconSpot.vue'

const SuggestList = {
  name: 'SuggestList',
  components: {
    IconSpot,
  },
  props: {
    spots: Array,
  },
  emits: ['click-suggest'],
}
export default SuggestList
</script>
<style scoped></style>
