/**
 * 時刻表用Storeモジュール
 */
import * as Axios from '@/service/AxiosService'
import config from '@/const/const.js'
import Util from '@/mixins/util'
const constant = config.constant

// 駅/バス停名検索用のキャンセルソース
let getStationInfoCancelSource = null
const INITIAL_SELECTED_TIMETABLE_STATION = {
  name: '', //名称,
  address: '', //住所,
  ruby: '', //駅名読み方；
  nodeId: '', //ノードID
  types: '', //モビリティ種別(表示アイコン切り分け用)
}
const INITIAL_SELECTED_DIRECTION_INFO = {
  linkId: '', //路線ID
  direction: '', // 方面の向き('up' or 'down')
  stationType: '', // 選択した方面の駅種別
}
export default {
  namespaced: true,
  state: {
    // 選択した駅
    selectTimeTableStation: structuredClone(INITIAL_SELECTED_TIMETABLE_STATION),
    linksResult: null, // 路線ID取得結果
    timeTableResult: null, // 時刻表APIからの検索結果
    searchDate: '', // 時刻表の検索日付
    // 選択中の時刻表情報ID
    selectedTimeTableId: '',
    // 選択中の方面情報
    selectedDirectionInfo: structuredClone(INITIAL_SELECTED_DIRECTION_INFO),
    favoriteTimeTables: [], // お気に入りの時刻表
    // 更新対象のお気に入り情報
    targetFavoriteData: null,
    // お気に入り登録時刻表データ
    favoriteOperations: [],
    // お気に入り時刻表データ最新化中フラグ
    isModernizeFavoriteOperations: false,
  },
  mutations: {
    /**
     * 選択した駅の更新
     */
    updateSelectTimeTableStation(state, obj) {
      state.selectTimeTableStation = obj
    },
    /**
     * 検索日付の更新
     */
    updateSearchDate(state, obj) {
      state.searchDate = obj
    },
    /**
     * 路線IDの更新
     */
    updateLinksResult(state, obj) {
      state.linksResult = obj
    },
    /**
     * 時刻表検索結果の更新
     */
    updateTimeTableResult(state, obj) {
      state.timeTableResult = obj
    },
    /**
     * 時刻表情報IDの更新
     */
    updateSelectedTimeTableId(state, target) {
      state.selectedTimeTableId = target
    },
    /**
     * 方面情報の更新
     */
    updateDirectionInfo(state, obj) {
      state.selectedDirectionInfo = obj
    },
    /**
     * お気に入り更新
     */
    updateFavoriteTimetable(state, target) {
      state.favoriteTimeTables = target
    },
    /**
     * 更新対象のお気に入り情報の更新
     */
    updateTargetFavoriteData(state, target) {
      state.targetFavoriteData = target
    },
    /**
     * お気に入りの時刻表データ登録/更新
     * @param {Object} target 対象データ
     */
    updateFavoriteOperations(state, target) {
      const existData = state.favoriteOperations.find((item) => {
        return item.id === target.id
      })

      let newFavoriteOperations = structuredClone(state.favoriteOperations)
      if (existData) {
        // 更新
        newFavoriteOperations = newFavoriteOperations.map((item) => {
          return item.id === target.id ? target : item
        })
      } else {
        // 登録
        newFavoriteOperations.push(target)
      }

      state.favoriteOperations = newFavoriteOperations
    },
    /**
     * お気に入りの時刻表データ削除
     * @param {Object} id 対象データID
     */
    deleteFavoriteOperations(state, id) {
      // 削除
      let newFavoriteOperations = structuredClone(state.favoriteOperations)
      newFavoriteOperations = newFavoriteOperations.filter((item) => {
        return item.id != id // 指定したIDのみ除去
      })
      state.favoriteOperations = newFavoriteOperations
    },
    /**
     * お気に入りの時刻表データ追加
     */
    addFavoriteOperations(state, obj) {
      state.favoriteOperations.push(obj)
    },
    /**
     * お気に入りの時刻表データ最新化中フラグの更新
     */
    updateIsModernizeFavoriteOperations(state, obj) {
      state.isModernizeFavoriteOperations = obj
    },
    /**
     * 時刻表Storeの初期化処理
     * @param {*} state Storeオブジェクト
     */
    initTimeTableState(state) {
      state.selectTimeTableStation = structuredClone(
        INITIAL_SELECTED_TIMETABLE_STATION
      ) // 選択駅情報
      state.linksResult = null // 路線情報一覧
      state.timeTableResult = null // 時刻表情報一覧
      state.searchDate = '' // 検索条件(日付)
      state.selectedTimeTableId = '' // 選択したお気に入り時刻表識別ID
      state.selectedDirectionInfo = structuredClone(
        INITIAL_SELECTED_DIRECTION_INFO
      ) // 選択したお気に入り時刻表方面情報
      state.targetFavoriteData = null // お気に入り時刻表更新時に用いる識別ID
    },
    /**
     * Storeの復元
     * @param {*} state Storeオブジェクト
     * @param {*} value IndexedDBに格納されていたデータ
     */
    restoreStore(state, value) {
      state.selectTimeTableStation = value.selectTimeTableStation
      state.linksResult = value.linksResult
      state.timeTableResult = value.timeTableResult
      state.searchDate = value.searchDate
      state.selectedTimeTableId = value.selectedTimeTableId
      state.selectedDirectionInfo = value.selectedDirectionInfo
      state.favoriteTimeTables = value.favoriteTimeTables
      state.targetFavoriteData = value.targetFavoriteData
      state.favoriteOperations = value.favoriteOperations
      state.isModernizeFavoriteOperations = value.isModernizeFavoriteOperations
    },
  },
  actions: {
    /**
     * 駅/バス停名検索API
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラーコールバック関数
     * @param {Function} cancel キャンセル時コールバック関数
     * @param {String} word 検索をするワード
     */
    /* eslint-disable-next-line no-unused-vars */
    getStationInfo({state}, {success, failed, error, cancel, word}) {
      // 重複している処理をキャンセルし、新しいCancelSourceを発行
      getStationInfoCancelSource = Util.methods.resetCancelSource(
        getStationInfoCancelSource
      )
      // 駅名に紐づく駅情報を取得
      return Axios.get(
        'transportNodeByWord',
        {word: word},
        {source: getStationInfoCancelSource}
      )
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (e.message === 'Cancel') {
              if (cancel) {
                cancel()
              }
              return
            }
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * 時刻表検索API
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     * @param {String} nodeId 対象のNodeID
     * @param {String} linkId 対象の路線ID
     * @param {String} startDate 対象の日付 YYYY-MM-DD
     * @param {String} startTime 対象の日時 YYYY-MM-DDTHH:mm:ss
     */
    getTimeTables(
      _,
      {success, failed, error, nodeId, linkId, startDate, startTime}
    ) {
      let body = {
        nodeId: nodeId,
        linkId: linkId,
      }
      if (startDate) {
        // 日毎の検索
        body.startDate = startDate
      } else if (startTime) {
        // 設定日時から24時間分を検索
        body.startTime = startTime
      }
      // 時刻表を取得
      return Axios.post('transportDiagram', body)
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            if (success) {
              success(data.body)
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
  },
}
