import config from '@/const/const.js'
import * as Axios from '@/service/AxiosService'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
const constant = config.constant
// 日付の形式を日本のものに変更
dayjs.locale('ja')

export default {
  methods: {
    /**
     * オブジェクトが Null か否か
     * ※以下の場合はtrueを返却する
     * ・Boolean：false
     * ・Number：0
     * ・String：空文字
     * ・その他：null、undefined
     * @param {*} obj 対象のオブジェクト
     * @returns 上記を満たす場合、true
     */
    isNull(obj) {
      return obj ? false : true
    },
    /**
     * オブジェクトが空オブジェクトであるか否か
     * @param {*} obj オブジェクト
     */
    isEmpty(obj) {
      return Object.keys(obj).length === constant.ZERO
    },
    /**
     * 全角数字をNumber型の数字に変換する
     * @param {String} fullNumber 対象の全角数字
     * @returns {Number} 変換後の数字（数字以外の場合、NaNが返る）
     */
    fullNumberToNumber(fullNumber) {
      let halfNumber = fullNumber.replace(/[０-９]/g, (number) => {
        // 文字コードをシフト
        return String.fromCharCode(number.charCodeAt(0) - 0xfee0) // eslint-disable-line no-magic-numbers
      })
      return Number(halfNumber)
    },
    /**
     * 現在日時を取得
     * @returns
     */
    getNow(object) {
      return dayjs(object).format()
    },
    /**
     * 月日のみを抽出
     * @param { dayjs() } object
     * @param { String } notationType 日付の表記方法
     * @returns M月D日形式に変換して返却
     */
    getFormatDay(object, notationType) {
      switch (notationType) {
        case constant.NOTATION_TYPE.SLASH:
          return dayjs(object).format('M/D')
        default:
          return dayjs(object).format('M月D日')
      }
    },
    /**
     * 月日曜日のみを抽出
     * @param { dayjs() } object
     * @returns M月D日 (曜日)の形式に変換して返却
     */
    getFormatDayOfWeek(object) {
      return dayjs(object).format('M月D日 (ddd)')
    },
    /**
     * 時のみを抽出
     * @param { dayjs() } object
     * @returns dayjs()の値をH:mm形式で返却
     */
    getFormatHour(object) {
      return dayjs(object).format('HH')
    },
    /**
     * 時分のみを抽出
     * @param { dayjs() } object
     * @returns dayjs()の値をH:mm形式で返却
     */
    getFormatTime(object) {
      return dayjs(object).format('H:mm')
    },
    /**
     * 時分のみHH:mm形式を抽出
     * @param { dayjs() } object
     * @returns dayjs()の値をHH:mm形式で返却
     */
    getFormatTimeToHHmm(object) {
      return dayjs(object).format('HH:mm')
    },
    /**
     * 日時をYYYY-MM-DD形式にフォーマットする
     * @param { dayjs() } object
     * @returns dayjs()の値をHH:mm形式で返却
     */
    getFormatYearDate(object) {
      return dayjs(object).format('YYYY-MM-DD')
    },
    /**
     * 日時をYYYY/MM/DD形式にフォーマットする
     * @param { dayjs() } object
     * @returns dayjs()の値をHH:mm形式で返却
     */
    getFormatYearDateSlash(object) {
      return dayjs(object).format('YYYY/MM/DD')
    },
    /**
     * 日時をYYYY-MM-DD[T]HH:mm形式にフォーマットする
     * @param {*} object
     * @returns dayjs()の値をYYYY-MM-DD[T]HH:mm形式で返却
     */
    getFotmatYearDateTime(object) {
      // ※注意 日付と時刻の間に'T'をつけないとiosでフォーマットできません
      return dayjs(object).format('YYYY-MM-DD[T]HH:mm')
    },
    /**
     * 値が1キロ以上か判別する
     * @returns true: 1キロ以上、false: 1キロ未満
     */
    isKilo(value) {
      return constant.KILO <= value
    },
    /**
     * 値をキロ単位に変換し、指定の小数点位置で四捨五入する
     * @param {} value
     * @param {*} decimalPoint
     * @returns
     */
    roundAndConvertToKilo(value, decimalPoint) {
      // キロに変換
      const distanceKm = value / 1000 // eslint-disable-line no-magic-numbers
      // 小数点に合わせた10のべき乗値を計算
      const point = 10 ** decimalPoint // eslint-disable-line no-magic-numbers
      // 指定の小数点で四捨五入
      return `${this.addCommmaForDecimal(
        Math.round(distanceKm * point) / point
      )}`
    },
    /**
     * 小数点の位置変更処理
     * @param {Number} data 変換前の数値データ
     * @param {Number} decimalPoint 設定する小数点の位置
     * @returns 変換後の数値
     */
    setDecimalpoint(data, decimalPoint) {
      const point = 10 ** decimalPoint // eslint-disable-line no-magic-numbers
      return Math.round(data * point) / point
    },
    /**
     * ルート検索時間の表示用文字列を生成
     * @param {String} targetTime 日付
     * @param {Object} timeType 出発 / 到着 / 始発 / 終電
     * @param {String} isNeedDayOfWeek 日付
     * @param {String} targetTime 日付
     * @returns {Strig} ルート検索時間の表示用文字列
     */
    generateSearchDateTime(
      targetTime,
      timeType,
      isNeedDayOfWeek,
      notationType
    ) {
      // 本日 or M月D日 (曜日)形式で取得
      const current = this.generateSearchDate(
        targetTime,
        isNeedDayOfWeek,
        notationType
      )
      if (this.isNull(timeType)) {
        //timeTypeが空文字の場合
        return `${current} ${this.getFormatTime(targetTime)}`
      }

      // timeTypeの種別ごとに処理を分ける
      switch (timeType.id) {
        case constant.TIME_TYPE_ID.START:
        case constant.TIME_TYPE_ID.GOAL:
          // timeTypeが出発or到着の場合
          // 日付に 時刻を表示(HH:mm形式)し、 「出発」or「到着」を追記
          return `${current} ${this.getFormatTime(targetTime)} ${timeType.name}`
        case constant.TIME_TYPE_ID.FIRST:
        case constant.TIME_TYPE_ID.LAST:
          // timeTypeが始発or終電の場合
          // 日付のみに、「始発」or「終電」を追記
          return `${current} ${timeType.name}`
        default:
          return `${current}`
      }
    },
    /**
     * 本日 or 指定の表記方法で日付を取得する
     * @param {String} targetTime 日付
     * @param {Boolean} isNeedDayOfWeek 曜日が不要かどうか
     * @param {String} notationType 表記方法
     * @returns {Strig} 本日 or 指定の表記方法の日付
     */
    generateSearchDate(targetTime, isNeedDayOfWeek, notationType) {
      const targetDay = dayjs(targetTime)
      if (targetDay.isSame(dayjs(), 'day')) {
        //ストアの検索日付が現在日と同じである場合「本日」を表示
        return '本日'
      } else {
        // 指定された表記法と曜日表示有無で表示を変更する
        return isNeedDayOfWeek
          ? this.getFormatDayOfWeek(targetTime)
          : this.getFormatDay(targetTime, notationType)
      }
    },
    /**
     * ローカルストレージからオブジェクトを取得する
     * @param key:ローカルストレージのKey
     */
    getLocalStorage(key) {
      if (localStorage.getItem(key)) {
        try {
          // JSONをObjectに変換し返却
          return JSON.parse(localStorage.getItem(key))
        } catch (e) {
          // jsonに問題がある場合は履歴を削除し空を返却
          localStorage.removeItem(key)
          return JSON.parse(localStorage.getItem(key))
        }
      } else {
        return null
      }
    },
    /**
     * ローカルストレージにオブジェクトを格納する
     * @param key:ローカルストレージのKey
     * @param value:ローカルストレージ
     */
    setLocalStorage(key, value) {
      const parsed = JSON.stringify(value)
      localStorage.setItem(key, parsed)
    },

    /**
     * 整数をカンマ付きにする
     */
    addCommaForInteger(num) {
      return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    },
    /**
     * 小数をカンマ付きにする
     */
    addCommmaForDecimal(num) {
      const first = 1
      var s = String(num).split('.')
      var ret = String(s[constant.ZERO]).replace(
        /(\d)(?=(\d\d\d)+(?!\d))/g,
        '$1,'
      )
      if (s.length > first) {
        ret += '.' + s[first]
      }
      return ret
    },
    /**
     * 次のページのIDを取得する
     */
    getNextPageId(currentTabId, vm = this) {
      // storeに保持しているページ情報を取得
      let storePage = vm.$store.state.currentPage
      // フッターリストから現状表示中のタブの情報を取得する
      const footer = constant.FOOTER_LIST.find(
        (element) => element.id == currentTabId
      )
      let pageId
      if (footer) {
        if (storePage[footer.name] && storePage[footer.name] != '') {
          // storeに保持しているものがあればそのページを設定
          pageId = storePage[footer.name]
        } else {
          // storeに保持していなければ、初期ページを表示
          pageId = footer.initialPage
        }
      }
      return pageId
    },
    /**
     * sectionsのデータからNavitimeのbasis_routeに必要なデータのみの形に成型する
     * @param {Object} sections route_total等から取得したsections
     * @returns {Object} basisRoute 成型後のデータ
     */
    getBasisRoute(sections) {
      let basisRoute = []

      sections
        .filter((section) => {
          return (
            section.type == 'move' &&
            section.transport &&
            section.transport.links
          )
        })
        .forEach((section) => {
          section.transport.links.forEach((link) => {
            let baseData = {
              start: link.from.id,
              goal: link.to.id,
              direction: link.direction,
              link: link.id,
            }
            basisRoute.push(baseData)
          })
        })
      return basisRoute
    },
    /**
     * 配列の中でもっとも小さい値かを判定
     * @param {Array} baseRouteList ルート情報
     * @param {String} key 検索対象のキー
     * @param {*} target 比較対象の値
     * @return {Boolean} もっとも小さな値かどうか
     */
    isMinObject(baseRouteList, key, target) {
      let targetList = []
      try {
        // 各ルートの移動時間をリストに詰める
        for (const route of baseRouteList) {
          targetList.push(route[key])
        }
        // もっとも早い時間を算出
        const min = Math.min(...targetList)
        return target == min
      } catch (e) {
        return false
      }
    },
    /**
     * 受け取った文字列がBase64文字列かどうか
     * @param {*} data 対象文字列
     * @returns Base64文字列かどうか
     */
    isBase64Str(data) {
      // 先頭のdataURI部分を切り出し
      const substr = data.substr(data.indexOf(',') + 1) // eslint-disable-line no-magic-numbers

      // base64検出に用いる正規表現
      const regex =
        '^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$'

      // base64であればtrueを返却
      return substr.match(regex) != null
    },
    /**
     * 入力文字のバイト数算出
     * @param {String} str 算出対象文字
     * @returns {Strig} 入力文字のバイト数
     */
    calculationByteOfInputChar(str) {
      let len = 0

      for (let i = 0; i < str.length; i++) {
        // 正規表現を用いて1文字単位で判定し、半角は1、全角は2とカウントする
        str[i].match(/[ -~｡-ﾟ]/) ? (len += 1) : (len += 2) // eslint-disable-line no-magic-numbers
      }

      return len
    },
    /**
     * 指定したDomのマージンを返却する
     * @param {Document} element DOMのElement要素
     * @returns 各マージンの値
     */
    getMarginStyle(element) {
      const styles = window.getComputedStyle(element)
      return ['', 'right', 'top', 'bottom', 'left'].reduce((obj, key) => {
        return {
          ...obj,
          // eslint-disable-next-line no-magic-numbers
          [key]: parseFloat(styles.getPropertyValue(`margin-${key}`)) || 0,
        }
      })
    },
    /**
     * ランダムな数値を返却
     * @param {*} max 最大値
     * @returns ランダム数値
     */
    getRandomInt(max) {
      return Math.floor(Math.random() * max)
    },
    /**
     * ランダムでエラー時の画像パスを返却
     * @returns 画像パス
     */
    getErrorAssetsSrc() {
      // 対象となるエラー画像
      const fileArr = ['ErrorIconDog1.png', 'ErrorIconDog2.png']

      // ランダムにエラー画像のファイル名を取得
      const target = this.getRandomInt(fileArr.length)
      const fileName = fileArr[target]

      // 画像パスを返却
      return require('@/assets/error/' + fileName)
    },
    /**
     * 全角数値を半角数値にして返却
     * @param {String} str 変換対象数値(文字列型)
     * @returns 変換後半角数値
     */
    getConvertedHankakuNumber(str) {
      return str.replace(
        /[０-９]/g,
        (num) => String.fromCharCode(num.charCodeAt(0) - 0xfee0) // eslint-disable-line no-magic-numbers
      )
    },
    /**
     * 文字のマスク化処理
     * 例) maskWords('hogehoge', 6) -> hogeh＊＊＊ :6文字目からマスクする
     * @param {String} words マスク対象の文字列
     * @param {Number} startNumber マスク開始位置
     * @returns マスク化した文字列
     */
    maskWords(words, startNumber) {
      const firstSpell = 0
      const startMaskNumber = startNumber - 1 // eslint-disable-line no-magic-numbers

      // サロゲートペア文字も考慮して、この定義で文字数を取得する
      const len = [...words].length

      // マスク文字数分を用意
      const maskStrLen =
        len - [...words].slice(firstSpell, startMaskNumber).length
      const maskStr = [...Array(maskStrLen)].map(() => {
        return '＊'
      })

      // 表示文字とマスク文字を結合した結果
      return [...words]
        .slice(firstSpell, startMaskNumber)
        .concat(maskStr)
        .join('')
    },
    /**
     * オブジェクト内に目当てのキーが入っているか判定
     * @param {*} targetObj 確認対象オブジェクト
     * @param {String} key 目当てのキー
     * @returns 判定結果
     */
    isContainsKey(targetObj, key) {
      return Object.prototype.hasOwnProperty.call(targetObj, key)
    },
    /**
     * キャンセル実行処理とキャンセルソースの再発行を行う
     * @param {CancelSource} cancelSource キャンセルソース
     * @returns 新しいキャンセルソース
     */
    resetCancelSource(cancelSource) {
      if (!this.isNull(cancelSource)) {
        // 重複して実行される場合は、古い検索をcancel
        cancelSource.cancel('Request Canceled')
      }

      // 新しいcancelSource発行し更新
      return Axios.CancelToken.source()
    },
    /**
     * 緯度経度に分離させる
     * @param {String} coords 一纏めになっている緯度経度
     * @returns 分離させた緯度経度
     */
    disassembleLatAndLon(coords) {
      const LAT_INDEX = 0
      const LON_INDEX = 1
      return {
        lat: coords.split(',')[LAT_INDEX],
        lon: coords.split(',')[LON_INDEX],
      }
    },
  },
}
