/**
 * 定数ファイル
 * ＜概要＞
 * ここでは汎用的な定数をまとめている。
 * Object.freezeにより書き換え不可。
 *
 * ＜禁止事項＞
 * ・日本語を含む文字列の定義。言語対応で行うこと。
 *
 * ＜注意事項＞
 * ・配列および連想配列の場合、配列内の値は書き換え可能になってしまう。
 *   そのため、個別にObject.freezeの設定が必要になるので注意すること。
 */

exports.constant = Object.freeze({
  /**
   * NAMOで汎用的に利用する文字列は個別に定義
   */
  PAYMENT_NG: 'paymentNG',

  // 汎用的に使う0
  ZERO: 0,
  // キロ単位変換用
  KILO: 1000,
  // 小数点第1位計算用
  POINT_ONE: 10,
  // 小数点第2位計算用
  POINT_TWO: 100,
  // スワイプ移動範囲閾値
  PANEL_SWIPE_UP_MOVED_DISTANCE: -20, // 上にスワイプ閾値
  PANEL_SWIPE_DOWN_MOVED_DISTANCE: 20, // 下にスワイプ閾値
  ARRAY_INDEX_ZERO: 0, // スワイプ配列のインデックス
  // API応答
  API_STATUS_CODE_OK: 200,
  API_STATUS_CODE_CLIENT_ERROR: 400, // Bad Request
  API_STATUS_CODE_UNAUTHORIZED: 401, // Unauthorized
  API_STATUS_CODE_FORBIDDEN: 403, // Forbidden
  API_STATUS_CODE_SERVER_ERROR: 500, // Internal Server Error
  API_STATUS_CODE_SERVICE_UNAVAILABLE: 503, // Service Unavailable

  // メンテンスエラーメッセージ
  API_MAINTENANCE_ERROR_MESSAGE: 'Namo Maintenance',

  // 現在位置取得の応答ステータスコード
  POSITION_PERMISSION_DENIED: 1, // 位置情報の利用許可なし
  POSITION_UNAVAILABLE: 2, // 現在地取得失敗
  POSITION_TIMEOUT: 3, //タイムアウト

  // 現在地点初期値(東京駅)
  DEFAULT_CURRENT_POSITION: Object.freeze({
    LAT: '35.6809591',
    LON: '139.7673068',
  }),

  // 現在地名
  CURRENT_NAME: '現在地',
  // スワイプの向き
  SWIPE_ACTION_UP: 'up',
  SWIPE_ACTION_DOWN: 'down',

  // パネルモード
  PANEL_MODE: Object.freeze({
    MAX: 1,
    MIDDLE: 2,
    MIN: 3,
  }),

  // LocalStorage Key
  SEARCH_HISTORY_STORAGE_KEY: 'searchHistory',
  TRANSFER_HISTORY_STORAGE_KEY: 'transferHistory',
  TRANSFER_DATE: 'transferDate',
  TRANSFER_CONDITIONS_HISTORY_STORAGE_KEY: 'transferConditionsHistory',
  TIME_TABLE_HISTORY_STORAGE_KEY: 'timeTableHistory',
  FAVORITE_TIME_TABLE: 'favoriteTimeTable',
  FOOTER_TAB: 'footerTab',
  APP_INFO_KEY: 'appInfoKey',
  LOGIN_SETTING_KEY: 'loginSettingKey',
  FAVORITE_SPOT_LIST_STORAGE_KEY: 'favoriteSpotListStorageKey',
  FORCE_RE_LOGIN: 'forceReLogin',
  NEXT_PAGE_IN_AUTH: 'nextPage',
  MESSAGE_LOG_LENGTH: 'messageLogLength',
  LAST_AGREED_TERMS_REVISION_DATE: 'lastAgreedTermsRevisionDate',
  USER_ACCOUNT_DEFECT: 'userAccountDefect',
  IS_READ_TAXI_PASSIVE_CANCEL_MODAL: 'isReadTaxiPassiveCancelModal',
  USER_SETUP_COMPLETED: 'userSetupCompleted',
  READ_NOTIFICATION_MESSAGES: 'readNotificationMessages',
  DISPLAY_NOTIFICATION_MESSAGES: 'displayNotificationMessages',
  ROUTE_SEARCH_OPTIONS_KEY: 'routeSearchOptions',

  // 地点先のKey名
  SPOT_TYPE_START: 'start',
  SPOT_TYPE_VIA: 'via',
  SPOT_TYPE_GOAL: 'goal',

  // 経由地設定枠
  VIA_DATA_MAX_NUMBER: 3,
  // お気に入り地点最大登録件数
  FAVORITE_SPOT_MAX_NUMBER: 5,

  // お気に入り地点選択
  FAVORITE_SPOT_HOME: 'home',
  FAVORITE_SPOT_WORK: 'work',
  FAVORITE_SPOT: 'favorite',

  // MapScriptのZoomレベル
  MAP_ZOOMLEVEL: Object.freeze({
    ZOOM_10_M: 20,
    ZOOM_200_M: 15,
    ZOOM_5_KM: 10,
    ZOOM_100_KM: 5,
  }),
  // マップの方角
  MAP_DIRECTION: Object.freeze({
    NORTH: 0, // 北
  }),
  // マップのモード
  MAP_MODE: Object.freeze({
    NORTH_UP: 0,
    HEADING_UP: 1,
    OPERATING: 2,
  }),
  // NAVITIME マップ範囲
  NAVITIME_MAP_VALID_AREA: Object.freeze({
    LAT: Object.freeze({
      MIN: 23.0,
      MAX: 46.0,
    }),
    LON: Object.freeze({
      MIN: 123.0,
      MAX: 147.0,
    }),
  }),

  // マップ上のユーザーアイコンの大きさ
  // ※現在地アイコンとの余白を調整するため、offsetパラメータを定義
  USER_ICON_SIZE_LIST: Object.freeze({
    LARGE: {
      KEY: 'LARGE',
      LABEL: '大',
      VALUE: {width: 120, height: 120, offsetY: -6},
    },
    MIDDLE: {
      KEY: 'MIDDLE',
      LABEL: '中',
      VALUE: {width: 90, height: 90, offsetY: 0},
    },
    SMALL: {
      KEY: 'SMALL',
      LABEL: '小',
      VALUE: {width: 50, height: 50, offsetY: 10},
    },
  }),

  // ユーザー名の最大文字バイト数（全角5文字、半角10文字）
  MAX_USER_NAME_LENGTH: 10,

  // ポップアップ種別
  MODAL_CALENDER_SELECT: 'selectDatetime', //時刻設定ポップアップ

  /**
   * 各画面識別用
   */
  // セットアップ
  DISPLAY_LOAD: 'Load', // ロード画面
  DISPLAY_SETUP_WELCOME: 'Welcome', // ようこそ画面
  DISPLAY_SETUP_EMAIL_VERIFIED: 'SetupEmailVerified', // メール送信完了画面
  DISPLAY_SETUP_LOGIN_METHOD: 'SetupLoginMethod', // ログイン設定画面
  DISPLAY_SETUP_USER_INFO: 'SetupUserInfo', // ユーザー情報登録画面
  DISPLAY_SETUP_USER_INFO_FINISHED: 'SetupUserInfoFinished', // ユーザー情報登録完了画面
  DISPLAY_SIGNUP_FOR_MOBILITY: 'SignupForMobility', // 登録・ログイン促進画面

  // スポット検索
  DISPLAY_SEARCH_TOP: 'SearchTop', // 検索Top画面
  DISPLAY_SUGGEST: 'Suggest', // 地点選択画面
  DISPLAY_ROUTE_BY_OPTIMAL: 'RouteByOptimal', // ルート検索画面（最適）
  DISPLAY_ROUTE_BY_TRAIN_BUS: 'RouteByTrainBus', // ルート検索画面（電車・バス）
  DISPLAY_ROUTE_BY_CAR: 'RouteByCar', // ルート検索画面（車）
  DISPLAY_ROUTE_BY_BICYCLE: 'RouteByBicycle', // ルート検索画面（自転車）
  DISPLAY_ROUTE_BY_WALK: 'RouteByWalk', // ルート検索画面（徒歩）
  DISPLAY_ROUTE_DETAIL_BY_TRAIN_BUS: 'RouteDetailByTrainBus', // ルート検索画面（電車・バス）
  DISPLAY_ROUTE_NAVI: 'RouteNavi', // ルートナビ画面
  DISPLAY_DESTINATION_SETTING: 'DestinationSetting', // 目的地設定画面画面

  // 乗換検索
  DISPLAY_TRANSFER_DETAIL: 'TransferDetail', // 乗り換え案内詳細画面

  // 時刻表検索
  DISPLAY_TIME_TABLE_TOP: 'TimeTableTop', // 時刻表Top画面
  DISPLAY_TIME_TABLE_DIRECTION: 'TimeTableDirection', // 時刻表方面画面

  // メニュー
  DISPLAY_MENU_TOP: 'Menu', // メニューTop画面
  DISPLAY_FAVORITE_SPOT_LIST: 'FavoriteSpotList', // お気に入り地点一覧画面
  DISPLAY_FAVORITE_SPOT_SUGGEST: 'FavoriteSpotSuggest', // お気に入り地点検索画面
  DISPLAY_FAVORITE_SPOT_DETAIL: 'FavoriteSpotDetail', // お気に入り地点詳細画面
  DISPLAY_USER_INFO_DETAIL: 'UserInfoDetail', // ユーザー情報参照画面
  DISPLAY_PAYMENT_METHOD_MANAGEMENT: 'PaymentMethodManagement', // 支払い方法管理画面
  DISPLAY_TIMETABLE_LICENSE_INFO: 'TimetableLicenseInfo', // 時刻表ライセンス情報画面
  DISPLAY_NOTIFICATION_LIST: 'NotificationList', // お知らせ一覧画面
  DISPLAY_NOTIFICATION_DETAIL: 'NotificationDetail', // お知らせ詳細画面

  // 乗り物手配
  DISPLAY_ARRANGEMENT_TOP: 'ArrangementTop', // 手配TOP画面

  // 自転車予約
  DISPLAY_BICYCLE_RESERVATION_TOP: 'BicycleReservationTop', // 自転車予約TOP画面
  DISPLAY_BICYCLE_RESERVATION_PORT_ON_MAP: 'BicycleReservationPortOnMap', // 自転車 地図から予約画面
  DISPLAY_BICYCLE_RESERVATION_SELECT: 'BicycleReservationSelect', // 自転車選択画面
  DISPLAY_BICYCLE_RESERVATION_PAYMENT_CONFIRM:
    'BicycleReservationPaymentConfirm', // 自転車支払確認画面
  DISPLAY_BICYCLE_RESERVATION_CONFIRMED: 'BicycleReservationConfirmed', // 自転車予約確定画面

  DISPLAY_BICYCLE_RESERVATION_RETURN_PORT_ON_MAP:
    'BicycleReservationReturnPortOnMap', // 自転車 地図から返却画面
  DISPLAY_BICYCLE_RESERVATION_RETURN_PORT_DETAIL:
    'BicycleReservationReturnPortDetail', // 自転車 返却ポート詳細画面

  // タクシー予約
  DISPLAY_TAXI_RESERVATION_TOP: 'TaxiReservationTop', // タクシー予約TOP画面
  DISPLAY_TAXI_RESERVATION_CONFIRM: 'TaxiReservationConfirm', // タクシー予約内容確認画面
  DISPLAY_TAXI_RESERVATION_PAYMENT_CONFIRM: 'TaxiReservationPaymentConfirm', // タクシー支払確認画面
  DISPLAY_TAXI_RESERVATION_COMPLETED: 'TaxiReservationCompleted', // タクシー手配完了画面
  DISPLAY_TAXI_RESERVATION_CHAT: 'TaxiReservationChat', // タクシー手配用チャット画面

  // 保険金請求
  DISPLAY_INSURANCE_CLAIMS_LOGIN: 'InsuranceClaimsLogin', // 保険金請求ログイン画面
  DISPLAY_INSURANCE_CLAIMS_TOP: 'InsuranceClaimsTop', // 保険金請求Top画面
  DISPLAY_INSURANCE_CLAIM_DETAIL: 'InsuranceClaimDetail', // 請求候補登録・編集画面
  DISPLAY_INSURANCE_CLAIM_CONFIRMED: 'InsuranceClaimConfirmed', // 請求手続き確認画面

  // Suica
  DISPLAY_SUICA_TOP: 'SuicaTop', // SuicaTop画面
  DISPLAY_SUICA_DETAIL: 'SuicaDetail', // Suica詳細画面

  // 決済
  DISPLAY_PAYMENT_METHOD_SELECT: 'PaymentMethodSelect', // 支払い方法選択画面
  DISPLAY_REGISTER_CREDIT_CARD: 'RegisterCreditCard', // お支払い方法を登録画面

  /**
   * エラー表示内容
   */
  // APIエラー
  API_ERROR: Object.freeze({
    // クレジットカード決済
    CREDIT_CARD_PAYMENT: Object.freeze({
      // 流量制限オーバー
      FLOW_LIMIT: Object.freeze({
        code: 'MS001',
        title: '現在、大変混み合っております。',
        message: '申し訳ございませんが、時間を置いて再度お試しください。',
      }),
      // 利用不可
      UNAVAILABLE: Object.freeze({
        code: 'MS002',
        title:
          'このクレジットカードはご利用になれません。別のお支払い方法を選択してください。',
        message: '',
      }),
      // 残高不足
      INSUFFICIENT_FUNDS: Object.freeze({
        code: 'MS003',
        title:
          'カード残高不足のため、決済を完了できませんでした。別のお支払い方法を選択してください。',
        message: '',
      }),
      // 限度額超過
      AMOUNT_LIMIT: Object.freeze({
        code: 'MS004',
        title:
          'カード限度額を超えているため、決済を完了できませんでした。別のお支払い方法を選択してください。',
        message: '',
      }),
    }),
  }),

  // ログイン設定リスト
  LOGIN_SETTINGS: Object.freeze([
    Object.freeze({
      KEY: 'AUTO_AUTH',
      LABEL: '自動でログイン',
    }),
    Object.freeze({
      KEY: 'DEVICE_AUTH',
      LABEL: 'スマートフォンのロック解除方法を使用',
    }),
    Object.freeze({
      KEY: 'EMAIL_PW_AUTH',
      LABEL: 'メールアドレスとパスワードを使う',
    }),
  ]),

  // モビリティ
  MOBILITY: Object.freeze({
    TRAIN_BUS: 'trainBus',
    CAR: 'car',
    WALK: 'walk',
    BICYCLE: 'bicycle',
  }),

  // モビリティカード
  MOBILITY_CARD_TYPE_TRAIN_BUS: 'trainBus',
  MOBILITY_CARD_TYPE_CAR: 'car',
  MOBILITY_CARD_TYPE_WALK: 'walk',
  MOBILITY_CARD_TYPE_BICYCLE: 'bicycle',

  // 移動モビリティ
  MOVE: Object.freeze({
    WALK: 'walk',
    CAR: 'car',
    BICYCLE: 'bicycle',
    FLIGHT: 'domestic_flight',
    FERRY: 'ferry',
    SHUTTLE_BUS: 'shuttle_bus',
    LOCAL_BUS: 'local_bus',
    HIGHWAY_BUS: 'highway_bus',
    SUPEREXPRESS_TRAIN: 'superexpress_train',
    SLEEPER_ULTRAEXPRESS: 'sleeper_ultraexpress',
    ULTRAEXPRESS_TRAIN: 'ultraexpress_train',
    EXPRESS_TRAIN: 'express_train',
    RAPID_TRAIN: 'rapid_train',
    SEMIEXPRESS_TRAIN: 'semiexpress_train',
    LOCAL_TRAIN: 'local_train',
  }),

  // フッターID
  FOOTER_ID_SPOT: 1,
  FOOTER_ID_TRANSFER: 2,
  FOOTER_ID_ARRANGEMENT: 3,
  FOOTER_ID_TIME_TABLE: 4,
  FOOTER_ID_MENU: 5,

  // フッターリスト
  FOOTER_LIST: Object.freeze([
    Object.freeze({
      id: 1, // eslint-disable-line no-magic-numbers
      name: 'spot',
      displayName: 'ルート',
      iconName: Object.freeze({
        on: 'IconTabRouteWhite.svg',
        off: 'IconTabRouteGray.svg',
      }),
      initialPage: 'SearchTop',
    }),
    Object.freeze({
      id: 4, // eslint-disable-line no-magic-numbers
      name: 'timeTable',
      displayName: '時刻表',
      iconName: Object.freeze({
        on: 'IconTabTimeTableWhite.svg',
        off: 'IconTabTimeTableGray.svg',
      }),
      initialPage: 'TimeTableTop',
    }),
    Object.freeze({
      id: 3, // eslint-disable-line no-magic-numbers
      name: 'arrangement',
      displayName: '乗物手配',
      iconName: Object.freeze({
        on: 'IconTabArrangementBlue.svg',
        off: 'IconTabArrangement.svg',
        bicycle: 'IconTabArrangementBicycle.svg', // 自転車予約/利用中時
        taxi: 'IconTabArrangementTaxi.svg', // タクシー手配/利用中時
      }),
      initialPage: 'ArrangementTop',
    }),
    Object.freeze({
      id: 2, // eslint-disable-line no-magic-numbers
      name: 'transfer',
      displayName: '乗換案内',
      iconName: Object.freeze({
        on: 'IconTabTransferWhite.svg',
        off: 'IconTabTransferGray.svg',
      }),
      initialPage: 'TransferTop',
    }),
    Object.freeze({
      id: 5, // eslint-disable-line no-magic-numbers
      name: 'menu',
      displayName: 'メニュー',
      iconName: Object.freeze({
        on: 'IconTabMenuWhite.svg',
        off: 'IconTabMenuGray.svg',
      }),
      initialPage: 'Menu',
    }),
  ]),

  // フッターの高さ
  FOOTER_HEIGHT: 52,

  // 車種別
  CAR_TYPE: Object.freeze({
    RECOMMEND: 'carRecommend',
    TOLL: 'carToll',
    FREE: 'carFree',
  }),
  // 自転車選択タイプ
  BICYCLE_TYPE: Object.freeze({
    MY_BICYCLE: 1, // eslint-disable-line no-magic-numbers
    SHARE_BIKE: 2, // eslint-disable-line no-magic-numbers
  }),

  // 色
  COLOR: Object.freeze({
    GENERAL_MOBILITY: '#979CA1',
    WALK: '#009CE5',
  }),
  // 出発・到着・始発・終電IDリスト
  TIME_TYPE_ID: Object.freeze({
    START: 1, // 出発
    GOAL: 2, // 到着
    FIRST: 3, // 始発
    LAST: 4, // 終電
  }),

  // 出発・到着・始発・終電選択リスト
  TIME_TYPE_LIST: Object.freeze([
    Object.freeze({id: 1, name: '出発'}),
    Object.freeze({id: 2, name: '到着'}),
    Object.freeze({id: 3, name: '始発'}),
    Object.freeze({id: 4, name: '終電'}),
  ]),

  // 詳細設定
  SELECT_MOBILITY: Object.freeze({
    isSuperexpressTrain: '新幹線',
    isUltraexpressTrain: '有料特急',
    isHighwayBus: '高速バス',
    isDomesticFlight: '飛行機',
    isLocalBus: '路線/連絡バス',
    isFerry: 'フェリー',
  }),

  // 駅種別
  STATION_TYPE: Object.freeze({
    STATION: 'station',
    AIRPORT: 'airport',
    PORT: 'port',
    BUSSTOP: 'busstop',
    SHUTTLE_BUSSTOP: 'shuttle_busstop',
    HIGHWAY_BUSSTOP: 'highway_busstop',
  }),

  // 地点選択履歴の最大件数
  MAX_HISTORY_NUM: 10,
  // 時刻表お気に入り最大件数
  MAX_FAVORITE_TIME_TABLE: 10,

  // 表記方法
  NOTATION_TYPE: Object.freeze({
    // スラッシュ
    SLASH: 'slash',
  }),

  // 手配リスト
  ARRANGEMENT_LIST: Object.freeze({
    TAXI: Object.freeze({
      id: 1,
      name: 'タクシーを手配',
      icon: 'IconArrangementGo.png',
    }),
    BICYCLE: Object.freeze({
      id: 2,
      name: '自転車を手配',
      icon: 'IconArrangementBikeshare.png',
    }),
    CAR_SHARE: Object.freeze({
      id: 3,
      name: 'カーシェアを手配',
      icon: 'IconArrangementTimes.png',
    }),
  }),

  // バイクシェア通知ステータス
  BICYCLE_STATUS_CODE: Object.freeze({
    RESERVING: 'reserving', // 予約処理中
    DEFAULT_STATUS: '0', // デフォルトの初期状態
    COMPLETE_RESERVATION: '100', // 予約中
    CANCEL_RESERVATION: '200', // キャンセル
    START_USING: '300', // 利用開始
    COMPLETE_RETURN: '500', // 返却
    COMPLETE_RETURN_AUTOMATICALLY: '520', // 自動返却
    COMPLETE_RETURN_WITHOUT_FREE: '510', // 返却(料金なし)
  }),

  // GOタクシーの配車ステータス
  TAXI_RESERVATION_STATUS: Object.freeze({
    RESERVING: 'reserving', // 予約処理中
    FINDING: 'finding', // タクシーを探している
    DISPATCHING: 'dispatching', // タクシーが乗車場所に向かっている
    DISPATCH_FAILED: 'dispatch_failed', // タクシーが見つからなかった
    PICK_UP_ARRIVED: 'pick_up_arrived', // タクシーが乗車場所に到着している
    ON_DELIVER: 'on_deliver', // 乗車済み
    DROP_OFF: 'drop_off', // 降車済み（決済金額未確定）
    SUCCEED: 'succeed', // 目的地到着
    CANCELED: 'canceled', // キャンセル完了
  }),

  // GOタクシーのメッセージ送信者
  TAXI_MESSAGE_SENDER: Object.freeze({
    USER: 0, // 利用者
    DRIVER: 1, // 乗務員
  }),

  // 自転車情報予約APIリザルトコード
  BICYCLE_RESERVATION_API_RESULT_CODE: Object.freeze({
    SUCCESS: 200, // キャンセル成功
  }),

  // 自転車解錠APIリザルトコード
  BICYCLE_UNLOCK_API_RESULT_CODE: Object.freeze({
    SUCCESS: 200, // 解錠成功
  }),

  // 性別種別
  GENDER: Object.freeze({
    MAN: Object.freeze({key: '0', label: '男性'}),
    WOMAN: Object.freeze({key: '1', label: '女性'}),
    NO_SELECT: Object.freeze({key: '2', label: '未選択'}),
  }),

  // 利用中のモビリティ種別
  USING_MOBILITY_TYPE: Object.freeze({
    BICYCLE: 'bicycle', // ドコモバイクシェア
    TAXI: 'taxi', // GOタクシー
    NONE: 'none', // 無し（利用中のモビリティ無し）
  }),

  /**
   * ミチシルDB
   */
  // データベース名
  MICHISIRU_DATABASE_NAME: 'MICHISIRU',
  // テーブル名 ユーザ
  USER_TABLE_NAME: 'USER',
  // キー名 ユーザアイコン
  USER_ICON_KEY: 'userIconKey',
  // テーブル名 お気に入り時刻表データ
  FAVORITE_OPERATIONS_TABLE_NAME: 'FAVORITE_DIAGRAM_OPERATIONS',
  // キー名 時刻表データ
  OPERATIONS_KEY: 'operationsKey',
  // テーブル名 store
  STORE_TABLE_NAME: 'STORE',
  // キー名 store
  STORE_KEY: 'storeKey',
  // テーブル名 キャッシュ
  CACHE_TABLE_NAME: 'CACHE',
  // キー名 キャッシュ
  CACHE_KEY: 'cacheKey',

  /**
   * Auth0
   */
  // メール未承認エラーメッセージ
  AUTH0_NO_EMAIL_VERIFIED_ERROR: 'Please verify your email before logging in.',
  // 未ログインエラーメッセージ
  AUTH0_LOGIN_REQUIRED_ERROR: 'Login required',
  // getAccessToken時にネットワークエラー
  AUTH0_FAILED_TO_FETCH: 'Failed to fetch',

  /**
   * ユーザー取得API
   */
  // 登録ユーザーなしエラーメッセージ
  USER_ACCOUNT_IS_NOT_REGISTERED: 'User account is not registered',

  /**
   * 事故請求画面
   */
  // 登録したものの種類
  CLAIM_TYPE: Object.freeze({
    NAMO: 'namo',
    RECEIPT: 'receipt',
  }),
  // 交通機関
  TRANSPORTATION_LIST: Object.freeze({
    TRAIN: Object.freeze({
      id: 1,
      label: '電車',
    }),
    EXPRESS: Object.freeze({
      id: 2,
      label: '特急電車・新幹線',
    }),
    BUS: Object.freeze({
      id: 3,
      label: 'バス',
    }),
    TAXI: Object.freeze({
      id: 4,
      label: 'タクシー',
    }),
    RENTAL_CAR: Object.freeze({
      id: 5,
      label: 'レンタカー',
    }),
    CAR_SHARING: Object.freeze({
      id: 6,
      label: 'カーシェア',
    }),
    SHARED_BICYCLE: Object.freeze({
      id: 7,
      label: '自転車（シェアバイク）',
    }),
    AIRPLANE: Object.freeze({
      id: 8,
      label: '飛行機',
    }),
    OTHER: Object.freeze({
      id: 9,
      label: 'その他',
    }),
  }),
  // 記名被保険者（お車の所有者）と利用者の関係
  RELATIONSHIP_INSURED: Object.freeze({
    SELF: Object.freeze({key: '0', label: '本人'}),
    FAMILY: Object.freeze({key: '1', label: '同居のご家族'}),
  }),
  // ステータス
  INCIDENT_INFO_STATUS: Object.freeze({
    REGISTERED: 'registered', // 登録済み未請求
    COMPLETED: 'completed', // 請求済み
  }),
  // 請求状態ステータス
  CLAIM_STATUS: Object.freeze({
    REGISTERED: 'registered', // 登録済み未請求
    WAITING: 'waiting', // 請求待ち
    COMPLETED: 'completed', // 請求済み
  }),
  // ウイルススキャンステータス
  VIRUS_SCAN_STATUS: Object.freeze({
    NOT_SCAN_TARGET: '', // スキャン対象外
    UNSCANNED: 'unScanned', // 未スキャン
    SCANNED: 'scanned', // ウイルススキャンOK
    REJECTED: 'rejected', // ウイルススキャンNG
  }),
  // 事故請求ログインエラーメッセージ
  INSURANCE_CLAIM_LOGIN_ERROR_MESSAGE: Object.freeze({
    NOT_FOUND_CLAIM: 'Not Found Claim', // 該当の受付番号が見つからないエラー
  }),
  // IC優先表示
  IC_PRIORITY_DISPLAY: '(IC優先)',
  // ユーザーの決済ステータス
  USER_PAYMENT_STATUS: Object.freeze({
    DEFAULT: '', // 未決済のものがない場合
    UNCOMPLETED: 'uncompleted', // 決済未完了の場合
    PAYMENT_NG: 'paymentNG', // 決済失敗の場合
    SUSPEND: 'suspend', // 管理者画面からの抑止ステータス
  }),
  // 乗物保険バナー
  MOBILITY_BANNER: Object.freeze({
    CAR: Object.freeze({
      ICON_PATH: 'banner_tyoinori_car_insurance.png',
    }),
    BICYCLE: Object.freeze({
      ICON_PATH: 'banner_bicycle_insurance.png',
    }),
  }),

  /**
   * 天気コード
   * ※変数名の記載は「時々：ANY、のち：TO、又：OR」で定義
   * TODO: 重複している意味合いのコードはどう扱うか
   */
  WEATHER_CODE: Object.freeze({
    // 晴れ
    SUNNY: '10', // '晴れ'
    SUNNY_ANY_CLOUDY: '12', // 晴れ時々曇り
    SUNNY_ANY_RAINY: '13', // 晴れ時々雨
    SUNNY_ANY_RAINY_OR_SNOW: '14', // 晴れ時々雨か雪
    SUNNY_ANY_SNOW: '15', // '晴れ時々雪'
    SUNNY_TO_CLOUDY: '16', // '晴れのち曇り'
    SUNNY_TO_RAINY: '17', // '晴れのち雨'
    SUNNY_TO_RAINY_OR_SNOW: '18', // '晴れのち雨か雪'
    SUNNY_TO_SNOW: '19', // 晴れのち雪
    // 曇り
    CLOUDY: '20', // 曇り
    CLOUDY_ANY_SUNNY: '21', // 曇り時々晴れ
    CLOUDY_ANY_RAINY: '23', // 曇り時々雨
    CLOUDY_ANY_RAINY_OR_SNOW: '24', // 曇り時々雨か雪
    CLOUDY_ANY_SNOW: '25', // 曇り時々雪
    CLOUDY_TO_SUNNY: '26', // 曇りのち晴れ
    CLOUDY_TO_RAINY: '27', // 曇りのち雨
    CLOUDY_TO_RAINY_OR_SNOW: '28', // 曇りのち雨か雪
    CLOUDY_TO_SNOW: '29', // 曇りのち雪
    // 雨
    RAINY: '30', // 雨
    RAINY_ANY_SUNNY: '31', // 雨時々晴れ
    RAINY_ANY_CLOUDY: '32', // 雨時々曇り
    RAINY_ANY_SNOW: '34', // 雨時々雪
    RAINY_ANY_SNOW2: '35', // 重複 雨時々雪
    RAINY_TO_SUNNY: '36', // 雨のち晴れ
    RAINY_TO_CLOUDY: '37', // 雨のち曇り
    RAINY_TO_SNOW: '38', // 雨のち雪
    RAINY_TO_SNOW2: '39', // 重複 雨のち雪
    // 雨又雪
    RAINY_OR_SNOW: '40', // 雨又雪
    RAINY_OR_SNOW_ANY_SUNNY: '41', // 雨又雪時々晴れ
    RAINY_OR_SNOW_ANY_CLOUDY: '42', // 雨又雪時々曇り
    RAINY_OR_SNOW2: '43', // 重複 雨又雪
    SNOW_OR_RAINY: '45', // 雪又雨
    RAINY_OR_SNOW_TO_SUNNY: '46', // 雨又雪のち晴れ
    RAINY_OR_SNOW_TO_CLOUDY: '47', // 雨又雪のち曇り
    RAINY_OR_SNOW3: '48', // 重複 雨又雪
    SNOW_OR_RAINY2: '49', // 重複 雪又雨
    // 雪
    SNOW: '50', // 雪
    SNOW_ANY_SUNNY: '51', // 雪時々晴れ
    SNOW_ANY_CLOUDY: '52', // 雪時々曇り
    SNOW_ANY_RAINY: '53', // 雪時々雨
    SNOW_ANY_RAINY2: '54', // 重複 雪時々雨
    SNOW_TO_SUNNY: '56', // 雪のち晴れ
    SNOW_TO_CLOUDY: '57', // 雪のち曇り
    SNOW_TO_RAINY: '58', // 雪のち雨
    SNOW_TO_RAINY2: '59', // 重複 雪のち雨
    // 不明
    NOT_FOUND: '255', // 不明
  }),
  /**
   * カスタムイベントハンドラー
   */
  CUSTOM_EVENT_HANDLER: Object.freeze({
    STOP_MAP: 'stop-map',
    MOVING_MAP: 'moving-map',
    LONG_PRESS_MAP: 'long-press-map',
  }),
  // コピーライト下の余白
  COPYRIGHT_UNDER_SPACE: Object.freeze({
    FOOTER_SHOW: 4, // フッターあり
    FOOTER_HIDE: 8, // フッターなし
  }),
})
