<template>
  <!-- ルート選択画面（自転車） -->
  <div class="w-full fixed bottom-0 flex flex-col !pointer-events-none">
    <div
      class="circle-back-button mt-safe-area flex justify-center items-center pointer-events-auto"
      @click="goToBackPage()"
    >
      <img class="h-6 w-6" src="@/assets/Icon_Left_grayV2.svg" />
    </div>
    <BasePanel
      class="pointer-events-auto pb-[52px]"
      calcPanelHeightMode="panel"
      :isShowFooter="true"
    >
      <!-- header要素からの相対座標でバナーを表示 -->
      <template v-slot:header>
        <div class="relative w-full">
          <BaseBanner
            class="!pointer-events-none absolute w-full top-[-130px]"
            v-show="isShowBanner"
            :url="bannerInfo.url"
            :pictureSrc="bannerInfo.src"
            position="right"
            @clickClose="closeBanner()"
          />
        </div>
      </template>
      <template v-slot:body>
        <div class="mx-5 mb-5">
          <!-- 到着時間・リルートボタン -->
          <div class="relative flex text-W4 items-end pb-5">
            <div class="flex-1 text-left">
              <!-- 到着時間 -->
              <span class="text-W7 text-[24px]">
                {{ arrivalTime }}
              </span>
              <span class="text-W4 text-[14px]">着</span>
              <!-- 移動時間 -->
              <span class="text-W3 text-[14px] leading-[14px] ml-1">(</span>
              <span v-if="isExistHour">
                <span class="text-W6 text-[15px] leading-[15px]">
                  {{ timeHour }}
                </span>
                <span class="text-W4 text-[15px]">時間</span>
              </span>
              <span class="text-W6 text-[15px] leading-[14px] mr-0.5">
                {{ timeMinute }}
              </span>
              <span class="text-W4 text-[15px]">分</span>
              <span class="text-W3 text-[15px]">)</span>
              <span class="px-1 text-W5 text-[16px] leading-[17px]">/</span>
              <!-- 距離 -->
              <span class="text-W6 text-[16px] leading-[16px]">
                {{ roundAndConvert }}
              </span>
              <span class="text-W5 text-[16px] leading-[16px]">
                {{ getDistanceUnit }}
              </span>
            </div>
            <!-- 再検索アイコン -->
            <img
              class="absolute right-0 h-6 w-6"
              src="@/assets/NaviReRouteIcon.svg"
              @click="reRoute()"
            />
          </div>
          <!-- 経路表示・カロリー -->
          <div class="flex">
            <!-- 左側(経路情報) -->
            <div class="flex-1">
              <!-- 経路表示 -->
              <div class="mr-5">
                <RouteSearchSpots
                  :start="forResearchConditions.start.name"
                  :via="forResearchConditions.via"
                  :goal="forResearchConditions.goal.name"
                  :weather="weatherInfo"
                />
              </div>
            </div>
            <!-- 右側(カロリー) -->
            <div class="w-[96px] border-l-[1px] border-gray300 pl-4">
              <!-- カロリー -->
              <div>
                <div class="flex">
                  <img class="h-4 w-4 mr-0.5" src="@/assets/Icon_Calorie.svg" />
                  <div
                    class="text-W5 text-[12px] text-gray600 leading-[12px] my-auto"
                  >
                    カロリー
                  </div>
                </div>
                <div
                  class="text-left text-W4 text-[11px] leading-[11px] mt-1.5"
                >
                  {{
                    addCommaForInteger(routeSearchBicycleResult.totalCalories)
                  }}kcal
                </div>
              </div>
            </div>
          </div>
          <!-- 未決済文言 -->
          <div class="h-8 flex items-end">
            <div
              v-if="!isReservedOrUsingOfMobility && isPaymentFailing"
              class="text-left text-[9px] text-W4 text-danger300"
            >
              未決済のご利用があります
            </div>
          </div>
          <!-- ボタン -->
          <div class="flex">
            <TheButton
              id="reserve-taxi-button"
              buttonType="secondary"
              :text="cycleReservationButtonText"
              :isDisabled="isReservedOrUsingOfMobility || isPaymentFailing"
              class="w-2/3 mr-2 text-[12px] leading-[13px] py-2 px-3"
              @click-button="clickBicycleButton()"
            />
            <TheButton
              buttonType="primary"
              text="ナビ"
              class="w-1/3 text-[12px] leading-[13px] py-2 px-3"
              @click-button="onClickNaviStart()"
            />
          </div>
        </div>
      </template>
    </BasePanel>
    <FooterMargin />
  </div>
</template>
<script>
import Util from '@/mixins/util'
import TheButton from '@/components/atoms/TheButton.vue'
import BaseBanner from '@/components/atoms/BaseBanner.vue'
import BasePanel from '@/components/atoms/BasePanel.vue'
import RouteSearchSpots from '@/components/molecules/searchRoute/RouteSearchSpots.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import * as mobilityUtil from '@/utils/mobilityUtil'
import NativeUtil from '@/mixins/nativeUtil'

const RouteByBicycle = {
  name: 'RouteByBicycle',
  components: {
    TheButton,
    BaseBanner,
    BasePanel,
    RouteSearchSpots,
    FooterMargin,
  },
  mixins: [Util],
  data() {
    return {
      gotoOptimalFlg: false, // 最適ルート画面に遷移することを検知するフラグ
    }
  },
  emits: [],
  created() {
    //  ルートを描画する
    this.drawShapeRoute()
  },
  mounted() {
    // 天気取得実行&アプリ表示時のイベントリスナーに追加
    NativeUtil.methods.addResumeEvent(this.fetchSpotWeather)
    this.fetchSpotWeather()
  },
  unmounted() {
    NativeUtil.methods.removeResumeEvent(this.fetchSpotWeather)
    if (this.gotoOptimalFlg) {
      // 最適ルート画面に遷移する場合
      // 再検索用検索条件を初期化
      this.$store.commit('RouteStore/updateForResearchConditions', {
        mode: 'init',
      })
      // 再検索用検索結果を初期化
      this.$store.commit('RouteStore/updateForResearchResult', {
        mode: 'init',
      })
      // アイコンをクリア
      this.$root.$refs.routeMap.clearSearchSpotMarkers()
    }
  },
  computed: {
    /**
     * 再検索用の検索条件
     */
    forResearchConditions() {
      return this.$store.state.RouteStore.forResearchConditions
    },
    /**
     * 再検索用検索結果の取得
     */
    routeSearchBicycleResult() {
      return this.$store.state.RouteStore.forResearchResult.bicycle[
        this.$config.ZERO
      ]
    },
    /**
     * 到着時刻(HH:mm)を算出
     */
    arrivalTime() {
      // 一番最後のtoTime保持セクションを検索
      const arrivalTimeSection =
        this.routeSearchBicycleResult.sections.findLast(
          (section) => section.toTime
        )
      return this.getFormatTime(arrivalTimeSection.toTime)
    },
    /**
     * 移動時間(h)の値を算出
     */
    timeHour() {
      const summaryTime = this.routeSearchBicycleResult.summaryTime
      const hour = 60
      return Math.floor(summaryTime / hour)
    },
    /**
     * 移動時間(m)の値を算出
     */
    timeMinute() {
      const summaryTime = this.routeSearchBicycleResult.summaryTime
      const hour = 60
      return summaryTime % hour
    },
    /**
     * 時間の単位が存在するかどうか
     */
    isExistHour() {
      return 0 < this.timeHour // eslint-disable-line no-magic-numbers
    },
    /**
     * 移動距離に応じて表示する単位を切り替える
     */
    getDistanceUnit() {
      return this.isKilo(this.routeSearchBicycleResult.totalDistance)
        ? 'km'
        : 'm'
    },
    /**
     * 移動距離に応じて距離の単位を切り替える
     */
    roundAndConvert() {
      const distance = this.routeSearchBicycleResult.totalDistance
      return this.isKilo(distance)
        ? // 1km以上の場合、km単位に変換する
          this.roundAndConvertToKilo(distance, 1) // eslint-disable-line no-magic-numbers
        : // 1km未満の場合、m単位のまま返却
          distance
    },
    /**
     * 利用中のモビリティ種別
     */
    usingMobilityType() {
      return this.$store.getters[
        'MobilityReservationStore/reservedOrUsingOfMobilityType'
      ]
    },
    /**
     * 「予約中」または「利用中」のモビリティがあるかどうか
     */
    isReservedOrUsingOfMobility() {
      return this.usingMobilityType !== this.$config.USING_MOBILITY_TYPE.NONE
    },
    /**
     * 決済が失敗状態であるかどうか
     * @return 決済失敗有無
     */
    isPaymentFailing() {
      return mobilityUtil.determiningPaymentFailing(
        this.$store.state.CreditCardsStore.paymentStatus
      )
    },
    /**
     * モビリティ手配ボタンのテキスト
     */
    cycleReservationButtonText() {
      switch (this.usingMobilityType) {
        case this.$config.USING_MOBILITY_TYPE.BICYCLE:
          return '自転車利用中'
        case this.$config.USING_MOBILITY_TYPE.TAXI:
          return 'タクシー利用中'
        case this.$config.USING_MOBILITY_TYPE.NONE:
          return '自転車を手配'
        default:
          return '自転車を手配'
      }
    },
    /**
     * 検索結果によって配置するアイコン
     */
    searchSpotsGlMarkers() {
      return this.$store.state.searchSpotsGlMarkers
    },
    /**
     * 表示するバナー情報
     * @returns ジャンプ先、画像パス
     */
    bannerInfo() {
      // TODO: 目的地のバナー分岐はここを拡張していきたい
      return {
        url: process.env.VUE_APP_TMNF_BICYCLE_INSURANCE_URL,
        src: this.$config.MOBILITY_BANNER.BICYCLE.ICON_PATH,
      }
    },
    /**
     * バナー表示有無
     */
    isShowBanner() {
      return this.$store.state.RouteStore.displayBanner.bicycle
    },
    /**
     * 天気情報
     */
    weatherInfo() {
      return this.$store.state.RouteStore.weather
    },
  },
  methods: {
    /**
     * 出発地・目的地の天気情報を取得し更新する
     */
    async fetchSpotWeather() {
      // 「地点.coord = 緯度,経度」の構成で格納されているため、緯度経度を抽出する
      const getCoord = (spot) => {
        const coord = spot.coord.split(',')
        return {lat: coord[0], lon: coord[1]} // eslint-disable-line no-magic-numbers
      }
      const startSpotCoord = getCoord(this.forResearchConditions.start)
      const goalSpotCoord = getCoord(this.forResearchConditions.goal)
      // 出発地と目的地の天気取得&更新
      const getStartWeather =
        this.createAsynchronousProcessForGettingWeather(startSpotCoord)
      const getGoalWeather =
        this.createAsynchronousProcessForGettingWeather(goalSpotCoord)

      // 全て取得が完了したら更新
      return Promise.allSettled([getStartWeather, getGoalWeather]).then(
        ([startWeather, goalWeather]) => {
          // 成功判定
          const isSuccess = (result) => result.status == 'fulfilled'

          // 更新処理
          this.$store.commit('RouteStore/updateWeather', {
            target: 'start',
            daily: isSuccess(startWeather) ? startWeather.value.daily : [],
            weekly: isSuccess(startWeather) ? startWeather.value.weekly : [],
          })
          this.$store.commit('RouteStore/updateWeather', {
            target: 'goal',
            daily: isSuccess(goalWeather) ? goalWeather.value.daily : [],
            weekly: isSuccess(goalWeather) ? goalWeather.value.weekly : [],
          })
        }
      )
    },
    /**
     * 天気取得の非同期処理作成
     * @param {Object} coord 座標
     * @param {String} coord.lat 緯度
     * @param {String} coord.lon 経度
     * @returns {Promise} 天気情報
     */
    createAsynchronousProcessForGettingWeather(coord) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('RouteStore/getWeather', {
          success: (result) => resolve(result),
          failed: () => reject(),
          error: (e) => reject(e),
          lat: coord.lat,
          lon: coord.lon,
        })
      })
    },
    /**
     * リルート処理
     */
    reRoute() {
      const vm = this
      this.$store.commit('startLoading')

      const success = (newCondition, newResult, newRoute) => {
        // 検索条件の更新
        vm.$store.commit('RouteStore/updateForResearchConditions', {
          value: newCondition,
        })

        // 検索結果の更新
        vm.$store.commit('RouteStore/updateForResearchResult', {
          key: 'bicycle',
          value: newResult,
        })

        // 検索ルートの更新
        vm.$store.commit('updateDrawRouteScript', newRoute)

        // 天気更新
        this.fetchSpotWeather()

        vm.$store.commit('endLoading')
      }

      // リルート実行
      this.$store.dispatch('RouteStore/reRoute', {
        success: success,
        routeType: 'bicycle',
      })
    },
    /**
     * 前の画面に遷移
     */
    goToBackPage() {
      // 最適画面に戻る際はローディングを表示させないようにする
      this.$store.commit('updateIsNoLoadingWhenDrawRoute', true)
      // ルート形状を初期化
      this.$store.commit('clearDrawRouteScript')
      // 天気初期化処理
      this.$store.commit('RouteStore/initWeather')

      // 最適ルート画面への遷移フラグを有効にする
      this.gotoOptimalFlg = true

      // 最適ルート画面に遷移
      this.$router.push({name: this.$config.DISPLAY_ROUTE_BY_OPTIMAL})
    },
    /**
     * ナビ開始ボタン押下時処理
     */
    onClickNaviStart() {
      // storeにナビ情報退避
      const routeNaviInfo = {
        routeType: this.$config.MOBILITY_CARD_TYPE_BICYCLE,
        selectedRouteInfo: this.routeSearchBicycleResult,
      }
      this.$store.commit('RouteStore/updateRouteNaviInfo', routeNaviInfo)

      // ナビ開始位置にフォーカスを移す
      this.focusStartCoord()

      // ナビ画面に遷移
      this.$router.push({name: this.$config.DISPLAY_ROUTE_NAVI})
    },
    /**
     * 出発地の座標に画面をフォーカスする
     */
    focusStartCoord() {
      const start = this.$store.getters['RouteStore/routeNaviStartLatLon']
      this.$root.$refs.routeMap.callFitWithLatLon(start.lat, start.lon)
    },
    /**
     * ルートを取得して、描画する
     */
    drawShapeRoute() {
      const ROUTE_TYPE_BICYCLE = 'bicycle'

      if (!this.routeSearchBicycleResult) {
        // ルート検索結果がない場合はルート情報を削除しておく
        this.$store.commit('clearDrawRouteScript')
        return
      }
      if (this.$store.state.routeType == ROUTE_TYPE_BICYCLE) {
        // eslint-disable-next-line no-magic-numbers
        if (this.searchSpotsGlMarkers.length !== 0) {
          // 表示済みのため何もせず終了する
          return
        }
      }

      // 既に取得済みの場合は、保持領域から該当ルートを取得して反映
      const bicycleRoute = this.$store.state.drawRouteData.bicycle
      if (bicycleRoute != null) {
        this.$store.commit('updateRouteType', ROUTE_TYPE_BICYCLE)
        this.$store.commit('updateDrawRouteScript', bicycleRoute)
        return
      }

      // ローディング開始
      this.$store.commit('startLoading')

      // ルート検索時のパラメータを取得
      const routeSearchParam =
        this.$store.state.RouteStore.routeSearchParam.bicycle
      // ルートタイプを自転車に指定
      routeSearchParam.routeType = ROUTE_TYPE_BICYCLE

      // 終了時処理
      const vm = this
      const success = () => {
        vm.$store.commit('updateRouteType', ROUTE_TYPE_BICYCLE)
        vm.$store.commit('endLoading')
      }

      const failed = () => {
        vm.$store.commit('updateRouteType', ROUTE_TYPE_BICYCLE)
        vm.$store.commit('endLoading')
        throw new Error()
      }

      const error = (e) => {
        vm.$store.commit('updateRouteType', ROUTE_TYPE_BICYCLE)
        vm.$store.commit('endLoading')
        throw e
      }

      // ルート描画
      this.$store.dispatch('shapeRoute', {
        success: success,
        failed: failed,
        error: error,
        target: routeSearchParam,
      })
    },
    /**
     * 自転車を手配ボタン押下処理
     */
    clickBicycleButton() {
      this.storeCurrentTabInfomation()
      // 遷移元のパスをstoreに登録
      this.$store.commit(
        'MobilityReservationStore/updateScreenTransitionSource',
        {
          displayName: this.$config.DISPLAY_ROUTE_BY_BICYCLE,
          footerId: this.$store.state.selectedFooterTab,
        }
      )
      // フッタータブ情報を切り替え
      this.$store.commit('updateFooterTab', this.$config.FOOTER_ID_ARRANGEMENT)
      // 画面遷移
      this.$router.push({name: this.$config.DISPLAY_BICYCLE_RESERVATION_TOP})
    },
    /**
     * 現在のタブの表示ページ情報を保持する
     */
    storeCurrentTabInfomation() {
      const footerName = this.$config.FOOTER_LIST.find((footer) => {
        return footer.id == this.$store.state.selectedFooterTab
      }).name
      let page = {key: footerName, value: this.$route.name}
      this.$store.commit('updateCurrentPage', page)
    },
    /**
     * バナーの表示状態を非表示にする
     */
    closeBanner() {
      this.$store.commit('RouteStore/updateDisplayBanner', {
        target: this.$config.MOBILITY.BICYCLE,
        isShow: false,
      })
    },
  },
}
export default RouteByBicycle
</script>
<style scoped></style>
