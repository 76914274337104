<!-- 地点検索TOPコンポーネント -->
<template>
  <div class="!pointer-events-none">
    <!-- 未決済 注意文言 -->
    <div
      v-if="!isReservedOrUsingOfMobility && isPaymentFailing"
      class="flex w-full mt-2 border-danger300 pointer-events-auto"
      id="search-top-payment-message"
    >
      <div
        class="flex items-center w-full bg-danger100 mx-5 p-3 border-[1px] border-solid border-danger300 rounded-[8px]"
        @click="onClickPaymentCaution()"
      >
        <img src="@/assets/Icon_Caution.svg" class="h-4 w-4 mr-2" />
        <div class="text-W4 text-[13px] leading-[13px] text-danger300 mr-auto">
          未決済のご利用があります。
        </div>
        <img src="@/assets/Icon_Right_gray.svg" class="h-3 w-3 self-center" />
      </div>
    </div>
    <!-- バナー -->
    <BaseBanner
      v-show="isShowBanner"
      class="mt-4"
      :url="bannerInfo.url"
      :pictureSrc="bannerInfo.src"
      position="left"
      @clickClose="closeBanner()"
    />
  </div>
  <!-- 検索フォーム -->
  <div
    ref="panelContents"
    class="absolute w-full bottom-0 !pointer-events-none"
  >
    <BaseCard
      class="mx-2 pointer-events-auto"
      :style="{'margin-bottom': searchMarginBottomHeight}"
      roundedValue="[8px]"
    >
      <div class="px-1.5 py-[6.5px] flex">
        <!--
          下記input要素をタップすることで、Suggestコンポーネントの入力フォームにフォーカスする
          ※Mobile Safariではinput要素に対する操作でなければ入力フォームにフォーカスさせることができない
        -->
        <img src="@/assets/Icon_Route_Glasses_black.svg" class="w-4 h-4" />
        <div class="flex-1 relative">
          <div class="absolute w-full left-0 h-[15px] pl-2 text-left">
            <div class="text-W4 text-[15px] leading-[15px] text-gray600">
              行きたい場所を入力
            </div>
          </div>
          <input
            type="text"
            class="absolute w-full left-0 opacity-0"
            readonly
            @click="isShowSuggest = true"
          />
        </div>
      </div>
    </BaseCard>
    <Suggest
      :panelSpaceHeight="panelSpaceHeight"
      :isShowSuggest="isShowSuggest"
      :targetSpot="$config.SPOT_TYPE_GOAL"
      :isShowIconSpecifyOnTheMap="true"
      @click-back-button="isShowSuggest = false"
      @route-search-select-spot="gotoRouteByOptimal($event)"
    ></Suggest>
  </div>
</template>
<script>
/**
 * 地点検索TOPコンポーネント
 * ＜概要＞
 * ・TOP画面のパネル部に最初に表示するコンポーネント
 * ・経由地の有無や数によって表示内容や高さが動的に変化する
 */
import Util from '@/mixins/util'
import BaseCard from '@/components/atoms/BaseCard.vue'
import Suggest from '@/components/Suggest.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import * as mobilityUtil from '@/utils/mobilityUtil'
import BaseBanner from '@/components/atoms/BaseBanner.vue'

const SearchTop = {
  name: 'SearchTop',
  components: {BaseCard, Suggest, FooterMargin, BaseBanner},
  mixins: [Util],
  data() {
    return {
      isShowSuggest: this.$store.state.isShowSuggestFlg, // 地点検索表示フラグ
      isShowBanner: this.$store.state.isShowInsuranceServiceBanner, // バナー表示フラグ
    }
  },
  created() {
    // 地点検索コンポーネントの表示フラグを初期化
    this.$store.commit('updateIsShowSuggestFlg', false)

    //ルート検索条件/結果をクリアする
    this.$store.commit('RouteStore/resetConditionAndResult')
    // 検索条件の初期化
    this.$store.commit('initializeSearchConditions')
    // 保持していたルート描画データを初期化
    this.$store.commit('clearDrawRouteData')

    // 初回のルート検索実施フラグの初期化
    this.$store.commit('updateSearchRoute', false)
    this.$store.commit('RouteStore/updateIsCompleteSearchRoute', false)

    // マップ長押しのイベントハンドラー
    window.addEventListener(
      this.$config.CUSTOM_EVENT_HANDLER.LONG_PRESS_MAP,
      this.onMapLongPress
    )
  },
  mounted() {
    this.initRouteMap()
  },
  beforeUnmount() {
    // マップ操作関連のイベントリスナー削除
    window.removeEventListener(
      this.$config.CUSTOM_EVENT_HANDLER.LONG_PRESS_MAP,
      this.onMapLongPress
    )
  },
  computed: {
    /**
     * 利用中のモビリティ種別
     */
    usingMobilityType() {
      return this.$store.getters[
        'MobilityReservationStore/reservedOrUsingOfMobilityType'
      ]
    },
    /**
     * 「予約中」または「利用中」のモビリティがあるかどうか
     */
    isReservedOrUsingOfMobility() {
      return this.usingMobilityType !== this.$config.USING_MOBILITY_TYPE.NONE
    },
    /**
     * 決済が失敗状態であるかどうか
     * @return 決済失敗有無
     */
    isPaymentFailing() {
      return mobilityUtil.determiningPaymentFailing(
        this.$store.state.CreditCardsStore.paymentStatus
      )
    },
    /**
     * パネルの上部スペース
     */
    panelSpaceHeight() {
      const baseSpaceHeight = 44
      return baseSpaceHeight + this.$store.state.topSafeAreaHeight
    },
    /**
     * 地点検索コンポーネント下部マージンの高さ
     */
    searchMarginBottomHeight() {
      const baseMarginBottom = 31
      return `${baseMarginBottom + this.$config.FOOTER_HEIGHT}px`
    },
    /**
     * 表示するバナー情報
     * @returns ジャンプ先、画像パス
     */
    bannerInfo() {
      return {
        url: process.env.VUE_APP_NAMO_INSURANCE_SERVICE_URL,
        src: 'banner_insurance.png',
      }
    },
  },
  methods: {
    /**
     * ルートマップの初期化処理
     */
    initRouteMap() {
      // マップ生成前の場合は何もしない
      if (!this.$root.$refs.routeMap?.$data?.frozen.mapRelated.map) return

      // 検索した地点のアイコンを親側で削除
      this.$root.$refs.routeMap.clearSearchSpotMarkers()

      // 前画面からモードを引き継ぐか
      if (!this.$route.params?.isKeepMapMode) {
        this.$root.$refs.routeMap.changeNorthUpMode(true)
      }
    },
    /**
     * ルート検索の設定を行い、最適画面に遷移する
     * @param {Object} spot 地点情報
     */
    gotoRouteByOptimal(spot) {
      // 検索条件の初期化
      this.$store.commit('initializeSearchConditions')

      // 到着地点設定処理
      this.$store.commit('updateSearchConditions', {
        key: this.$config.SPOT_TYPE_GOAL,
        value: spot,
      })
      // 最適画面に遷移
      this.$router.push({name: 'RouteByOptimal'})
    },
    /**
     * 保険サービスのバナーを閉じる
     */
    closeBanner() {
      this.isShowBanner = false
      this.$store.commit('updateIsShowInsuranceServiceBanner', false)
    },
    /**
     * 決算警告メッセージクリック処理
     */
    onClickPaymentCaution() {
      // 現在のタブの表示ページ情報を保持しておく
      this.storeCurrentTabInformation()
      // 乗り物手配Top画面へ遷移させる
      this.$router.push({name: this.$config.DISPLAY_ARRANGEMENT_TOP})
      // ストアのタブIDを更新
      this.$store.commit('updateFooterTab', this.$config.FOOTER_ID_ARRANGEMENT)
      // ストレージのタブIDを更新
      this.setLocalStorage(
        this.$config.FOOTER_TAB,
        this.$config.FOOTER_ID_ARRANGEMENT
      )
    },

    /**
     * 現在のタブの表示ページ情報を保持する
     */
    storeCurrentTabInformation() {
      const footerName = this.$config.FOOTER_LIST.find((footer) => {
        return footer.id == this.$store.state.selectedFooterTab
      }).name
      let page = {key: footerName, value: this.$route.name}
      this.$store.commit('updateCurrentPage', page)
    },
    /**
     * 現在地に戻る
     */
    goBackCurrentPosition() {
      this.$root.$refs.routeMap?.currentSpotFit?.()
    },
    /**
     * マップを長押しした際に目的地設定画面へ遷移
     */
    onMapLongPress() {
      // マップ生成前の場合は何もしない
      if (!this.$root.$refs.routeMap?.$data?.frozen.mapRelated.map) return
      // マップの中心を取得
      const mapCenter =
        this.$root.$refs.routeMap?.$data?.frozen.mapRelated.map.getCenter()
      // 遷移前に中心を保持
      this.$store.commit('updateMapCenter', mapCenter)
      // 目的地設定画面へ遷移する
      this.$router.push({name: this.$config.DISPLAY_DESTINATION_SETTING})
    },
  },
}
export default SearchTop
</script>
<style scoped></style>
