<template>
  <div class="flex flex-col" :class="positionClass">
    <div class="flex bg-transparent">
      <div class="pointer-events-auto mx-2 mb-1.5">
        <img
          src="@/assets/insuranceService/close.png"
          width="16"
          height="16"
          @click="$emit('clickClose')"
        />
      </div>
    </div>
    <!-- shadowが途切れないように、下方向に十分な余白を設ける -->
    <img
      class="banner-shadow pointer-events-auto mb-6"
      :src="src"
      width="120"
      height="48"
      @click="jumpPage()"
    />
  </div>
</template>
<script>
const BaseBanner = {
  name: 'BaseBanner',
  props: {
    /**
     * 遷移先ページ（なければ遷移しない）
     */
    url: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * 画像名(「@/assets/banner/」配下にある画像名)
     */
    pictureSrc: {
      type: String,
      required: true,
    },
    /**
     * バナーの表示位置
     */
    position: {
      type: String,
      required: true,
      validator(val) {
        return ['left', 'right'].includes(val)
      },
    },
  },
  emits: ['clickClose'],
  computed: {
    /**
     * 画像パス
     */
    src: {
      get() {
        return require('@/assets/banner/' + this.pictureSrc)
      },
    },
    /**
     * バナーの位置
     */
    positionClass: {
      get() {
        switch (this.position) {
          case 'right': // 右端
            return 'items-end'
          case 'left': // 左端
          default:
            return 'items-start'
        }
      },
    },
  },
  methods: {
    /**
     * 外部ページにジャンプする
     */
    jumpPage() {
      if (!this.url) return
      window.open(this.url, '_blank', 'noreferrer')
    },
  },
}
export default BaseBanner
</script>
<style scoped>
.banner-shadow {
  filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.3));
}
</style>
