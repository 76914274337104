<template>
  <div id="RouteDetailByTrainBus" class="w-full fixed bottom-0">
    <div
      class="circle-back-button mt-safe-area flex justify-center items-center"
      @click="gotoBackpage()"
    >
      <img class="h-6 w-6" src="@/assets/Icon_Left_grayV2.svg" />
    </div>
    <ScrollPanel
      id="ScrollPanel"
      :defaultPanelMode="defaultPanelMode"
      :maxTopSpace="maxTopSpace"
      :middleTopSpace="middleTopSpace"
      :minTopSpace="minTopSpace"
      :footerBgColor="'bg-gray200'"
      :contentId="contentId"
      @updatePanelMode="updatePanelMode($event)"
      ref="scrollPanel"
    >
      <!-- ヘッダー部 -->
      <template v-slot:header>
        <div>
          <div
            id="PanelHeader"
            class="w-full route-detail-header flex justify-between text-W4 h-auto bg-white"
            ref="panelHeader"
          >
            <div class="w-full leading-[14px] text-left">
              <!-- 時間とラベル -->
              <div class="w-full pl-5 pb-1 flex items-center justify-between">
                <!-- 時間 -->
                <div class="flex items-end">
                  <div class="flex items-center">
                    <div class="text-W6 text-[22px] leading-[25px] self-center">
                      {{ getStartTime }}
                    </div>
                    <img
                      src="@/assets/Arrow_Right_Gray.svg"
                      class="h-3 w-3 mx-1"
                    />
                    <div class="text-W6 text-[22px] leading-[25px] self-center">
                      {{ getGoalTime }}
                    </div>
                  </div>
                  <div
                    class="text-W4 text-center text-[14px] leading-[16px] self-center"
                  >
                    （{{ getMoveTime }}）
                  </div>
                </div>
              </div>
              <div class="w-full flex h-auto pb-4 items-center justify-between">
                <div class="flex-1 mr-4">
                  <div
                    class="pl-5"
                    :class="isAndroidWebView() ? 'flex flex-col' : ''"
                  >
                    <div class="flex">
                      <span class="text-W4 text-[11px] text-gray400 mr-0.5">
                        合計
                      </span>
                      <span class="text-W4 text-[11px] pr-0.5 text-gray400">
                        {{ $config.IC_PRIORITY_DISPLAY }}
                      </span>
                      <!-- iPhone端末は、1行にまとめる -->
                      <span
                        v-if="!isAndroidWebView()"
                        class="text-W5 text-[13px] ml-2 mt-0.5"
                      >
                        <span class="mr-0.5">¥</span>
                        <span>
                          {{
                            addCommaForInteger(selectedRouteTotal.summaryFare)
                          }}
                        </span>
                      </span>
                    </div>
                    <!-- Android端末は、金額のみを改行 -->
                    <span v-if="isAndroidWebView()" class="text-W5 text-[13px]">
                      <span class="mr-px">¥</span>
                      <span>
                        {{ addCommaForInteger(selectedRouteTotal.summaryFare) }}
                      </span>
                    </span>
                  </div>
                </div>
                <!-- 縦線 -->
                <div v-if="isAndroidWebView()" class="w-[1px] h-8 bg-gray300" />
                <!-- Suica残高を表示 -->
                <div
                  v-if="isAndroidWebView()"
                  class="flex-1 ml-1 pr-1 mr-2"
                  @click="onClickDisplaySuicaBalance()"
                >
                  <div class="w-full">
                    <SuicaBalance
                      :balance="balance"
                      :isReadCardCompleted="isReadCardCompleted"
                      :isReadCardFailed="isReadCardFailed"
                      :isBalanceFallingShort="isBalanceFallingShort"
                      :isSuicaLinked="isSuicaLinked"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- ボディ部 -->
      <template v-slot:body>
        <div class="min-h-full bg-gray200">
          <div class="h-full">
            <div
              id="PanelContentsBody"
              class="px-5 pb-10 pt-4"
              ref="panelContentsBody"
            >
              <div
                v-for="(section, index) in sections"
                :key="index"
                class="text-left"
              >
                <!-- 目的地以外の区間表示 -->
                <div v-if="section.type === 'move'" class="flex-1 flex">
                  <!-- 出発地/経由地を含む移動の場合は縦ラインに地点アイコンを入れる -->
                  <div
                    v-if="isShowPointIcon(sections, index - 1)"
                    class="section-border mr-[7px] mt-[19px]"
                  >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      class="relative ml-[7px]"
                      :id="setPointId(index)"
                    >
                      <g fill="#fff" stroke="#009ce5" stroke-width="3">
                        <circle cx="5" cy="5" r="5" stroke="none" />
                        <circle cx="5" cy="5" r="3.5" fill="none" />
                      </g>
                    </svg>
                  </div>
                  <!-- 乗換不要の移動の場合は縦ラインに斜線を入れる -->
                  <div
                    v-else-if="isNoTransferRequired(sections, index)"
                    :id="setPointId(index)"
                    class="section-border mt-[11px]"
                  >
                    <div class="diagonalLine" />
                  </div>
                  <!-- 上記以外の移動の場合には縦ラインに乗物アイコンを入れる -->
                  <div
                    v-else-if="isShowMoveIcon(sections, index)"
                    class="section-border mt-[11px]"
                  >
                    <!-- モビリティアイコン -->
                    <img
                      class="h-6 w-6 relative"
                      :src="getRouteMobilityIcon(section, sections[index - 2])"
                      :id="setPointId(index)"
                    />
                  </div>
                  <div v-else class="w-[17px]" />

                  <div class="flex-1 pl-3">
                    <RouteDetailSection
                      :moveSection="section"
                      :prevMoveSection="getPrevMoveSection(index)"
                      :prevPointSection="sections[index - 1]"
                      :nextPointSection="sections[index + 1]"
                      :spotWeather="spotWeather(sections[index - 1])"
                      @updateRouteMove="updatePanelStyle()"
                      @clickNavi="onClickNaviStart(selectedRouteTotal, index)"
                    />
                  </div>
                </div>

                <!-- 目的地表示 -->
                <div v-else-if="isLastPointSection(index)" class="flex-1">
                  <div class="flex">
                    <div class="section-border mr-[7px] mt-[19px]">
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        class="relative ml-[7px]"
                        :id="setPointId(index)"
                      >
                        <g fill="#fff" stroke="#009ce5" stroke-width="3">
                          <circle cx="5" cy="5" r="5" stroke="none" />
                          <circle cx="5" cy="5" r="3.5" fill="none" />
                        </g>
                      </svg>
                    </div>
                    <div class="w-[17px]" />
                    <!-- 目的地のカード表示 -->
                    <div class="bg-white rounded-[4px] flex w-full py-4 px-5">
                      <!-- point名称 -->
                      <div class="flex-1">
                        <div class="text-W7 text-[15px] leading-[19px]">
                          {{ section.spotName || section.name }}
                        </div>
                        <WeatherInfo
                          v-if="currentDailyWeatherInfoForGoalSpot"
                          class="mt-1"
                          :weatherCode="currentDailyWeatherInfoForGoalSpot.code"
                          :temperature="
                            currentDailyWeatherInfoForGoalSpot.temperature
                          "
                          :rainyPercent="
                            currentDailyWeatherInfoForGoalSpot.precipitationPercentage
                          "
                        />
                      </div>
                      <!-- 着時間（一番上の場合は出発時間） -->
                      <div class="text-W4 text-[14px] ml-auto pl-[18px]">
                        {{ getFormatTime(sections[index - 1].toTime) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FooterMargin />
          </div>
        </div>
      </template>
    </ScrollPanel>
  </div>
</template>
<script>
import Util from '@/mixins/util'
import SearchRouteUtil from '@/mixins/searchRouteUtil'
import RouteDetailSection from '@/components/organisms/searchRoute/RouteDetailSection.vue'
import ScrollPanel from '@/components/molecules/ScrollPanel.vue'
import SuicaBalance from '@/components/molecules/SuicaBalance.vue'
import NativeUtil from '@/mixins/nativeUtil'
import suica from '@/utils/suicaNativeUtil'
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import {
  isNoNextTransferRequired,
  getStartTimeFromSection,
  getGoalTimeFromSection,
} from '@/utils/transferUtil'
import {transferMinuteToText} from '@/utils/utils'
import WeatherInfo from '@/components/molecules/WeatherInfo.vue'

const ZERO = 0
const ONE = 1
let spotIdNum = 0 // 区間の採番に用いる番号

const RouteDetailByTrainBus = {
  name: 'RouteDetailByTrainBus',
  components: {
    ScrollPanel,
    RouteDetailSection,
    SuicaBalance,
    FooterMargin,
    WeatherInfo,
  },
  mixins: [Util, SearchRouteUtil, NativeUtil],
  data() {
    return {
      selectedRouteTotal: this.$store.state.RouteStore.selectedRouteTotal, // 表示対象のルート情報を取得
      pointInfoList: [], // 区間表示情報
      clearRouteFlg: true, // ルート初期化フラグ
      defaultPanelMode: this.$store.state.swipeMode, // パネルモードの初期値
      isSuicaLinked: false, // Suicaが連携されているか
      balance: 0, // Suica残高
      isReadCardCompleted: false, // Suica残高の取得が完了したか
      isReadCardFailed: false, // Suica残高取得失敗したか
      contentId: 'RouteDetailPanelBody', // パネルボディ部のID
    }
  },
  created() {
    // カウンタをリセット
    spotIdNum = ZERO

    // 選択した経路のルートを描画
    this.shapeRoute()
  },
  async mounted() {
    // 天気取得実行&アプリ表示時のイベントリスナーに追加
    NativeUtil.methods.addResumeEvent(this.fetchSpotWeather)
    this.fetchSpotWeather()

    this.updatePanelStyle()
    if (this.isAndroidWebView()) {
      // Androidの場合Suicaの残高を取得
      await this.getSuicaBalance()
      this.addResumeEvent(this.getSuicaBalance)
    }
  },
  beforeUnmount() {
    NativeUtil.methods.removeResumeEvent(this.fetchSpotWeather)
    // ルート情報の初期化フラグが立っていて、フッターがルート検索の場合、ルート情報を初期化
    if (
      this.clearRouteFlg &&
      this.$store.state.selectedFooterTab == this.$config.FOOTER_ID_SPOT
    ) {
      // 表示用のルート情報を解放
      this.$store.commit('RouteStore/clearSelectedRouteTotal')
    }
    if (this.isAndroidWebView()) {
      // Androidの場合Suicaの残高を取得を削除
      this.removeResumeEvent(this.getSuicaBalance)
    }
  },
  computed: {
    /**
     * 検索条件
     */
    searchConditions() {
      return this.$store.state.RouteStore.trainBusRouteSearchConditions
    },
    /**
     * パネル最大位置(上部余白)
     * @return {Number} パネル最大位置時の上部余白
     */
    maxTopSpace() {
      const baseTop = 60
      const topSafeAreaHeight = this.$store.state.topSafeAreaHeight
      return baseTop + topSafeAreaHeight
    },
    /**
     * パネル中間位置(上部余白)
     * @return {string} パネル中間位置時の上部余白
     */
    middleTopSpace() {
      return `calc(50vh - ${this.$config.FOOTER_HEIGHT}px / 2)`
    },
    /**
     * パネル最小位置(上部余白)
     * @return {string} パネル最小位置時の上部余白
     */
    minTopSpace() {
      const footerHeight = `${this.$config.FOOTER_HEIGHT}px`
      const minHeight = '60px'
      const bottomSafeAreaHeight = `${this.$store.state.bottomSafeAreaHeight}px`
      return `calc(100vh - ${footerHeight} - ${minHeight} - ${bottomSafeAreaHeight})`
    },
    /**
     * セクション情報
     * @return {Object} セクション情報のリスト
     */
    sections() {
      return this.selectedRouteTotal.sections
    },
    /**
     * Suicaの残高が不足しているか判定（取得できなかった）
     * @return {Boolean} Suicaの残高が不足しているか
     */
    isBalanceFallingShort() {
      return this.balance < this.selectedRouteTotal.summaryFare
    },

    /**
     * 出発時間を取得する
     * @return {String} 出発時間 (h:mm形式)
     */
    getStartTime() {
      return this.getFormatTime(
        getStartTimeFromSection(this.selectedRouteTotal.sections)
      )
    },
    /**
     * 到着時間を取得する
     * @return {String} 到着時間 (h:mm形式)
     */
    getGoalTime() {
      return this.getFormatTime(
        getGoalTimeFromSection(this.selectedRouteTotal.sections)
      )
    },
    /**
     * 移動時間を取得する
     * @return {string} 移動時間 + 単位
     */
    getMoveTime() {
      return transferMinuteToText(this.selectedRouteTotal.summaryTime)
    },
    /**
     * 天気情報
     */
    weatherInfo() {
      return this.$store.state.RouteStore.weather
    },
    /**
     * 目的地点における天気情報(直近)を返却する
     */
    currentDailyWeatherInfoForGoalSpot() {
      return this.weatherInfo.goal.daily[0] // eslint-disable-line no-magic-numbers
    },
  },
  methods: {
    /**
     * 出発地点の場合のみ、出発地の天気情報を返却する
     */
    spotWeather(section) {
      return section.name === '出発' ? this.weatherInfo.start : undefined
    },
    /**
     * 出発地・目的地の天気情報を取得し更新する
     */
    async fetchSpotWeather() {
      // 「地点.coord = 緯度,経度」の構成で格納されているため、緯度経度を抽出する
      const getCoord = (spot) => {
        const coord = spot.coord.split(',')
        return {lat: coord[0], lon: coord[1]} // eslint-disable-line no-magic-numbers
      }
      const startSpotCoord = getCoord(this.searchConditions.start)
      const goalSpotCoord = getCoord(this.searchConditions.goal)
      // 出発地と目的地の天気取得&更新
      const getStartWeather =
        this.createAsynchronousProcessForGettingWeather(startSpotCoord)
      const getGoalWeather =
        this.createAsynchronousProcessForGettingWeather(goalSpotCoord)

      // 全て取得が完了したら更新
      return Promise.allSettled([getStartWeather, getGoalWeather]).then(
        ([startWeather, goalWeather]) => {
          // 成功判定
          const isSuccess = (result) => result.status == 'fulfilled'

          // 更新処理
          this.$store.commit('RouteStore/updateWeather', {
            target: 'start',
            daily: isSuccess(startWeather) ? startWeather.value.daily : [],
            weekly: isSuccess(startWeather) ? startWeather.value.weekly : [],
          })
          this.$store.commit('RouteStore/updateWeather', {
            target: 'goal',
            daily: isSuccess(goalWeather) ? goalWeather.value.daily : [],
            weekly: isSuccess(goalWeather) ? goalWeather.value.weekly : [],
          })
        }
      )
    },
    /**
     * 天気取得の非同期処理作成
     * @param {Object} coord 座標
     * @param {String} coord.lat 緯度
     * @param {String} coord.lon 経度
     * @returns {Promise} 天気情報
     */
    createAsynchronousProcessForGettingWeather(coord) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('RouteStore/getWeather', {
          success: (result) => resolve(result),
          failed: () => reject(),
          error: (e) => reject(e),
          lat: coord.lat,
          lon: coord.lon,
        })
      })
    },
    /**
     * パネル内のレイアウト更新
     */
    updatePanelStyle() {
      // 対象のidに対し線を引く
      this.setSectionBorderStyle()
    },
    /**
     * パネル昇降モードを保持する
     * @param {*} swipeMode スワイプモード
     */
    updatePanelMode(swipeMode) {
      this.$store.commit('updateSwipeMode', swipeMode)
    },
    /**
     * ルート描画処理
     */
    shapeRoute() {
      const ROUTE_TYPE_TRAIN_BUS = 'TrainBus'

      if (this.$store.state.routeType == ROUTE_TYPE_TRAIN_BUS) {
        // 表示済みのため何もせず終了する
        return
      }

      const routeTotalScript = this.$store.state.drawRouteData.trainBus.find(
        (route) => {
          return route.id == this.selectedRouteTotal.routeNo
        }
      )
      // ルート取得済みであれば、保持しているルートを設定
      if (routeTotalScript != null && routeTotalScript.data != null) {
        // 表示対象に徒歩ルートを配置
        this.$store.commit('updateRouteType', ROUTE_TYPE_TRAIN_BUS)
        this.$store.commit('updateDrawRouteScript', routeTotalScript.data)
        return
      }

      this.$store.commit('startLoading')

      const vm = this
      const success = () => {
        vm.$store.commit('updateRouteType', ROUTE_TYPE_TRAIN_BUS)
        vm.$store.commit('endLoading')
      }

      const failed = () => {
        vm.$store.commit('updateRouteType', ROUTE_TYPE_TRAIN_BUS)
        vm.$store.commit('endLoading')
        throw new Error()
      }

      const error = (e) => {
        vm.$store.commit('updateRouteType', ROUTE_TYPE_TRAIN_BUS)
        vm.$store.commit('endLoading')
        throw e
      }

      // ルート描画
      this.$store.dispatch('shapeRoute', {
        success: success,
        failed: failed,
        error: error,
        target: this.makeShapeRouteTarget(),
      })
    },
    /**
     * ルート線取得APIのパラメータ生成処理
     */
    makeShapeRouteTarget() {
      // 検索条件の設定
      const target = {
        // 地点条件
        startName: this.searchConditions.start.name,
        startAddress: this.searchConditions.start.address,
        startCoord: this.searchConditions.start.coord,
        goalName: this.searchConditions.goal.name,
        goalAddress: this.searchConditions.goal.address,
        goalCoord: this.searchConditions.goal.coord,
        // 日付
        date: this.searchConditions.targetTime,
        timeType: this.searchConditions.timeType.id,
        // 移動手段
        isSuperexpressTrain:
          this.searchConditions.searchOptions.isSuperexpressTrain,
        isUltraexpressTrain:
          this.searchConditions.searchOptions.isUltraexpressTrain,
        isHighwayBus: this.searchConditions.searchOptions.isHighwayBus,
        isDomesticFlight: this.searchConditions.searchOptions.isDomesticFlight,
        isLocalBus: this.searchConditions.searchOptions.isLocalBus,
        isFerry: this.searchConditions.searchOptions.isFerry,
        // 検索種別
        no: this.selectedRouteTotal.routeNo,
        routeType: this.$config.MOBILITY_CARD_TYPE_TRAIN_BUS,
      }
      // 経由地の追加設定
      const viaCount = this.searchConditions.via.length
      for (let i = 1; i <= viaCount; i++) {
        const index = i - 1 // eslint-disable-line no-magic-numbers
        target[`via${i}Name`] = this.searchConditions.via[index].name
        target[`via${i}Address`] = this.searchConditions.via[index].address
        target[`via${i}Coord`] = this.searchConditions.via[index].coord
      }
      return target
    },
    /**
     * セクションの画像に対し、区間スタイルをメモリに保持しIDを付与
     * @param {Number} index 該当セクションのインデックス
     */
    setPointId(index) {
      // idを生成
      spotIdNum = spotIdNum + ONE
      const id = `pointIcon${spotIdNum}`

      // 対象区間のスタイル情報を保持
      const sectionStyle = this.sectionStyle(this.sections, index)
      const pointInfo = {
        id: id,
        width: sectionStyle.width,
        color: sectionStyle.color,
        style: sectionStyle.lineStyle,
        left: sectionStyle.left,
      }
      this.pointInfoList.push(pointInfo)

      return id
    },
    /**
     * 区間のスタイル情報を生成
     * @param {Object} section 該当セクション情報
     * @param {Number} index 該当セクションのインデックス
     */
    sectionStyle(section, index) {
      const type = section[index].type
      let targetSection
      let res = {
        width: '6px', // 線の幅
        color: this.$config.COLOR.WALK, // 線の色
        lineStyle: 'solid', // 線のレイアウト
        left: '9px', // 線の表示位置
      }
      switch (type) {
        case 'point': // 地点
          targetSection =
            section[index].name == '到着'
              ? section[index - ONE] // 到着の場合は直前の経路を指定
              : section[index + ONE] // 到着以外の場合は直後の経路を指定
          break
        case 'move': // 経路
          targetSection = section[index]
          break
        default:
      }

      if (targetSection.lineName == '徒歩') {
        // 徒歩の場合は徒歩色、線幅、表示位置を返却
        res.color = this.$config.COLOR.WALK
        res.lineStyle = 'dotted'
        res.width = '4px'
        res.left = '10px'
      } else {
        res.color = targetSection.color || this.$config.COLOR.GENERAL_MOBILITY
      }
      return res
    },
    /**
     * 戻るアイコン押下時の遷移処理
     */
    gotoBackpage() {
      // 戻る際はローディングを表示させないようにする
      this.$store.commit('updateIsNoLoadingWhenDrawRoute', true)
      // ルート形状を初期化
      this.$store.commit('clearDrawRouteScript')
      // 天気初期化処理
      this.$store.commit('RouteStore/initWeather')

      this.$router.push({name: this.$config.DISPLAY_ROUTE_BY_TRAIN_BUS})

      this.updatePanelMode(this.$config.PANEL_MODE.MIDDLE)
    },
    /**
     * 【point】各セクションのアイコンを表示するかどうかを返却
     * ・point：「出発」「経由地」「到着」の場合は表示、それ以外は非表示
     * @param {Object} section 該当セクション情報
     * @param {Number} index 該当セクションのインデックス
     */
    isShowPointIcon(sections, index) {
      if (sections[index].type != 'point') {
        return false
      }
      const name = sections[index].name
      return name == '出発' || name == '経由地' || name == '到着'
    },
    /**
     * 【move】各セクションのアイコンを表示するかどうかを返却
     * ・move：直前のセクションが「出発」「経由地」の場合は非表示、それ以外は表示
     * @param {Object} sections セクション一覧
     * @param {Number} index 該当セクションのインデックス
     */
    isShowMoveIcon(sections, index) {
      if (sections[index].type != 'move') {
        return false
      }
      const prevName = sections[index - ONE].name
      // ひとつ前のpointセクション情報が出発及び経由地以外であればtrueを返却
      return prevName != '出発' && prevName != '経由地'
    },
    /**
     * 乗換不要判定を行い返却する
     * @param {Object} sections セクション一覧
     * @param {Number} moveIndex 該当セクション(移動)のインデックス
     * @return {Boolean} 乗換不要判定
     */
    isNoTransferRequired(sections, moveIndex) {
      const prevMoveSection = sections[moveIndex - 2] // eslint-disable-line no-magic-numbers
      const pointSection = sections[moveIndex - 1] // eslint-disable-line no-magic-numbers
      const nextMoveSection = sections[moveIndex]
      return isNoNextTransferRequired(
        prevMoveSection,
        pointSection,
        nextMoveSection
      )
    },
    /**
     * 区間の線に対しスタイルを適用
     */
    setSectionBorderStyle() {
      for (let i = 1; i <= spotIdNum; i++) {
        // 対象とその次の対象のDOMを取得
        let crrentPoint = document.getElementById(`pointIcon${i}`)
        if (i == spotIdNum) {
          // 末尾は高さを0にする
          crrentPoint.parentElement.style.setProperty('--height', '0px')
          return
        }

        let nextPoint = document.getElementById(`pointIcon${i + ONE}`)

        // 区間の高さを算出
        let currentPointRect =
          crrentPoint != null ? crrentPoint.getBoundingClientRect() : null
        let nextPointRect = nextPoint ? nextPoint.getBoundingClientRect() : null
        if (currentPointRect && nextPointRect) {
          const diffRectY = nextPointRect.y - currentPointRect.y

          // 色を取得
          const info = this.pointInfoList.find((info) => {
            return info.id == `pointIcon${i}`
          })

          // 対象のDOMに対してStyleを更新
          crrentPoint.parentElement.style.setProperty(
            '--height',
            diffRectY + 'px'
          )
          crrentPoint.parentElement.style.setProperty('--lineWidth', info.width)
          crrentPoint.parentElement.style.setProperty('--lineColor', info.color)
          crrentPoint.parentElement.style.setProperty('--lineStyle', info.style)
          crrentPoint.parentElement.style.setProperty('--lineLeft', info.left)

          if (crrentPoint.querySelector('g')) {
            // 丸アイコンに色を適用
            crrentPoint
              .querySelector('g')
              .style.setProperty('stroke', info.color)
          }
        }
      }
    },
    /**
     * ナビ開始ボタン押下時処理
     * @param {Object} selectedRouteTotal 選択したルート情報
     * @param {Number} index 選択したルートのインデックス情報
     */
    onClickNaviStart(selectedRouteTotal, index) {
      // 初期化フラグをOFFにする
      this.clearRouteFlg = false

      // storeにナビ情報退避
      const routeNaviInfo = {
        routeType: this.$config.MOBILITY_CARD_TYPE_TRAIN_BUS,
        selectedRouteInfo: selectedRouteTotal,
        sectionNo: index,
      }
      this.$store.commit('RouteStore/updateRouteNaviInfo', routeNaviInfo)

      // ナビ開始位置にフォーカスを移す
      this.focusStartCoord()

      // ナビ画面に遷移
      this.$router.push({name: this.$config.DISPLAY_ROUTE_NAVI})
    },
    /**
     * 出発地の座標に画面をフォーカスする
     */
    focusStartCoord() {
      const start = this.$store.getters['RouteStore/routeNaviStartLatLon']
      this.$root.$refs.routeMap.callFitWithLatLon(start.lat, start.lon)
    },
    /**
     * 最後のpointセクションかどうかの判定
     * @param {Number} index チェックするセクションのインデックス
     * @return {Boolean} true:最後のpointセクション false:それ以外
     */
    isLastPointSection(index) {
      if (this.sections[index].type != 'point') {
        return false
      }
      // eslint-disable-next-line no-magic-numbers
      return this.sections.length <= index + 1
    },
    /**
     * 基準のmoveセクションの一つ前のmoveセクションを取得する
     * @param {Number} basisIndex 基準のmoveセクションのインデックス
     * @return {*} 一つ前のmoveセクション ない場合はnullを返却
     */
    getPrevMoveSection(basisIndex) {
      // eslint-disable-next-line no-magic-numbers
      const prevMoveIndex = basisIndex - 2
      // eslint-disable-next-line no-magic-numbers
      if (prevMoveIndex < 0 || this.sections.length <= prevMoveIndex) {
        // 配列外の指定となる場合はnullを返却
        return null
      }
      return this.sections[prevMoveIndex]
    },
    /**
     * Suica残高取得処理
     */
    async getSuicaBalance() {
      this.isSuicaLinked = await suica.isLinked()
      if (this.isSuicaLinked) {
        try {
          const cardInfo = await suica.getCardInfo()
          this.balance = cardInfo.balance
          this.isReadCardFailed = false
        } catch (e) {
          this.isSuicaLinked = await suica.isLinked()
          this.isReadCardFailed = true
        } finally {
          this.isReadCardCompleted = true
        }
      }
    },
    /**
     * Suica残高を表示押下時の処理
     */
    onClickDisplaySuicaBalance() {
      // 連携済なら何もしない
      if (this.isSuicaLinked) return

      // 初期化フラグをOFFにする
      this.clearRouteFlg = false

      // 遷移元ソース保存
      this.$store.commit('SuicaStore/updateScreenTransitionSource', {
        displayName: this.$config.DISPLAY_ROUTE_DETAIL_BY_TRAIN_BUS,
        footerId: this.$store.state.selectedFooterTab,
      })

      // メニュータブに更新
      this.$store.commit('updateFooterTab', this.$config.FOOTER_ID_MENU)

      this.$router.push({name: this.$config.DISPLAY_SUICA_TOP})
    },
  },
}
export default RouteDetailByTrainBus
</script>
<style scoped>
.section-border {
  position: relative;
}
.section-border::before {
  content: '';
  position: absolute;
  height: var(--height);
  width: 1px;
  border-right: var(--lineWidth) var(--lineStyle) var(--lineColor);
  top: 3px;
  left: var(--lineLeft);
}
/* 直通運転時の斜線 */
.diagonalLine {
  width: 24px;
  height: 8px;
  background: #f2f3f5; /* 背景と同色にする */
  transform: rotate(-45deg);
}
</style>
