<template>
  <div
    id="RouteByCar"
    ref="RouteByCar"
    class="w-full fixed bottom-0 flex flex-col !pointer-events-none"
  >
    <div
      class="circle-back-button mt-safe-area flex justify-center items-center pointer-events-auto"
      @click="goToBackPage()"
    >
      <img class="h-6 w-6" src="@/assets/Icon_Left_grayV2.svg" />
    </div>
    <BasePanel
      class="pointer-events-auto pb-[52px]"
      calcPanelHeightMode="panel"
      :isShowFooter="true"
    >
      <!-- header要素からの相対座標でバナーを表示 -->
      <template v-slot:header>
        <div class="relative w-full">
          <BaseBanner
            class="!pointer-events-none absolute w-full top-[-130px]"
            v-show="isShowBanner"
            :url="bannerInfo.url"
            :pictureSrc="bannerInfo.src"
            position="right"
            @clickClose="closeBanner()"
          />
        </div>
      </template>
      <template v-slot:body>
        <div class="mx-5 mb-5">
          <!-- 上段(おすすめ・一般優先・高速優先タブ) -->
          <div>
            <div class="flex w-full">
              <div
                class="flex-1"
                v-for="radioButton in carTabList"
                :key="radioButton.id"
              >
                <div
                  class="text-[13px] leading-[13px] pb-2 border-b-[1px]"
                  :class="selectedCarTab(radioButton.id)"
                  @click="onClickCarTab(radioButton.id)"
                >
                  {{ radioButton.title }}
                </div>
              </div>
            </div>
          </div>
          <!-- 中段(到着時間・リルートボタン) -->
          <div class="relative flex text-W4 items-end py-5">
            <div>
              <div class="flex-1 text-left">
                <!-- 到着時間 -->
                <span class="text-W7 text-[24px]">
                  {{ arrivalTime }}
                </span>
                <span class="text-W4 text-[14px]">着</span>
                <!-- 移動時間 -->
                <span class="text-W5 text-[15px] leading-[16px] ml-1">(</span>
                <span v-if="isExistHour" class="mr-0.5">
                  <span class="text-W6 text-[15px] leading-[16px]">
                    {{ timeHour }}
                  </span>
                  <span class="text-W5 text-[15px] leading-[16px]">時間</span>
                </span>
                <span class="text-W6 text-[15px] leading-[16px]">
                  {{ timeMinute }}
                </span>
                <span class="text-W5 text-[15px] leading-[16px] mr-0.5">
                  分)
                </span>
                <span class="px-1 text-W5 text-[15px] leading-[16px]">/</span>
                <!-- 距離 -->
                <span class="text-W6 text-[15px] leading-[16px]">
                  {{ roundAndConvert }}
                </span>
                <span class="text-W5 text-[15px] leading-[16px]">
                  {{ getDistanceUnit }}
                </span>
              </div>
            </div>
            <!-- 再検索アイコン -->
            <img
              class="absolute right-0 h-6 w-6"
              src="@/assets/NaviReRouteIcon.svg"
              @click="reRoute()"
            />
          </div>
          <!-- 下段(経路情報等) -->
          <div class="flex">
            <!-- 左側 -->
            <div class="flex-1">
              <!-- 経路表示 -->
              <div class="mr-5">
                <RouteSearchSpots
                  :start="forResearchConditions.start.name"
                  :via="forResearchConditions.via"
                  :goal="forResearchConditions.goal.name"
                  :weather="weatherInfo"
                />
              </div>
            </div>

            <!-- 右側 -->
            <div class="w-[96px] border-l-[1px] border-gray300 pl-4">
              <div class="relative">
                <div
                  id="taxi-fare-information"
                  class="flex"
                  @click="isShowHighwayFareDetail = true"
                >
                  <img class="h-4 w-4" src="@/assets/route/IconTaxiBlue.svg" />
                  <div
                    class="text-primary underline text-W4 text-[12px] leading-[12px] my-auto"
                  >
                    タクシー
                  </div>
                </div>
                <div
                  class="mt-[6px] text-left text-W4 text-[11px] leading-[11px] my-auto"
                >
                  ¥{{ addCommaForInteger(taxiFare) }}〜
                </div>
              </div>
              <div v-if="isShowHighwayFare">
                <div class="flex items-center mt-4">
                  <img class="h-4 w-4" src="@/assets/route/IconYen.svg" />
                  <span
                    class="text-W4 text-[12px] leading-[12px] ml-0.5 mt-4.5"
                  >
                    高速料金
                  </span>
                </div>
                <div
                  class="mt-1 text-left text-W4 text-[11px] leading-[12px] my-auto"
                >
                  ¥{{ addCommaForInteger(summaryFare) }}
                </div>
              </div>
            </div>
          </div>
          <!-- 未決済文言 -->
          <div class="h-8 flex items-end">
            <div
              v-if="!isReservedOrUsingOfMobility && isPaymentFailing"
              class="text-left text-[9px] text-W4 text-danger300"
            >
              未決済のご利用があります
            </div>
          </div>
          <!-- ボタン -->
          <div class="flex">
            <!-- iPhoneSE等横幅が狭い画面の場合意図しない箇所で改行されてしまうため、意図した改行するように個別でボタン定義 -->
            <div
              class="the-button-secondary flex-1 text-[12px] leading-[13px] py-2 px-3 flex relative w-full justify-center items-center text-W7 text-[15px] leading-none px-6 py-4"
              :class="{'opacity-40': isDisableTaxiReserveButton}"
              @click="clickTaxiButton()"
            >
              <div v-if="usingMobilityType === mobilityType.BICYCLE">
                <span class="inline-block">自転車</span>
                <span class="inline-block">利用中</span>
              </div>
              <div v-else-if="usingMobilityType === mobilityType.TAXI">
                <span class="inline-block">タクシー</span>
                <span class="inline-block">利用中</span>
              </div>
              <div v-else>
                <span class="inline-block">タクシー</span>
                <span class="inline-block">手配</span>
              </div>
            </div>
            <div class="w-2" />
            <TheButton
              id="reserve-car-share-button"
              buttonType="secondary"
              text="カーシェア"
              appendImgPath="common/Icon_Target_Blank.svg"
              class="flex-1 text-[12px] leading-[13px] py-2 px-3"
              @click-button="clickCarShareButton()"
            />
            <div class="w-2" />
            <TheButton
              buttonType="primary"
              text="ナビ"
              class="flex-1 text-[12px] leading-[13px] py-2 px-3"
              @click-button="onClickNaviStart()"
            />
          </div>
        </div>
      </template>
    </BasePanel>
    <FooterMargin />
    <!-- 高速料金詳細ポップアップ -->
    <div
      v-show="isShowHighwayFareDetail"
      class="overlay pointer-events-auto"
      @click="isShowHighwayFareDetail = false"
    >
      <div class="overlay-layer">
        <div id="taxi-fare-info-modal" class="w-full mx-6">
          <div class="flex justify-end w-full pb-2">
            <img src="@/assets/Icon_Close_wh.svg" class="h-4 w-4" />
          </div>
          <div class="p-6 rounded-[20px] bg-white text-left">
            <div class="text-W5 text-[15px] leading-[15px] mb-4">
              料金表記について
            </div>
            <div class="text-W3 text-[15px] leading-[19px]">
              タクシー料金は概算です。配車料金、高速代は含まれておりません。
            </div>
          </div>
          <div
            class="flex ml-auto mr-[1px] mt-5 bg-white px-2 py-1 rounded-[12px] w-[82px]"
          >
            <img class="h-4 w-4" src="@/assets/route/IconTaxiBlue.svg" />
            <div
              class="text-primary underline text-W4 text-[12px] leading-[12px] my-auto"
            >
              タクシー
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Util from '@/mixins/util'
import TheButton from '@/components/atoms/TheButton.vue'
import BasePanel from '@/components/atoms/BasePanel.vue'
import BaseBanner from '@/components/atoms/BaseBanner.vue'
import RouteSearchSpots from '@/components/molecules/searchRoute/RouteSearchSpots.vue'
import deepcopy from 'deepcopy'
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import * as mobilityUtil from '@/utils/mobilityUtil'
import NativeUtil from '@/mixins/nativeUtil'

// 車のタブリストのID
const CAR_NUMBER = {
  RECOMMEND: 1,
  FREE: 2,
  TOLL: 3,
}

// 車のタブリスト
const CAR_TAB_LIST = [
  {id: CAR_NUMBER.RECOMMEND, title: 'おすすめ'},
  {id: CAR_NUMBER.FREE, title: '一般優先'},
  {id: CAR_NUMBER.TOLL, title: '高速優先'},
]

const RouteByCar = {
  name: 'RouteByCar',
  mixins: [Util],
  components: {
    TheButton,
    BaseBanner,
    BasePanel,
    RouteSearchSpots,
    FooterMargin,
  },
  data() {
    return {
      // 選択中の車タブ
      showingCarType: CAR_NUMBER.RECOMMEND,
      // 車のタブリスト
      carTabList: CAR_TAB_LIST,
      // 高速料金詳細ポップアップフラグ
      isShowHighwayFareDetail: false,
      // 最適画面に戻るフラグ
      gotoOptimalFlg: false,
      // 経由地を表示するフラグ
      isOpenedVia: false,
    }
  },
  created() {
    // 初期表示データを取得
    this.showingCarType = this.selectedShowingCarType()
    // ルート形状の更新
    this.updateShapeRoute(this.selectedCarType)
  },
  mounted() {
    // 天気取得実行&アプリ表示時のイベントリスナーに追加
    NativeUtil.methods.addResumeEvent(this.fetchSpotWeather)
    this.fetchSpotWeather()
    // タクシー料金説明モーダルの位置設定
    this.setTaxiFareInformationModalPosition()
  },
  updated() {
    // タクシー料金説明モーダルの位置設定
    this.setTaxiFareInformationModalPosition()
  },
  unmounted() {
    NativeUtil.methods.removeResumeEvent(this.fetchSpotWeather)
    if (this.gotoOptimalFlg) {
      // 最適ルート画面に遷移する場合
      // 再検索用検索条件を初期化
      this.$store.commit('RouteStore/updateForResearchConditions', {
        mode: 'init',
      })
      // 再検索用検索結果を初期化
      this.$store.commit('RouteStore/updateForResearchResult', {
        mode: 'init',
      })
      // 検索ルート形状の初期化
      this.$store.commit('RouteStore/updateReRouteCarShapeData', {
        mode: 'init',
      })
      // 車タブ選択状態を初期化
      this.$store.commit(
        'RouteStore/updateSelectedCarType',
        this.$config.CAR_TYPE.RECOMMEND
      )
      // アイコンをクリア
      this.$root.$refs.routeMap.clearSearchSpotMarkers()
    }
  },
  computed: {
    /**
     * 再検索用の検索条件
     */
    forResearchConditions() {
      return this.$store.state.RouteStore.forResearchConditions
    },
    /**
     * おすすめ・一般優先・高速優先の制御
     */
    checked: {
      get() {
        return this.showingCarType
      },
      set(value) {
        this.showingCarType = value
      },
    },
    /**
     * 検索結果取得
     */
    routeSearchResult() {
      return this.$store.state.RouteStore.forResearchResult
    },
    /**
     * 検索結果取得
     */
    routeSearchCarResult() {
      return this.routeSearchResult[this.selectedCarType][this.$config.ZERO]
    },
    /**
     * 到着時刻(HH:mm)を算出
     */
    arrivalTime() {
      // 一番最後のtoTime保持セクションを検索
      const arrivalTimeSection = this.routeSearchCarResult.sections.findLast(
        (section) => section.toTime
      )
      return this.getFormatTime(arrivalTimeSection.toTime)
    },
    /**
     * 移動時間(h)の値を算出
     */
    timeHour() {
      const summaryTime = this.routeSearchCarResult.summaryTime
      const hour = 60
      return Math.floor(summaryTime / hour)
    },
    /**
     * 移動時間(m)の値を算出
     */
    timeMinute() {
      const summaryTime = this.routeSearchCarResult.summaryTime
      const hour = 60
      return summaryTime % hour
    },
    /**
     * タクシー料金の取得
     */
    taxiFare() {
      return this.routeSearchCarResult.additionalFare.taxi
    },
    /**
     * 高速料金の取得
     */
    summaryFare() {
      return this.routeSearchCarResult.summaryFare
    },
    /**
     * 時間の単位が存在するかどうか
     */
    isExistHour() {
      return 0 < this.timeHour // eslint-disable-line no-magic-numbers
    },
    /**
     * 高速料金を表示するかどうか
     * ・高速優先：表示固定（0円でも表示）
     * ・上記以外：料金の発生する場合のみ表示
     */
    isShowHighwayFare() {
      return (
        // eslint-disable-next-line no-magic-numbers
        this.showingCarType === CAR_NUMBER.TOLL || 0 < this.summaryFare
      )
    },
    /**
     * 選択中の車種別
     */
    selectedCarType() {
      return this.$store.state.RouteStore.selectedCarType
    },
    /**
     * 一度でもリルートを実行しているかどうか(検索条件が変更されているか)
     */
    isExecutedReRoute() {
      return (
        JSON.stringify(
          deepcopy(this.$store.state.RouteStore.forResearchConditions)
        ) != JSON.stringify(deepcopy(this.$store.state.searchConditions))
      )
    },
    /**
     * 移動距離に応じて表示する単位を切り替える
     */
    getDistanceUnit() {
      return this.isKilo(this.routeSearchCarResult.totalDistance) ? 'km' : 'm'
    },
    /**
     * 移動距離に応じて距離の単位を切り替える
     */
    roundAndConvert() {
      const distance = this.routeSearchCarResult.totalDistance
      return this.isKilo(distance)
        ? // 1km以上の場合、km単位に変換する
          this.roundAndConvertToKilo(distance, 1) // eslint-disable-line no-magic-numbers
        : // 1km未満の場合、m単位のまま返却
          distance
    },
    /**
     * 利用中のモビリティ種別
     */
    usingMobilityType() {
      return this.$store.getters[
        'MobilityReservationStore/reservedOrUsingOfMobilityType'
      ]
    },
    /**
     * 「予約中」または「利用中」のモビリティがあるかどうか
     */
    isReservedOrUsingOfMobility() {
      return this.usingMobilityType !== this.$config.USING_MOBILITY_TYPE.NONE
    },
    /**
     * 決済が失敗状態であるかどうか
     * @return 決済失敗有無
     */
    isPaymentFailing() {
      return mobilityUtil.determiningPaymentFailing(
        this.$store.state.CreditCardsStore.paymentStatus
      )
    },
    /**
     * タクシー手配ボタンの非活性状態
     */
    isDisableTaxiReserveButton() {
      return this.isReservedOrUsingOfMobility || this.isPaymentFailing
    },
    /**
     * モビリティ利用種別
     */
    mobilityType() {
      return this.$config.USING_MOBILITY_TYPE
    },
    /**
     * 検索結果によって配置するアイコン
     */
    searchSpotsGlMarkers() {
      return this.$store.state.searchSpotsGlMarkers
    },
    /**
     * 表示するバナー情報
     * @returns ジャンプ先、画像パス
     */
    bannerInfo() {
      // TODO: 目的地のバナー分岐はここを拡張していきたい
      return {
        url: process.env.VUE_APP_TMNF_TYOINORI_CAR_INSURANCE_URL,
        src: this.$config.MOBILITY_BANNER.CAR.ICON_PATH,
      }
    },
    /**
     * バナー表示有無
     */
    isShowBanner() {
      return this.$store.state.RouteStore.displayBanner.car
    },
    /**
     * 天気情報
     */
    weatherInfo() {
      return this.$store.state.RouteStore.weather
    },
  },
  methods: {
    /**
     * 出発地・目的地の天気情報を取得し更新する
     */
    async fetchSpotWeather() {
      // 「地点.coord = 緯度,経度」の構成で格納されているため、緯度経度を抽出する
      const getCoord = (spot) => {
        const coord = spot.coord.split(',')
        return {lat: coord[0], lon: coord[1]} // eslint-disable-line no-magic-numbers
      }
      const startSpotCoord = getCoord(this.forResearchConditions.start)
      const goalSpotCoord = getCoord(this.forResearchConditions.goal)
      // 出発地と目的地の天気取得&更新
      const getStartWeather =
        this.createAsynchronousProcessForGettingWeather(startSpotCoord)
      const getGoalWeather =
        this.createAsynchronousProcessForGettingWeather(goalSpotCoord)

      // 全て取得が完了したら更新
      return Promise.allSettled([getStartWeather, getGoalWeather]).then(
        ([startWeather, goalWeather]) => {
          // 成功判定
          const isSuccess = (result) => result.status == 'fulfilled'

          // 更新処理
          this.$store.commit('RouteStore/updateWeather', {
            target: 'start',
            daily: isSuccess(startWeather) ? startWeather.value.daily : [],
            weekly: isSuccess(startWeather) ? startWeather.value.weekly : [],
          })
          this.$store.commit('RouteStore/updateWeather', {
            target: 'goal',
            daily: isSuccess(goalWeather) ? goalWeather.value.daily : [],
            weekly: isSuccess(goalWeather) ? goalWeather.value.weekly : [],
          })
        }
      )
    },
    /**
     * 天気取得の非同期処理作成
     * @param {Object} coord 座標
     * @param {String} coord.lat 緯度
     * @param {String} coord.lon 経度
     * @returns {Promise} 天気情報
     */
    createAsynchronousProcessForGettingWeather(coord) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('RouteStore/getWeather', {
          success: (result) => resolve(result),
          failed: () => reject(),
          error: (e) => reject(e),
          lat: coord.lat,
          lon: coord.lon,
        })
      })
    },
    /**
     * タクシー料金についてのモーダル位置設定
     * ※ margin-bottomを利用して位置を調整する
     */
    setTaxiFareInformationModalPosition() {
      // タクシー料金説明に関する各要素を取得
      const taxiText = document.getElementById('taxi-fare-information') // モーダル非表示時の「タクシー」テキスト
      const taxiFareInfoModal = document.getElementById('taxi-fare-info-modal') // モーダル内の料金情報
      // タクシーテキストの位置と要素高を取得
      const taxiTextY = taxiText.getBoundingClientRect().y
      const taxiTextH = taxiText.getBoundingClientRect().height
      const padding = 4 // モーダル表示時の内余白
      // 下部余白を設定
      const marginBottom = `calc(100vh - ${taxiTextY}px - ${taxiTextH}px - ${padding}px)`
      taxiFareInfoModal.style.setProperty('margin-bottom', marginBottom)
    },
    /**
     * おすすめ・一般優先・高速優先の選択タブのスタイルを設定
     */
    selectedCarTab(id) {
      return this.checked === id
        ? 'text-W7 text-primary border-primary'
        : 'text-W4 text-gray400 border-gray300'
    },
    /**
     * リルート処理
     * @param {Boolean} isNoUpdateInfo ルート情報の更新が必要かどうか
     */
    reRoute(isNoUpdateInfo) {
      const vm = this
      this.$store.commit('startLoading')

      const success = (newCondition, newResult, newRoute) => {
        if (!isNoUpdateInfo) {
          // 検索条件の更新
          vm.$store.commit('RouteStore/updateForResearchConditions', {
            value: newCondition,
          })
          // 検索結果の更新
          vm.$store.commit('RouteStore/updateForResearchResult', {
            key: this.$config.CAR_TYPE.RECOMMEND,
            value: newResult.recommend,
          })
          vm.$store.commit('RouteStore/updateForResearchResult', {
            key: this.$config.CAR_TYPE.TOLL,
            value: newResult.tollTime,
          })
          vm.$store.commit('RouteStore/updateForResearchResult', {
            key: this.$config.CAR_TYPE.FREE,
            value: newResult.freeTime,
          })

          // 検索ルート形状の初期化
          vm.$store.commit('RouteStore/updateReRouteCarShapeData', {
            mode: 'init',
          })
        }

        // 検索ルート形状の保持
        vm.$store.commit('RouteStore/updateReRouteCarShapeData', {
          key: this.selectedCarType,
          value: newRoute,
        })

        // 検索ルートの更新
        vm.$store.commit('updateDrawRouteScript', newRoute)

        // 天気更新
        this.fetchSpotWeather()

        vm.$store.commit('endLoading')
      }

      // リルート実行
      this.$store.dispatch('RouteStore/reRoute', {
        success: success,
        routeType: this.selectedCarType,
        isNoUpdateInfo: isNoUpdateInfo,
      })
    },
    /**
     * 選択された車種判別のためのIDを返却
     * @return {Number} 自分の車のタブリストのID
     */
    selectedShowingCarType() {
      let showingCarType
      switch (this.selectedCarType) {
        case this.$config.CAR_TYPE.FREE:
          showingCarType = CAR_NUMBER.FREE
          break
        case this.$config.CAR_TYPE.TOLL:
          showingCarType = CAR_NUMBER.TOLL
          break
        case this.$config.CAR_TYPE.RECOMMEND:
        default:
          showingCarType = CAR_NUMBER.RECOMMEND
          break
      }
      return showingCarType
    },
    /**
     * 前の画面(最適画面)に遷移
     */
    goToBackPage() {
      // 最適画面に戻る際はローディングを表示させないようにする
      this.$store.commit('updateIsNoLoadingWhenDrawRoute', true)
      // ルート形状を初期化
      this.$store.commit('clearDrawRouteScript')
      // 天気初期化処理
      this.$store.commit('RouteStore/initWeather')

      // 最適ルート画面への遷移フラグを有効にする
      this.gotoOptimalFlg = true

      // 最適ルート画面に遷移
      this.$router.push({name: this.$config.DISPLAY_ROUTE_BY_OPTIMAL})
    },
    /**
     * 車タブ内のおすすめ、一般優先、高速優先を選択した際の処理
     * @param {String} carID 検索条件
     */
    onClickCarTab(carID) {
      this.showingCarType = carID
      // 選択された車の中のタブを検索
      let carType = this.getCarTypeFromCarId(carID)
      if (carType) {
        // 選択中の車種別を変更する
        this.$store.commit('RouteStore/updateSelectedCarType', carType)
        // ルート形状を更新する
        this.updateShapeRoute(carType)
      }
    },
    /**
     * ルート形状を更新する
     * 検索条件がない/表示中のルート情報がない/描画データ取得済である場合処理抜ける
     * @param {String} carType 検索条件
     */
    updateShapeRoute(carType) {
      if (this.$store.state.routeType == this.selectedCarType) {
        // eslint-disable-next-line no-magic-numbers
        if (this.searchSpotsGlMarkers.length !== 0) {
          // 表示済みのため何もせず終了する
          return
        }
      }

      if (this.isExecutedReRoute) {
        //リルート検索済みの場合
        this.updateShapeRouteWhenReRoute(carType)
      } else {
        // リルートを行なっていない場合
        this.updateShapeRouteWhenStandard(carType)
      }
    },
    /**
     * リルート検索時のルート形状を更新する
     * @param {String} carType 検索条件
     */
    updateShapeRouteWhenReRoute(carType) {
      const routeScript =
        this.$store.state.RouteStore.reRouteCarShapeData[carType]

      // 一度取得していればスキップ
      if (routeScript != null) {
        this.$store.commit('updateRouteType', this.selectedCarType)
        this.$store.commit('updateDrawRouteScript', routeScript)
        return
      }
      // リルート実行
      this.reRoute(true)
    },
    /**
     * リルート検索ではない場合のルート形状を更新する
     * @param {String} carType 検索条件
     */
    updateShapeRouteWhenStandard(carType) {
      const routeSearchParam = this.$store.state.RouteStore.routeSearchParam.car
      // 検索時パラメータが取れない場合や表示ルートがない場合はスルー
      if (!routeSearchParam || !this.routeSearchCarResult) {
        this.$store.commit('clearDrawRouteScript')
        return
      }

      const routeScript = this.$store.state.drawRouteData[carType]

      // 一度取得していればスキップ
      if (routeScript != null) {
        this.$store.commit('updateRouteType', this.selectedCarType)
        this.$store.commit('updateDrawRouteScript', routeScript)
        return
      }

      // 車の検索タイプを設定
      routeSearchParam.routeType = carType

      // 終了時処理
      const vm = this
      const success = () => {
        vm.$store.commit('updateRouteType', this.selectedCarType)
        vm.$store.commit('endLoading')
      }

      const failed = () => {
        vm.$store.commit('updateRouteType', this.selectedCarType)
        vm.$store.commit('endLoading')
        throw new Error()
      }

      const error = (e) => {
        vm.$store.commit('updateRouteType', this.selectedCarType)
        vm.$store.commit('endLoading')
        throw e
      }

      // ローディング開始
      this.$store.commit('startLoading')
      // ルート描画
      this.$store.dispatch('shapeRoute', {
        success: success,
        failed: failed,
        error: error,
        target: routeSearchParam,
      })
    },
    /**
     * 車のIDから車種別を返す
     * @param {String} carID 検索条件
     * @return {String} 車種別名
     */
    getCarTypeFromCarId(carID) {
      switch (carID) {
        case CAR_NUMBER.RECOMMEND:
          return this.$config.CAR_TYPE.RECOMMEND
        case CAR_NUMBER.TOLL:
          return this.$config.CAR_TYPE.TOLL
        case CAR_NUMBER.FREE:
          return this.$config.CAR_TYPE.FREE
        default:
          return
      }
    },
    /**
     * ナビ開始ボタン押下時処理
     * ナビ画面に遷移し、表示中の車ルート情報を更新する
     */
    onClickNaviStart() {
      // storeにナビ情報退避
      const routeNaviInfo = {
        routeType: this.$config.MOBILITY_CARD_TYPE_CAR,
        selectedRouteInfo: this.routeSearchCarResult,
      }
      this.$store.commit('RouteStore/updateRouteNaviInfo', routeNaviInfo)

      // ナビ開始位置にフォーカスを移す
      this.focusStartCoord()

      // ナビ画面に遷移
      this.$router.push({name: this.$config.DISPLAY_ROUTE_NAVI})
    },
    /**
     * 出発地の座標に画面をフォーカスする
     */
    focusStartCoord() {
      const start = this.$store.getters['RouteStore/routeNaviStartLatLon']
      this.$root.$refs.routeMap.callFitWithLatLon(start.lat, start.lon)
    },
    /**
     * タクシーを手配ボタン押下処理
     */
    clickTaxiButton() {
      // 非活性の際は何も通知しない
      if (this.isDisableTaxiReserveButton) return

      // タクシー用ルート情報更新
      this.updateRouteInfoInTaxi()
      this.storeCurrentTabInfomation()
      // 遷移元のパスをstoreに登録
      this.$store.commit(
        'MobilityReservationStore/updateScreenTransitionSource',
        {
          displayName: this.$config.DISPLAY_ROUTE_BY_CAR,
          footerId: this.$store.state.selectedFooterTab,
        }
      )
      // フッタータブ情報を切り替え
      this.$store.commit('updateFooterTab', this.$config.FOOTER_ID_ARRANGEMENT)
      // 画面遷移
      this.$router.push({name: this.$config.DISPLAY_TAXI_RESERVATION_TOP})
    },
    clickCarShareButton() {
      // カーシェアを手配
      // 外部Webサイトを開く
      window.open(
        process.env.VUE_APP_TIMES_CAR_SHARE_HP_URL,
        '_blank',
        'noreferrer'
      )
    },
    /**
     * 現在のタブの表示ページ情報を保持する
     */
    storeCurrentTabInfomation() {
      const footerName = this.$config.FOOTER_LIST.find((footer) => {
        return footer.id == this.$store.state.selectedFooterTab
      }).name
      let page = {key: footerName, value: this.$route.name}
      this.$store.commit('updateCurrentPage', page)
    },
    /**
     * 検索条件からタクシー用ルート情報を抽出し、Storeにコミットする処理
     */
    updateRouteInfoInTaxi() {
      // 検索条件取得
      const forResearchConditions = deepcopy(this.forResearchConditions)

      // 出発地・目的地の座標取得
      const startLatLon = forResearchConditions.start.coord.split(',')
      const goalLatLon = forResearchConditions.goal.coord.split(',')

      const latIndex = 0
      const lonIndex = 1

      // ルート情報オブジェクト作成
      const updateRouteInfo = {
        pickUp: {
          lat: startLatLon[latIndex],
          lon: startLatLon[lonIndex],
          address: forResearchConditions.start.address,
        },
        dropOff: {
          lat: goalLatLon[latIndex],
          lon: goalLatLon[lonIndex],
          address: forResearchConditions.goal.address,
        },
      }

      // タクシー用Storeに保管
      this.$store.commit('MobilityReservationStore/updateRouteInfoInTaxi', {
        obj: updateRouteInfo,
      })
    },
    /**
     * バナーの表示状態を非表示にする
     */
    closeBanner() {
      this.$store.commit('RouteStore/updateDisplayBanner', {
        target: this.$config.MOBILITY.CAR,
        isShow: false,
      })
    },
  },
}
export default RouteByCar
</script>
<style scoped>
#radio1 input[type='radio'] + label {
  background-color: white;
  color: '#6B7075';
  border: 1px solid white;
}
#radio1 input[type='radio']:checked + label {
  background-color: #009ce5;
  color: white;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 6px #00000026;
}
.overlay {
  position: fixed;
  z-index: 5000;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 5000px);
  transform: translate3d(0, 0, 5000px);
}
.overlay-layer {
  display: flex;
  align-items: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1c2133;
  backdrop-filter: blur(3px) brightness(81%);
}
.translateZ-6000 {
  transform: translateZ(6000px);
}
</style>
