/**
 * モビリティ予約用Storeモジュール
 */
import Util from '@/mixins/util.js'
import * as Axios from '@/service/AxiosService'
import config from '@/const/const.js'
const deepcopy = require('deepcopy')
const constant = config.constant
import log from 'loglevel'

// ポート詳細情報の初期値
const DEFAULT_PARK_DETAIL_INFO = {
  planInfo: {
    basic: {}, // 基本利用
    use: {}, // 延長利用
  }, // プラン情報
  parkInfo: {}, // 予約対象のポート情報
  cycleInfo: {
    // 予約可能なバイク情報
    cycleList: [],
  },
}

// 予約ステータス初期値
const DEFAULT_RESERVATION_STATUS = '0'
// タクシー手配ステータス初期値
const DEFAULT_RESERVATION_STATUS_IN_TAXI = ''

// 自転車予約情報初期値
const DEFAULT_RESERVATION_INFO_BICYCLE = {
  reserveLimit: '',
  csaType: 0,
  cycName: '',
  passcode: '',
  parkName: '',
  imageUrl: '',
  startTime: '',
  endTime: '',
  parkAddress: '',
  howToAccess: '',
  lat: '',
  lon: '',
}

// タクシー車両情報初期値
const DEFAULT_VEHICLE_INFO_IN_TAXI = {
  status: '',
  orderId: '',
  orderedDate: '',
  waitingNumber: '',
  pickUpArrivalDate: '',
  dropOffArrivalMinutes: null,
  cancelReason: '',
  onlinePayment: {
    amount: null,
    currency: '',
    breakdown: {},
  },
  company: {
    name: '',
    logoImageUrl: '',
    pickupFeeDescription: '',
    phoneNumber: '',
  },
  vehicle: {
    radioNumber: '',
    numberPlate: '',
    direction: '',
    latitude: null,
    longitude: null,
  },
}

// タクシー用ルート情報詳細初期値
const DEFAULT_ROUTE_INFO_DETAIL_IN_TAXI = {
  lat: '',
  lon: '',
  address: '',
}

// タクシー用ルート情報初期値
const DEFAULT_ROUTE_INFO_IN_TAXI = {
  // 乗車地
  pickUp: deepcopy(DEFAULT_ROUTE_INFO_DETAIL_IN_TAXI),
  // 目的地
  dropOff: deepcopy(DEFAULT_ROUTE_INFO_DETAIL_IN_TAXI),
}

// タクシー配車可能判定用のキャンセルソース
let checkDispatchAreaCancelSources = [{default: null}]

const DEFAULT_ESTIMATED_ARRIVAL_TIME = {minWaitTime: null, maxWaitTime: null}
export default {
  namespaced: true,
  state: {
    //遷移元パス
    screenTransitionSource: {
      displayName: '',
      footerId: '',
    },
    // シェアバイク用データ格納
    bicycle: {
      parkInfo: [], //ポート情報(ポート一覧)
      parkDetailInfo: deepcopy(DEFAULT_PARK_DETAIL_INFO), //ポート詳細情報
      selectedParkInfo: {}, // 選択されたポート情報(1件のみ)
      selectedCycleInfo: {}, // 選択した自転車情報
      selectedCycleTerms: '', // 選択した自転車の利用規約URL
      userInfo: {}, // 利用者情報
      reservationInfo: deepcopy(DEFAULT_RESERVATION_INFO_BICYCLE), // 予約情報
      reservationStatus: deepcopy(DEFAULT_RESERVATION_STATUS), // 予約ステータス
      isReservationCancelFlg: false, // 予約キャンセルフラグ（デフォルトはfalse、ユーザー手動で予約キャンセルを実施した際にtrueとなる。）
    },
    // Goアプリ用データ格納
    taxi: {
      routeInfo: deepcopy(DEFAULT_ROUTE_INFO_IN_TAXI), // ルート情報
      userInfo: {}, // 利用者情報
      reservationStatus: deepcopy(DEFAULT_RESERVATION_STATUS_IN_TAXI), // タクシー配車ステータス
      templateMessageList: [], // チャット送信用のメッセージ一覧
      messageList: [], // チャット内容のリスト
      vehicleInfo: deepcopy(DEFAULT_VEHICLE_INFO_IN_TAXI), // 車両情報
      pollingTimerId: null, // ポーリング用タイマーID
      isNetworkErrorOccurred: false, // ネットワークエラー発生フラグ
      estimatedArrival: structuredClone(DEFAULT_ESTIMATED_ARRIVAL_TIME), // 到着予測時間(最小/最大)
    },
    inProgressGetMobilityInfo: false, // モビリティ情報取得中フラグ
  },
  getters: {
    /**
     * 自転車手配ステータスが、「予約中」「利用中」であるかどうか
     */
    isReservedOrUsingOfCycleStatus(state) {
      const status = state.bicycle.reservationStatus

      // 手配ステータスが予約完了か利用開始の場合はtrueを返す
      return (
        status === constant.BICYCLE_STATUS_CODE.COMPLETE_RESERVATION ||
        status === constant.BICYCLE_STATUS_CODE.START_USING
      )
    },
    /**
     * 「予約中」または「利用中」のモビリティ種別を返す
     * @param {*} state
     * @returns 「予約中」または「利用中」のモビリティ種別
     */
    reservedOrUsingOfMobilityType(state) {
      const bicycleStatus = state.bicycle.reservationStatus
      const taxiStatus = state.taxi.reservationStatus
      const isUnreadTaxiPassiveCancelModal = !Util.methods.getLocalStorage(
        constant.IS_READ_TAXI_PASSIVE_CANCEL_MODAL
      )

      if (
        bicycleStatus === constant.BICYCLE_STATUS_CODE.RESERVING ||
        bicycleStatus === constant.BICYCLE_STATUS_CODE.COMPLETE_RESERVATION ||
        bicycleStatus === constant.BICYCLE_STATUS_CODE.START_USING
      ) {
        // バイクシェア手配ステータスが利用中に該当する場合は[ドコモバイクシェア]の種別を返却
        return constant.USING_MOBILITY_TYPE.BICYCLE
      } else if (
        taxiStatus === constant.TAXI_RESERVATION_STATUS.RESERVING ||
        taxiStatus === constant.TAXI_RESERVATION_STATUS.FINDING ||
        taxiStatus === constant.TAXI_RESERVATION_STATUS.DISPATCHING ||
        taxiStatus === constant.TAXI_RESERVATION_STATUS.PICK_UP_ARRIVED ||
        taxiStatus === constant.TAXI_RESERVATION_STATUS.ON_DELIVER ||
        (taxiStatus === constant.TAXI_RESERVATION_STATUS.CANCELED &&
          isUnreadTaxiPassiveCancelModal)
      ) {
        // タクシー配車ステータスが利用中に該当する場合は[GOタクシー]の種別を返却
        // タクシー配車ステータスがキャンセルの場合は受動キャンセルモーダル未読時のみ利用中として扱う
        return constant.USING_MOBILITY_TYPE.TAXI
      } else {
        // いずれにも当てはまらない場合は[利用中のモビリティ無し]の種別を返却
        return constant.USING_MOBILITY_TYPE.NONE
      }
    },
    /**
     * タクシー手配中かどうか
     * @param {*} state
     * @returns
     */
    isArrangingTaxiStatus(state) {
      const status = state.taxi.reservationStatus
      return (
        status === constant.TAXI_RESERVATION_STATUS.DISPATCHING ||
        status === constant.TAXI_RESERVATION_STATUS.PICK_UP_ARRIVED ||
        status === constant.TAXI_RESERVATION_STATUS.ON_DELIVER
      )
    },
    /**
     * タクシーを予約処理中、または探している最中か判定
     * @param {*} state Storeオブジェクト
     * @returns {Boolean} タクシーを予約処理中、または探している最中か
     */
    isReservingOrFindingTaxiStatus(state) {
      const status = state.taxi.reservationStatus
      return (
        status === constant.TAXI_RESERVATION_STATUS.RESERVING ||
        status === constant.TAXI_RESERVATION_STATUS.FINDING
      )
    },
    /**
     * 自転車を予約している最中か
     * @param {*} state Storeオブジェクト
     * @returns {Boolean} 自転車を予約している最中か
     */
    isReservingBicycleStatus(state) {
      return (
        state.bicycle.reservationStatus ===
        constant.BICYCLE_STATUS_CODE.RESERVING
      )
    },
    /**
     * 自転車手配ステータスが「予約時間オーバー」であるかどうか
     * @param {*} state Storeオブジェクト
     * @returns true: 予約時間オーバー、false：それ以外
     */
    isReservationOverTime(state) {
      const status = state.bicycle.reservationStatus

      if (status !== constant.BICYCLE_STATUS_CODE.CANCEL_RESERVATION) {
        // ステータスが200（予約キャンセル or 予約時間オーバー）以外
        return false
      }
      if (state.bicycle.isReservationCancelFlg) {
        // 予約キャンセルの場合
        return false
      }

      // 予約時間オーバーの場合
      return true
    },
    /**
     * 金額をカンマ付きにしたプラン情報を返す
     * @param {*} state
     * @returns 金額をカンマ付きにしたプラン情報
     */
    planInfoWithCommadCharge(state) {
      const planInfo = deepcopy(state.bicycle.parkDetailInfo.planInfo)
      // 金額が千円以上の場合はカンマ付きに変換する
      planInfo.basic.basicCharge = Util.methods.addCommaForInteger(
        planInfo.basic.basicCharge
      )
      planInfo.use.useCharge = Util.methods.addCommaForInteger(
        planInfo.use.useCharge
      )
      return planInfo
    },
  },
  mutations: {
    /**
     * 遷移元パス更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 遷移元パス
     */
    updateScreenTransitionSource(state, obj) {
      state.screenTransitionSource = {
        displayName: obj.displayName,
        footerId: obj.footerId,
      }
    },
    /**
     * ポート情報更新処理
     * @param {*} state Storeオブジェクト
     * @param {Array} obj APIから取得したポート情報
     */
    updateBicycleParkInfo(state, obj) {
      state.bicycle.parkInfo = obj
    },
    /**
     * ポート詳細情報更新処理
     * @param {*} state Storeオブジェクト
     * @param {Array} obj 更新対象のポート詳細情報
     */
    updateParkDetailInfo(state, obj) {
      state.bicycle.parkDetailInfo = obj
    },
    /**
     * 選択ポート情報更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象の選択ポート情報
     */
    updateSelectedParkInfo(state, obj) {
      state.bicycle.selectedParkInfo = obj
    },
    /**
     * 選択した自転車情報の更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象の自転車情報
     */
    updateSelectedCycleInfo(state, obj) {
      state.bicycle.selectedCycleInfo = obj
    },
    /**
     * 選択した自転車の利用規約URL更新処理
     * @param {*} state Storeオブジェクト
     * @param {String} url 更新対象の自転車利用規約情報URL
     */
    updateSelectedCycleTerms(state, url) {
      state.bicycle.selectedCycleTerms = url
    },
    /**
     * 自転車利用者情報の更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象の利用者情報
     */
    updateBicycleUserInfo(state, obj) {
      state.bicycle.userInfo = obj
    },
    /**
     * タクシー利用者情報の更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象の利用者情報
     */
    updateTaxiUserInfo(state, obj) {
      state.taxi.userInfo = obj
    },
    /**
     * 予約情報の更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象の予約情報
     */
    updateReservationInfo(state, obj) {
      state.bicycle.reservationInfo = obj
    },
    /**
     * 自転車予約ステータスの更新
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象の予約ステータス
     */
    updateBicycleReservationStatus(state, obj) {
      state.bicycle.reservationStatus = obj
    },
    /**
     * 予約キャンセルフラグの更新
     * @param {*} state Storeオブジェクト
     * @param {*} obj
     */
    updateReservationCancelFlg(state, obj) {
      state.bicycle.isReservationCancelFlg = obj
    },
    /**
     * タクシー用ルート情報を更新
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象の乗車地
     * @param {String} key 更新対象キー
     */
    updateRouteInfoInTaxi(state, {obj, key}) {
      // キー指定がない場合は全更新
      if (Util.methods.isNull(key)) {
        state.taxi.routeInfo = obj
        return
      }

      // キーが存在する場合
      if (Util.methods.isContainsKey(state.taxi.routeInfo, key)) {
        // 指定されたキーの値を更新
        state.taxi.routeInfo[key] = obj
      }
    },
    /**
     * タクシー手配ステータスの更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象の手配ステータス
     */
    updateTaxiReservationStatus(state, obj) {
      state.taxi.reservationStatus = obj
    },
    /**
     * タクシーメッセージ一覧の更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象のチャット一覧
     */
    updateTaxiReservationMessageList(state, obj) {
      state.taxi.messageList = obj
    },
    /**
     *  // チャット送信用のメッセージ一覧の更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象のチャットテンプレート一覧
     */
    updateTaxiReservationTemplateMessageList(state, obj) {
      state.taxi.templateMessageList = obj
    },
    /**
     * タクシー到着予想時間の更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 更新対象の到着予想時間
     */
    updateEstimatedArrivalTime(state, obj) {
      state.taxi.estimatedArrival = obj
    },
    /**
     * シェアバイク情報初期化処理
     * @param {*} state Storeオブジェクト
     */
    initBicycleInfo(state) {
      state.screenTransitionSource = ''
      state.bicycle.parkInfo = []
      state.bicycle.parkDetailInfo = deepcopy(DEFAULT_PARK_DETAIL_INFO)
      state.bicycle.selectedParkInfo = {}
      state.bicycle.selectedCycleInfo = {}
      state.bicycle.selectedCycleTerms = ''
      state.bicycle.userInfo = {}
      state.bicycle.reservationInfo = deepcopy(DEFAULT_RESERVATION_INFO_BICYCLE)
    },
    /**
     * シェアバイク予約のステータス初期化
     * 使用タイミングが「シェアバイク情報初期化処理」と違うため別途切り出し
     * @param {*} state Storeオブジェクト
     */
    initReservationStatus(state) {
      state.bicycle.reservationStatus = deepcopy(DEFAULT_RESERVATION_STATUS)
    },
    /**
     * タクシー情報内ルート情報の初期化処理
     * @param {*} state Storeオブジェクト
     * @param {String} key キー
     */
    initRouteInfoInTaxi(state, key) {
      // キー指定がない場合はrouteInfoを全て初期化
      if (Util.methods.isNull(key)) {
        state.taxi.routeInfo = deepcopy(DEFAULT_ROUTE_INFO_IN_TAXI)
        return
      }

      // キーが存在する場合
      if (Util.methods.isContainsKey(state.taxi.routeInfo, key)) {
        // 指定されたキーの値を初期化
        state.taxi.routeInfo[key] = deepcopy(DEFAULT_ROUTE_INFO_DETAIL_IN_TAXI)
      }
    },
    /**
     * タクシー到着予想時間の初期化処理
     * @param {*} state Storeオブジェクト
     */
    initEstimatedArrivalTime(state) {
      state.taxi.estimatedArrival = structuredClone(
        DEFAULT_ESTIMATED_ARRIVAL_TIME
      )
    },
    /**
     * タクシー情報初期化処理
     * @param {*} state Storeオブジェクト
     */
    initTaxiInfo(state) {
      state.taxi.routeInfo = deepcopy(DEFAULT_ROUTE_INFO_IN_TAXI)
      state.taxi.reservationStatus = deepcopy(
        DEFAULT_RESERVATION_STATUS_IN_TAXI
      )
      state.taxi.userInfo = {}
      state.taxi.templateMessageList = []
      state.taxi.messageList = []
      state.taxi.vehicleInfo = deepcopy(DEFAULT_VEHICLE_INFO_IN_TAXI)
      state.taxi.estimatedArrival = structuredClone(
        DEFAULT_ESTIMATED_ARRIVAL_TIME
      )
    },
    /**
     * Storeの復元
     * @param {*} state Storeオブジェクト
     * @param {*} value IndexedDBに格納されていたデータ
     */
    restoreStore(state, value) {
      // 未ログインルート検索からの遷移を考慮して、遷移元情報とタクシーの乗車地・目的地のみ復元
      state.screenTransitionSource = value.screenTransitionSource
      state.taxi.routeInfo = value.taxi.routeInfo
    },
    /**
     * 手配中車両情報の更新
     * @param {*} state Storeオブジェクト
     * @param {*} value 手配情報
     */
    updateTaxiVehicleInfo(state, value) {
      state.taxi.vehicleInfo = value
    },
    /**
     * タクシー関連のデータのうち、ルート情報以外を初期値にする
     * @param {*} state Storeオブジェクト
     */
    resetTaxiInfo(state) {
      state.taxi.userInfo = {}
      state.taxi.reservationStatus = deepcopy(
        DEFAULT_RESERVATION_STATUS_IN_TAXI
      )
      state.taxi.templateMessageList = []
      state.taxi.messageList = []
      state.taxi.vehicleInfo = deepcopy(DEFAULT_VEHICLE_INFO_IN_TAXI)
      state.taxi.estimatedArrival = structuredClone(
        DEFAULT_ESTIMATED_ARRIVAL_TIME
      )
    },
    /**
     * タクシーのポーリング処理に使用するタイマーIDを更新する
     * @param {*} state Storeオブジェクト
     * @param {Number|Null} value タイマーID
     */
    updateTaxiPollingTimerId(state, value) {
      state.taxi.pollingTimerId = value
    },
    /**
     * タクシーのポーリング中のネットワークエラー表示フラグ
     * @param {*} state Storeオブジェクト
     * @param {Boolean} value ネットワークエラーが発生しているか
     */
    updateIsTaxiNetworkErrorOccurred(state, value) {
      state.taxi.isNetworkErrorOccurred = value
    },
    /**
     * モビリティ情報取得中フラグを更新する
     * @param {*} state Storeオブジェクト
     * @param {Boolean} value モビリティ情報取得中であるか
     */
    updateInProgressGetMobilityInfo(state, value) {
      state.inProgressGetMobilityInfo = value
    },
  },
  actions: {
    /**
     * 現在地からポート情報を取得する
     * @param {*} state このstoreのstate
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     * @param {Object} currentPosition 現在地
     * @param {Number} zoomLevel マップのズームレベル
     * @returns Promise
     */
    getPortInfo(
      /* eslint-disable-next-line no-unused-vars */
      {state, commit},
      {success, failed, error, currentPosition, zoomLevel}
    ) {
      // リクエスト用パラメータ生成
      const VALID_DIGIT = 6 // 有効桁数
      const param = {
        lat: Util.methods.setDecimalpoint(currentPosition.lat, VALID_DIGIT),
        lon: Util.methods.setDecimalpoint(currentPosition.lon, VALID_DIGIT),
        zoomLevel: zoomLevel,
      }
      return Axios.get('bicycleParkInfoList', param, {needsAccessToken: true})
        .then((res) => {
          if (res.statusCode === constant.API_STATUS_CODE_OK) {
            // ポート一覧情報を保持
            commit('updateBicycleParkInfo', res.body.parkInfo)
            if (success) {
              success()
              return
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * ポート詳細情報取得API実行
     * @param {*} commit Storeオブジェクト
     * @param {Function} success 成功時コールバック
     * @param {Function} failed 失敗コールバック
     * @param {Function} error エラーコールバック
     * @param {*} parkId ポートID
     */
    getBicycleParkDetailInfoList({commit}, {success, failed, error, parkId}) {
      // リクエスト用パラメータ生成
      const param = {
        parkId: parkId,
      }

      return Axios.get('bicycleParkDetailInfoList', param, {
        needsAccessToken: true,
      })
        .then((res) => {
          if (res.statusCode === constant.API_STATUS_CODE_OK) {
            // Storeに取得結果を反映
            commit('updateParkDetailInfo', res.body)
            if (success) {
              success()
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * 利用規約取得API実行
     * @param {*} commit Storeオブジェクト
     * @param {Function} success 成功時コールバック
     * @param {Function} failed 失敗コールバック
     * @param {Function} error エラーコールバック
     * @param {*} entId 事業者ID
     * @param {*} areaId エリアID
     */
    getBicycleTerms({commit}, {success, failed, error, entId, areaId}) {
      // リクエスト用パラメータ生成
      const param = {
        entId: entId,
        areaId: areaId,
      }

      return Axios.get('bicycleTerms', param, {needsAccessToken: true})
        .then((res) => {
          if (res.statusCode === constant.API_STATUS_CODE_OK) {
            // Storeに取得結果を反映
            commit('updateSelectedCycleTerms', res.body.termsJp)
            if (success) {
              success()
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * 自転車予約API実行（ユーザー情報の登録、予約の実行、予約情報の取得を行う）
     * @param {*} state このstoreのstate
     * @param {*} commit Storeオブジェクト
     * @param {String} capyChallengeKey CAPY検証キー
     * @param {String} capyAnswer CAPY回答
     * @param {String} cardSeq カード連番
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     */
    postBicycleReservation(
      {state, commit},
      {capyChallengeKey, capyAnswer, cardSeq, success, failed, error}
    ) {
      const parkId = state.bicycle.selectedParkInfo.parkId

      // リクエスト用パラメータ生成
      const param = {
        capyChallengeKey,
        capyAnswer,
        entId: state.bicycle.selectedCycleInfo.entId,
        cycName: state.bicycle.selectedCycleInfo.cycName,
        tel: state.bicycle.userInfo.tel,
        email: state.bicycle.userInfo.email,
        cardSeq,
        parkId,
      }

      // 自転車予約API実行
      return Axios.post('bicycleReservation', param, {needsAccessToken: true})
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            const capyFailure = data.body?.capyFailure
            if (!capyFailure) {
              // Storeに取得した予約情報を反映
              commit('updateReservationInfo', data.body)
            }
            if (success) {
              success(capyFailure)
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * モビリティ手配情報取得API実行
     * @param {*} commit storeオブジェクト
     * @param {Function} success 成功時コールバック
     * @param {Function} failed 失敗時コールバック
     * @param {Function} error エラー時コールバック
     */
    getMobilityInfo({commit}, {success, failed, error}) {
      // モビリティステータス取得API実行
      return Axios.get('mobilityInfo', {}, {needsAccessToken: true})
        .then((res) => {
          if (res.statusCode === constant.API_STATUS_CODE_OK) {
            const bicycleReserve = res.body.bicycleReserve
            // 手配情報が存在する場合はデータ更新
            if (!Util.methods.isEmpty(bicycleReserve)) {
              // 自転車ステータス
              commit('updateBicycleReservationStatus', bicycleReserve.status)

              // 手配情報
              commit('updateReservationInfo', bicycleReserve.reserveInfo)
            }

            commit('updateTaxiReservationStatus', res.body.taxiResult)
            commit(
              'CreditCardsStore/updatePaymentStatus',
              res.body.paymentStatus,
              {
                root: true,
              }
            )

            if (success) {
              success()
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * 自転車予約キャンセルAPI実行
     * @param {*} state このstoreのstate
     * @param {*} commit Storeオブジェクト
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     */
    cancelBicycleReservation({state, commit}, {success, failed, error}) {
      // 自転車予約キャンセルAPI実行
      return Axios.del('bicycleReservation', {}, {needsAccessToken: true})
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            // 予約ステータスをキャンセルに更新
            commit(
              'updateBicycleReservationStatus',
              constant.BICYCLE_STATUS_CODE.CANCEL_RESERVATION
            )
            // 予約キャンセルフラグをtrueに更新
            commit('updateReservationCancelFlg', true)
            if (success) {
              success()
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * 自転車解錠API実行
     * @param {*} state このstoreのstate
     * @param {*} commit Storeオブジェクト
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗コールバック関数
     * @param {Function} error 失敗時コールバック関数
     * @param {String} cycName 自転車識別番号
     */
    unlockBicycle({state, commit}, {success, failed, error, cycName}) {
      // リクエスト用パラメータ生成
      const param = {
        cycName: cycName,
      }

      // 自転車解錠API実行
      return Axios.post('bicycleUnlock', param, {needsAccessToken: true})
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            // 予約ステータスを利用開始に更新
            commit(
              'updateBicycleReservationStatus',
              constant.BICYCLE_STATUS_CODE.START_USING
            )
            if (success) {
              success()
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * タクシー配車可能エリア判定API
     * @param {*} state このstoreのstate
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     * @param {Function} cancel キャンセル時コールバック関数
     * @param {String} lat 緯度
     * @param {String} lon 経度
     * @param {String} key キー
     */
    checkTaxiDispatchArea(
      {state},
      {success, failed, error, cancel, lat, lon, key = 'default'}
    ) {
      // 重複している処理をキャンセルし、新しいCancelSourceを発行
      checkDispatchAreaCancelSources[key] = Util.methods.resetCancelSource(
        checkDispatchAreaCancelSources[key]
      )
      const param = {
        lat: lat,
        lon: lon,
      }
      return Axios.get('checkDispatchArea', param, {
        source: checkDispatchAreaCancelSources[key],
        needsAccessToken: true,
      })
        .then((res) => {
          if (res.statusCode === constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (e.message == 'Cancel') {
              if (cancel) {
                cancel()
              }
              return
            }

            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * タクシー配車見積もりAPI
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     * @param {*} pickUp 乗車地座標オブジェクト
     * @param {*} dropOff 目的地座標オブジェクト
     */
    getTaxiEstimations({state}, {success, failed, error, pickUp, dropOff}) {
      const param = {
        pickupLat: pickUp.lat,
        pickupLon: pickUp.lon,
        dropoffLat: dropOff.lat,
        dropoffLon: dropOff.lon,
      }
      return Axios.get('taxiEstimations', param, {
        needsAccessToken: true,
      })
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * タクシー配車依頼API
     * @param {*} state このstoreのstate
     * @param {*} commit このstoreのcommit
     * @param {String} capyChallengeKey CAPY検証キー
     * @param {String} capyAnswer CAPY回答
     * @param {String} cardSeq カード連番
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     * @returns {Promise}
     */
    taxiRequestOrders(
      {state, commit},
      {capyChallengeKey, capyAnswer, cardSeq, success, failed, error}
    ) {
      const param = {
        capyChallengeKey,
        capyAnswer,
        pickUp: state.taxi.routeInfo.pickUp,
        dropOff: state.taxi.routeInfo.dropOff,
        tel: state.taxi.userInfo.tel,
        email: state.taxi.userInfo.email,
        cardSeq: cardSeq,
      }
      return Axios.post('taxiOrders', param, {
        needsAccessToken: true,
      })
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            const capyFailure = data.body?.capyFailure
            if (!capyFailure) {
              if (!data.body?.status) {
                throw new Error('status is undefined')
              }
              commit('updateTaxiReservationStatus', data.body.status)
            }

            if (success) {
              success(capyFailure)
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * タクシー手配キャンセルAPI実行
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error 異常時コールバック関数
     */
    cancelTaxiReservation(_, {success, failed, error}) {
      return Axios.del('taxiOrders', {}, {needsAccessToken: true})
        .then((data) => {
          // 通常キャンセル成功
          if (
            data.statusCode === constant.API_STATUS_CODE_OK &&
            data.body.isCanceled
          ) {
            if (success) {
              success()
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * タクシー情報取得し、更新する
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     */
    getTaxiVehicleStatus({commit}, {success, failed, error}) {
      return Axios.get(
        'taxiVehicleStatus',
        {},
        {
          needsAccessToken: true,
        }
      )
        .then((res) => {
          if (res.statusCode === constant.API_STATUS_CODE_OK) {
            // 取得成功時のみ更新する
            const body = res.body
            const vehicleInfo = body.vehicleInfo
            const routeInfo = body.routeInfo

            // 手配情報が存在する場合はデータ更新
            if (!Util.methods.isEmpty(vehicleInfo)) {
              // 車両情報を更新
              commit('updateTaxiVehicleInfo', vehicleInfo)

              // タクシー予約ステータスの更新
              const status = vehicleInfo.status
              commit('updateTaxiReservationStatus', status)

              // メッセージ履歴の更新
              const messageList = body.messageList
              commit('updateTaxiReservationMessageList', messageList)
            }

            // ルート情報が存在する場合はデータ更新
            if (!Util.methods.isEmpty(routeInfo)) {
              // タクシールート情報の更新
              commit('updateRouteInfoInTaxi', {obj: routeInfo})
            }

            if (success) {
              success()
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          log.debug(e)
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * タクシーの運転手に送信する際のメッセージ一覧を取得
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     */
    getTaxiTemplateMessageList({commit}, {success, failed, error}) {
      return Axios.get(
        'taxiTemplateMessages',
        {},
        {
          needsAccessToken: true,
        }
      )
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            // テンプレートメッセージ一覧を保持
            commit('updateTaxiReservationTemplateMessageList', res.body)
            if (success) {
              success()
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * タクシーの運転手にメッセージを送信する
     * @param {String} messageId テンプレートメッセージID
     * @param {String} message テンプレートメッセージ
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     */
    sendTaxiTemplateMessage(
      {state, commit},
      {messageId, message, success, failed, error}
    ) {
      const param = {
        templateMessageId: messageId,
        message: message,
      }
      return Axios.post('taxiSendTemplateMessage', param, {
        needsAccessToken: true,
      })
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            if (success) {
              success(data.body)
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * ポーリング処理を停止
     * @param {*} state Storeオブジェクト
     * @param {*} commit Storeオブジェクト
     */
    stopTaxiPolling({state, commit}) {
      if (state.taxi.pollingTimerId !== null) {
        clearTimeout(state.taxi.pollingTimerId)
        commit('updateTaxiPollingTimerId', null)
      }
    },
    /**
     * ポーリング処理を開始
     * @param {*} getters Storeオブジェクト
     * @param {*} commit Storeオブジェクト
     * @param {*} dispatch Storeオブジェクト
     * @returns {Promise} タクシー情報更新処理のPromiseオブジェクト
     */
    startTaxiPolling({getters, commit, dispatch}) {
      const finish = () => {
        // タクシーを探している or 手配中場合ポーリングを継続
        if (
          getters['isArrangingTaxiStatus'] ||
          getters['isReservingOrFindingTaxiStatus']
        ) {
          const timerMilSec = 5000 // 5秒
          commit(
            'updateTaxiPollingTimerId',
            setTimeout(() => {
              dispatch('startTaxiPolling')
            }, timerMilSec)
          )
        } else {
          dispatch('stopTaxiPolling')
        }
      }

      const success = () => {
        commit('updateIsTaxiNetworkErrorOccurred', false)
        finish()
      }

      const failed = () => {
        commit('updateIsTaxiNetworkErrorOccurred', false)
        finish()
      }

      const error = (e) => {
        if (e.message.includes('invalidAccessToken')) {
          dispatch('stopTaxiPolling')
          throw e
        } else if (e.message.includes('networkError')) {
          commit('updateIsTaxiNetworkErrorOccurred', true)
        }
        finish()
      }

      return dispatch('getTaxiVehicleStatus', {
        success,
        failed,
        error,
      })
    },
  },
}
