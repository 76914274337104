import config from '@/const/const.js'
const constant = config.constant
/**
 * ローカルストレージからオブジェクトを取得する
 * @param {Object} key ローカルストレージのKey
 */
function getLocalStorage(key) {
  if (localStorage.getItem(key)) {
    try {
      // JSONをObjectに変換し返却
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      // jsonに問題がある場合は履歴を削除し空を返却
      localStorage.removeItem(key)
      return JSON.parse(localStorage.getItem(key))
    }
  } else {
    return null
  }
}
/**
 * ローカルストレージにオブジェクトを格納する
 * @param {String} key ローカルストレージのKey
 * @param {Object} value ローカルストレージに登録するデータ
 */
function setLocalStorage(key, value) {
  const parsed = JSON.stringify(value)
  localStorage.setItem(key, parsed)
}

/**
 * 移動手段をローカルストレージから取得する
 * @return 電車バス用移動手段情報
 */
export function getSearchOptionsFromLocalStorage() {
  const baseOptions = {
    isSuperexpressTrain: true, //新幹線
    isUltraexpressTrain: true, //有料特急
    isHighwayBus: true, //高速バス
    isDomesticFlight: true, //飛行機
    isLocalBus: true, //路線/連絡バス
    isFerry: true, //フェリー
  }

  // ローカルストレージから取得
  const result = getLocalStorage(constant.ROUTE_SEARCH_OPTIONS_KEY)

  // ローカルストレージに無い、取得失敗した場合
  if (result == null) {
    return baseOptions
  }
  return result
}
/**
 * 移動手段をローカルストレージに登録する
 * @param {Object} value ローカルストレージに登録するデータ
 */
export function setSearchOptionsToLocalStorage(value) {
  setLocalStorage(constant.ROUTE_SEARCH_OPTIONS_KEY, value)
}
