<template>
  <div class="overflow-hidden" ref="panelContents">
    <Teleport to="body" :disabled="isShowFooter">
      <transition name="slide" v-on:after-enter="automaticGrantFocus()">
        <BasePanel
          v-if="isShowSuggest"
          :spaceHeight="panelSpaceHeight"
          :isOverflowYAuto="true"
          :isRoundedTop="isRoundedTop"
          :isShowFooter="isShowFooter"
          :isShowShadow="isShowShadow"
          ref="basePanel"
        >
          <template v-slot:body>
            <div class="h-full flex flex-col">
              <!--
            TODO:
              TextForm コンポーネントを変更すると他viewに影響が出るため、一旦 TextFormV2コンポーネントを新規作成
              デザイン改修終わり次第V2をとり、TextForm コンポーネントに戻す
          -->
              <TextFormV2
                class="searchText mx-5 pb-3"
                inputClass="bg-gray200 !border-[0px]"
                :placeholder="getPlaceHolderText"
                @input-text="onchangeText($event)"
                @click-back-button="$emit('click-back-button')"
                @focus="changeInputFlg(true)"
                @blur="changeInputFlg(false)"
                :maxlength="'100'"
                ref="textForm"
              />
              <div
                v-if="isEditing"
                class="overflow-y-auto scroll-bar-none pb-10 flex-1"
              >
                <AddressSuggestList
                  class="address-suggest-list"
                  :address="word"
                  @click-address-suggest="clickAddressSuggestList($event)"
                />
                <SuggestList
                  :spots="spots"
                  @click-suggest="completeForm($event)"
                  class="suggest-list"
                />
              </div>
              <template v-else>
                <div v-if="favoriteSectionDisplayFlg">
                  <!-- 登録値 -->
                  <div
                    class="flex justify-between mx-9 mt-2 mb-5"
                    ref="favoriteList"
                  >
                    <!-- 現在地 -->
                    <div
                      class="flex flex-col items-center w-[66px]"
                      @click="onClickFavorite(favoriteCurrent)"
                    >
                      <div><IconFavoriteCurrent class="w-8 h-8" /></div>
                      <div
                        class="mt-1 text-W4 text-[11px] leading-none text-primary"
                      >
                        {{ favoriteCurrent.name }}
                      </div>
                    </div>
                    <!-- 自宅 -->
                    <div
                      class="flex flex-col items-center w-[66px]"
                      @click="onClickFavorite(favoriteHome)"
                    >
                      <div><IconFavoriteHome class="w-8 h-8" /></div>
                      <div
                        class="mt-1 text-W4 text-[11px] leading-none text-primary"
                      >
                        {{ favoriteHome.name }}
                      </div>
                    </div>
                    <!-- 会社/学校 -->
                    <div
                      class="flex flex-col items-center w-[66px]"
                      @click="onClickFavorite(favoriteWork)"
                    >
                      <div><IconFavoriteOfficeSchool class="w-8 h-8" /></div>
                      <div
                        class="mt-1 text-W4 text-[11px] leading-none text-primary"
                      >
                        {{ favoriteWork.name }}
                      </div>
                    </div>
                    <!-- お気に入り -->
                    <div
                      class="flex flex-col items-center w-[66px]"
                      @click="onClickFavorite(favoriteList)"
                    >
                      <div><IconFavoriteRibbon class="w-8 h-8" /></div>
                      <div
                        class="mt-1 text-W4 text-[11px] leading-none text-primary"
                      >
                        {{ favoriteList.name }}
                      </div>
                    </div>
                  </div>
                  <div v-if="isShowIconSpecifyOnTheMap">
                    <div class="flex justify-between m-5">
                      <!-- 地図で指定 -->
                      <div
                        class="w-full h-12 border-[1px] border-gray300 rounded-[8px]"
                        @click="onClickSpecifyOnTheMap()"
                      >
                        <div class="h-full flex justify-center items-center">
                          <img
                            src="@/assets/spot/Icon_Specify_On_The_Map.svg"
                            class="h-[15.85px] w-4 mr-2"
                          />
                          <div class="text-W5 text-[13px] leading-[20.8px]">
                            地図から指定
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="h-2 bg-gray200" />
                  </div>
                  <div v-else class="h-px bg-gray300 mx-6" />
                </div>
                <div class="overflow-y-auto scroll-bar-none pb-10 flex-1">
                  <div
                    class="mt-5 mb-3 ml-5 text-W7 text-left text-[13px] leading-none"
                  >
                    履歴
                  </div>
                  <HistoryList
                    :historyList="historyList"
                    @click-suggest="completeForm($event)"
                    class="history-list"
                  />
                </div>
              </template>
            </div>
          </template>
        </BasePanel>
      </transition>
    </Teleport>
    <!-- お気に入り一覧表示 -->
    <Teleport to="body" :disabled="isShowFooter">
      <Overlay
        v-if="isShowFavoriteSpotOverlay"
        @close-overlay="isShowFavoriteSpotOverlay = false"
        pixelOfTitleAndBody="24"
      >
        <template v-slot:header>お気に入り地点</template>
        <template v-slot:body>
          <div class="px-8">
            <!-- お気に入り -->
            <div
              v-for="favorite in favoriteSpotList"
              :key="favorite.id"
              class="flex items-center bg-white pr-6 pl-4 py-4 rounded-[40px] w-full mt-3 first:mt-0"
              @click="onClickFavoriteSpot(favorite)"
            >
              <img
                class="h-8 w-8 mr-4"
                src="@/assets/favoriteIcon/Icon_Favorite_Ribbon_Lighting.svg"
              />
              <div class="flex-1 text-left overlay-spot-name">
                <div class="text-W4 text-[14px] leading-[14px] truncate mb-1">
                  {{ favorite.spotName }}
                </div>
                <div
                  class="text-W3 text-[13px] leading-[13px] text-gray truncate"
                >
                  {{ showAddress(favorite) }}
                </div>
              </div>
            </div>
            <!-- 追加する -->
            <div
              v-if="isNotMaxFavoriteList"
              class="flex items-center bg-white pr-6 pl-4 py-4 rounded-[40px] w-full mt-3"
              @click="goToFavoriteSuggest(favoriteList.id)"
            >
              <img
                class="h-8 w-8 mr-4"
                src="@/assets/favoriteIcon/Icon_Favorite_Ribbon.svg"
              />
              <div
                class="text-blue text-W4 text-[13px] leading-[13px] text-left"
              >
                追加する
              </div>
            </div>
            <div class="text-W3 text-[11px] text-white mt-4">
              お気に入り登録の変更はメニュー画面から可能です。
            </div>
          </div>
        </template>
      </Overlay>
    </Teleport>
    <!-- お気に入り未登録ポップアップ -->
    <Teleport to="body" :disabled="isShowFooter">
      <Modal
        v-if="isShowNoRegistFavoriteModal"
        class="modal text-center"
        :isShowCloseButton="false"
        :isModalCenter="true"
        modalPaddingX="20px"
      >
        <div class="pt-9 px-5 pb-6">
          <div class="text-W5 text-[17px]">
            <span class="leading-[21.5px]">
              {{ targetFavoriteData.name }}が登録されていません。
            </span>
            <br />
            <span class="leading-[21.5px]">登録しますか？</span>
          </div>
          <!-- ボタン -->
          <div class="flex justify-center mt-4 items-stretch">
            <div
              class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
              @click="closeNoRegistFavoriteModal()"
            >
              キャンセル
            </div>
            <div
              class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
              @click="goToFavoriteSuggest(targetFavoriteData.id)"
            >
              登録
            </div>
          </div>
        </div>
      </Modal>
    </Teleport>
    <!-- 住所取得エラーポップアップ -->
    <Teleport to="body" :disabled="isShowFooter">
      <Modal
        v-if="isNoAddressPopupFlg"
        class="modal text-center"
        :isShowCloseButton="false"
        :isModalCenter="true"
        modalPaddingX="20px"
      >
        <div class="px-4 pt-9 pb-4">
          <div class="pb-5 text-W5 text-[17px] leading-[17px]">
            選択した住所が存在しません。
          </div>
          <div
            class="text-W7 the-button-default text-[15px] leading-[15px] py-4 mx-auto"
            @click="isNoAddressPopupFlg = false"
          >
            OK
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>
<script>
import TextFormV2 from '@/components/TextFormV2.vue'
import SuggestList from '@/components/SuggestList.vue'
import HistoryList from '@/components/HistoryList.vue'
import AddressSuggestList from '@/components/AddressSuggestList.vue'
import Overlay from '@/components/Overlay.vue'
import deepcopy from 'deepcopy'
import Modal from '@/components/Modal.vue'
import BasePanel from '@/components/atoms/BasePanel.vue'
import util from '@/mixins/util'
import searchRouteUtil from '@/mixins/searchRouteUtil'
import IconFavoriteCurrent from '@/components/icons/common/IconFavoriteCurrent.vue'
import IconFavoriteHome from '@/components/icons/common/IconFavoriteHome.vue'
import IconFavoriteOfficeSchool from '@/components/icons/common/IconFavoriteOfficeSchool.vue'
import IconFavoriteRibbon from '@/components/icons/common/IconFavoriteRibbon.vue'

const LOAD_SPOT_API_SEC = 100

// お気に入り表示情報
const FAVORITE_CURRENT = {
  id: 'current',
  name: '現在地',
}
const FAVORITE_HOME = {
  id: 'home',
  name: '自宅',
}
const FAVORITE_WORK = {
  id: 'work',
  name: '会社/学校',
}
const FAVORITE_LIST = {
  id: 'favorite',
  name: 'お気に入り',
}
const SuggestSpot = {
  name: 'SuggestSpot',
  components: {
    TextFormV2,
    SuggestList,
    HistoryList,
    AddressSuggestList,
    Overlay,
    Modal,
    BasePanel,
    IconFavoriteCurrent,
    IconFavoriteHome,
    IconFavoriteOfficeSchool,
    IconFavoriteRibbon,
  },
  mixins: [util, searchRouteUtil],
  emits: ['click-back-button', 'route-search-select-spot'],
  props: {
    // 地点検索表示フラグ
    isShowSuggest: {
      type: Boolean,
      default: false,
    },
    // 対象のスポット（出発地、経由地、目的地)
    targetSpot: {
      type: String,
    },
    // パネルの昇降位置調整
    panelSpaceHeight: {
      type: Number,
      require: false,
      default: 44,
    },
    // お気に入りセクション表示フラグ
    favoriteSectionDisplayFlg: {
      type: Boolean,
      default: true,
    },
    // パネル上部の角丸表示をするか
    isRoundedTop: {
      type: Boolean,
      default: true,
    },
    // フッターを表示するか
    isShowFooter: {
      type: Boolean,
      default: false,
    },
    // 影を表示するか
    isShowShadow: {
      type: Boolean,
      default: true,
    },
    // 地図で指定アイコンを表示するか
    isShowIconSpecifyOnTheMap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      word: '', // 入力テキスト
      spots: [], // スポット検索候補一覧
      timer: 0, // タイマーの識別情報
      favoriteCurrent: FAVORITE_CURRENT, // 現在地
      favoriteHome: FAVORITE_HOME, // 自宅
      favoriteWork: FAVORITE_WORK, // 勤務先
      favoriteList: FAVORITE_LIST, // お気に入り
      isEditing: false, // true:入力中、false:未入力
      historyList: this.getSpotHistoryList(), // 検索履歴一覧
      isShowFavoriteSpotOverlay: false, // お気に入り一覧オーバーレイフラグ
      isShowNoRegistFavoriteModal: false, // お気に入り未登録ポップアップフラグ
      targetFavoriteData: null, // 未登録時の対象
      isNoAddressPopupFlg: false, // 住所検索エラーポップアップフラグ
    }
  },
  beforeUnmount() {
    // 入力中フラグを落とす
    this.changeInputFlg(false)
    // イベントリスナーを解除する（セーフ処理）
    window.removeEventListener('touchstart', this.onTouchStart)
    window.removeEventListener('touchend', this.onTouchEnd)
  },
  watch: {
    /**
     * 地点検索画面表示状態の監視
     */
    isShowSuggest: {
      immediate: true,
      handler(value) {
        // 表示に切り替わった時に、値を初期化し、スクロール領域の再計算を行う
        this.$nextTick(() => {
          if (value) {
            this.$refs.textForm.reset()
            this.word = ''
            this.isEditing = false
            this.spots = []
          }
        })
      },
    },
  },
  computed: {
    /**
     * 登録している自宅情報
     */
    home() {
      return this.$store.state.MenuStore.favoriteSpots.home
    },
    /**
     * 登録している会社/学校情報
     */
    work() {
      return this.$store.state.MenuStore.favoriteSpots.work
    },
    /**
     * 登録しているお気に入り地点一覧情報
     */
    favoriteSpotList() {
      return this.$store.state.MenuStore.favoriteSpots.favorite
    },
    /**
     * お気に入り地点の件数が上限に達していないかチェックする
     * @return {boolean} true:上限に達していない
     */
    isNotMaxFavoriteList() {
      return (
        this.favoriteSpotList.length < this.$config.FAVORITE_SPOT_MAX_NUMBER
      )
    },
    /**
     * 検索フォームに表示するテキストを取得
     */
    getPlaceHolderText() {
      let text
      switch (this.targetSpot) {
        case this.$config.SPOT_TYPE_START:
          text = '出発地を入力'
          break
        case this.$config.SPOT_TYPE_VIA:
          text = '経由地を入力'
          break
        case this.$config.SPOT_TYPE_GOAL:
          text = '行きたい場所を入力'
          break
        default:
          text = '住所を検索'
      }
      return text
    },
  },
  methods: {
    /**
     * 初回展開時のオートフォーカス付与
     */
    automaticGrantFocus() {
      this.$refs.textForm.focusInputForm()
    },
    /**
     * 入力中フラグを切り替える
     * @param {boolean} isInput 入力中かどうか
     */
    changeInputFlg(isInput) {
      this.$store.commit('updateInputFlag', isInput)
      // 入力時にタッチイベントリスナーを設定する
      // 解除はタッチ終了イベントで行う
      if (isInput) {
        window.addEventListener('touchstart', this.onTouchStart)
        window.addEventListener('touchend', this.onTouchEnd)
      }
    },
    /**
     * 表示する住所名を作成
     * @param {Object} spot スポット情報
     */
    showAddress(spot) {
      return spot.buildingName != null && spot.buildingName != ''
        ? spot.address.concat(' ', spot.buildingName)
        : spot.address
    },
    /**
     * 入力文字の内容を用いて地点検索を行う
     * @param {String} value 検索文字
     */
    onchangeText(value) {
      this.word = value
      clearTimeout(this.timer)

      //入力中かの判定
      this.isEditing = value != ''
      if (!this.isEditing) {
        // 入力文字を削除して空文字（未入力状態）にした場合、スポット検索しない
        return
      }
      const vm = this
      this.spots = []
      const success = (spots) => {
        vm.spots = spots
      }
      this.timer = setTimeout(() => {
        vm.$store.dispatch('getSpot', {
          success: success,
          word: vm.word,
        })
      }, LOAD_SPOT_API_SEC)
    },
    /**
     * お気に入り未登録ポップアップを閉じる
     */
    closeNoRegistFavoriteModal() {
      this.isShowNoRegistFavoriteModal = false
      this.targetFavoriteData = null
    },
    /**
     * お気に入りアイコン押下処理
     * @param {Object} target 押下したお気に入り種別
     */
    onClickFavorite(target) {
      // 未登録の場合は未登録ポップアップを表示
      if (this.isNoRegisteredSpot(target)) {
        this.targetFavoriteData = target
        this.isShowNoRegistFavoriteModal = true
        return
      }

      // 各選択地点で登録処理振り分け
      let spot
      switch (target.id) {
        case FAVORITE_CURRENT.id:
          // 現在地を選択した場合、現在地の設定を反映
          spot = {
            name: '現在地',
            address: '',
            coord: '',
          }
          break
        case FAVORITE_HOME.id:
          spot = {
            name: '自宅',
            address: this.home.address,
            coord: this.home.coord,
            buildingName: this.home.buildingName,
          }
          break
        case FAVORITE_WORK.id:
          spot = {
            name: '会社/学校',
            address: this.work.address,
            coord: this.work.coord,
            buildingName: this.work.buildingName,
          }
          break
        case FAVORITE_LIST.id:
          // お気に入りアイコンの場合
          this.isShowFavoriteSpotOverlay = true
          return
        default:
          return
      }
      this.completeForm(spot)
    },
    /**
     * お気に入り地点が登録されているかどうかを返却
     * true:未登録, false:登録済
     * @param {boolean} target 押下したお気に入り種別
     */
    isNoRegisteredSpot(target) {
      let result
      const emptyFavoriteList = 0
      switch (target.id) {
        case FAVORITE_CURRENT.id:
          // 現在地選択の場合は固定でfalse返却
          result = false
          break
        case FAVORITE_HOME.id:
          // 住所もしくは緯度経度が未設定だったら、未登録判定
          result = this.home.address == '' || this.home.coord == ''
          break
        case FAVORITE_WORK.id:
          // 住所もしくは緯度経度が未設定だったら、未登録判定
          result = this.work.address == '' || this.work.coord == ''
          break
        case FAVORITE_LIST.id:
          // お気に入りの場合、登録件数が0件であれば、未登録判定
          result = this.favoriteSpotList.length == emptyFavoriteList
          break
        default:
          result = true
      }
      return result
    },
    /**
     * 地図で指定するボタン押下処理
     */
    onClickSpecifyOnTheMap() {
      // 目的地設定画面へ遷移する
      this.$router.push({name: this.$config.DISPLAY_DESTINATION_SETTING})
    },
    /**
     * お気に入り一覧の地点押下処理
     * @param {Object} favorite お気に入り情報
     */
    onClickFavoriteSpot(favorite) {
      this.isShowFavoriteSpotOverlay = false
      const spot = {
        name: favorite.spotName,
        address: favorite.address,
        coord: favorite.coord,
        buildingName: favorite.buildingName,
      }
      this.completeForm(spot)
    },
    /**
     * 住所検索
     * @param value 住所検索キーワード
     */
    clickAddressSuggestList(value) {
      if (this.isNull(value)) {
        // 空文字（未入力状態）の場合、住所検索しない
        return
      }

      this.$store.commit('startLoading')

      const vm = this
      const success = (addressInfo) => {
        vm.$store.commit('endLoading')
        // 検索結果が0件の場合はエラー表示
        if (addressInfo == null) {
          // 住所が見つからなければエラー表示
          vm.isNoAddressPopupFlg = true
          return
        }
        const searchAddressSpot = {
          name: addressInfo.name,
          address: addressInfo.name,
          coord: `${addressInfo.coord.lat},${addressInfo.coord.lon}`,
        }
        vm.completeForm(searchAddressSpot)
      }

      // 入力フォームにフォーカスが当たっていれば外す
      this.$refs.textForm.blurInputForm()

      //住所検索を実行
      this.$store.dispatch('getAddress', {
        success: success,
        word: value,
      })
    },
    /**
     * ストレージに履歴一覧を反映、検索条件に地点を反映を行い、検索Top画面に遷移
     * @param {Object} value 選択した地点情報
     */
    completeForm(value) {
      // ローカルストレージに格納
      this.historyList = this.setSpotHistoryList(value)
      let updateValue = deepcopy(value)

      if (value.buildingName != null && value.buildingName != '') {
        updateValue.address = updateValue.address.concat(
          ' ',
          updateValue.buildingName
        )
      }
      this.$emit('route-search-select-spot', updateValue)
    },
    /**
     * お気に入り地点選択画面への遷移処理
     * @param {string} targetId 選択した地点のID
     */
    goToFavoriteSuggest(targetId) {
      // 地点検索コンポーネントの表示フラグを立てる
      this.$store.commit('updateIsShowSuggestFlg', true)
      // フッタータブ情報を切り替え
      this.$store.commit('updateFooterTab', this.$config.FOOTER_ID_MENU)
      // お気に入り地点検索画面に遷移
      this.$router.push({
        name: this.$config.DISPLAY_FAVORITE_SPOT_SUGGEST,
        params: {key: targetId},
      })
    },

    /**
     * タッチ開始時処理
     * @param {Object} event イベント
     */
    onTouchStart(event) {
      // アクティブなエレメントのフォーカスを解除する
      // ターゲットがテキストフォームの場合は解除しない
      let obj = document.activeElement
      if (obj && event.target != document.getElementById('textFormInput')) {
        obj.blur()
      }
    },
    /**
     * タッチ終了時処理
     * @param {Object} event イベント
     */
    onTouchEnd(event) {
      // タッチのターゲットがテキストフォームの場合は処理しない
      if (event.target == document.getElementById('textFormInput')) {
        return
      }
      // リスナーを解除する
      window.removeEventListener('touchstart', this.onTouchStart)
      window.removeEventListener('touchend', this.onTouchEnd)
    },
  },
}
export default SuggestSpot
</script>
<style scoped>
/* パネル昇降のトランジション */
.slide-enter-active,
.slide-leave-active {
  transform: translate(0px, 0px) translateZ(1px);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(100vh) translateY(0px) translateZ(1px);
}

.img {
  box-shadow: 0 0 14px 0 rgba(2, 14, 34, 0.27);
  background-color: #f8fafc;
}
.buttonBox {
  margin-left: 37.5px;
  margin-right: 37.5px;
}
.modal-box {
  padding: 48px 38px 32px 38px;
}
.bmaps-button {
  display: flex;
  justify-content: center;
}
.bmaps-button > img {
  max-height: 32px;
}
.overlay-spot-name {
  /* カードの左右マージン 32px × 2 = 64px*/
  /* カード内の左マージン 24px */
  /* カード内の右マージン 16px */
  /* 画像と画像右側のマージン 48px */
  width: calc(100vw - 152px);
}

.history-list :deep(.history-col),
.address-suggest-list,
.suggest-list :deep(.suggest-col) {
  padding-right: 20px;
  padding-left: 20px;
}
</style>
